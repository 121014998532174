import { BusinessObject } from '@shared/bos/business-object'
import { EditorGeneralProperty, EditorPropertyWithKey } from './editor-property-types'
import { TextOrCode } from '@shared/data/text-or-code'

const PROPERTIES_METADATA_KEY = '__editor_properties__'
type Property = EditorPropertyWithKey<BusinessObject, any>

export class EditorPropertiesManager {
	private static staticPropertyCache: Map<Object, Property[]> = new Map()
	private static propertiesForType = new Map<string, Property[]>()

	private constructor() {}

	static setProperty(
		target: Object,
		propertyKey: string | symbol,
		editorProperty: EditorGeneralProperty<BusinessObject, any>
	) {
		const metadata = this.getOwnProperties(target)
		metadata.push({
			...editorProperty,
			// getter: editorProperty.getter ?? ((target, key) => Reflect.get(target, key)),
			// setter: editorProperty.setter ?? ((target, key, value) => Reflect.set(target, key, value)),
			getter: editorProperty.getter ?? ((target, key) => {
				const value = Reflect.get(target, key)
				if(value instanceof TextOrCode) return value.content
				return value
			}),
			setter: editorProperty.setter ?? ((target, key, value) => {
				const currentValue = Reflect.get(target, key)
				if(currentValue instanceof TextOrCode) {
					currentValue.content = value
					return true
				}
				return Reflect.set(target, key, value)
			}),
			key: propertyKey.toString(),
		})
		Reflect.defineMetadata(PROPERTIES_METADATA_KEY, metadata, target)
	}

	static addPropertiesForTypes(...properties: {
		types: string[],
		property: Property
	}[]) {
		for(const property of properties) {
			for(const type of property.types) {
				const forType = this.propertiesForType.get(type) || []
				this.propertiesForType.set(type, forType)
				if(forType.find(p => p.key == property.property.key)) {
					console.error(`Duplicate property key found for type ${type}:`, property.property)
				}
				forType.push(property.property)
			}
		}
	}

	static clearPropertiesForType() {
		this.propertiesForType.clear()
	}

	static getOwnProperties(target: Object) {
		const metadata: Property[] =
			Reflect.getOwnMetadata(PROPERTIES_METADATA_KEY, target) || []
		return metadata
	}

	static getPropertyByKey(target: Object, key: string) {
		return this.getOwnProperties(target)?.find(p => p.key == key)
	}

	static getAllProperties(target: Object): Property[] {
		if (!target) return []

		let staticProperties = this.staticPropertyCache.get(target)
		if(!staticProperties) {
			const parentClassProperties = this.getAllProperties(Object.getPrototypeOf(target))
			const ownProperties = this.getOwnProperties(target)
			const type = (target as any).__type || (target as any).type
			const forType = this.propertiesForType.get(type) || []
			const forAllTypes = type ? this.propertiesForType.get('*') ?? [] : []
	
			staticProperties = [
				...parentClassProperties,
				...ownProperties,
				...forType,
				...forAllTypes,
			]
			
			const staticPropertyKeys = staticProperties.map(p => p.key)
			if(staticPropertyKeys.length != new Set(staticPropertyKeys).size) {
				console.error(`Duplicate property keys found in ${(target as any).id}:`, staticProperties)
				console.log(parentClassProperties, ownProperties, forType, forAllTypes)
			}
	
			this.staticPropertyCache.set(target, staticProperties)
		}
		
		let properties = [...staticProperties]
		// if(includeDynamicProperties) {
		// 	properties.push(...(target.getAdditionalEditorProperties?.() ?? []))
		// }
		properties.sort((a, b) => a.order - b.order)
		return properties
	}
}
