import type { HttpClient } from '@angular/common/http'
import { HttpEventType } from '@angular/common/http'
import { catchError, EMPTY, map, Subject, Subscription } from 'rxjs'
import type { FileUploadSession } from './file-upload-session'

export class FileUpload {
	private uploadSubscription: Subscription | null = null
	private bytesUploaded = 0
	private promiseResolveFunc!: (token: string) => void
	private promiseRejectFunc!: (err: any) => void
	private uploadProgressBytesSubject = new Subject<number>()
	private uploadFinishedSubject = new Subject<void>()

	fileName: string
	size: number = 0
	mimeType: string
	status: 'inprogress' | 'complete' | 'failed' | 'cancelled' = 'inprogress'
	isRemovable = true
	uploadFinished$ = this.uploadFinishedSubject.asObservable()
	readonly uploadProgressBytes$ = this.uploadProgressBytesSubject.asObservable()
	readonly uploadProgressPercent$ = this.uploadProgressBytes$.pipe(
		map(bytes => this.size ? Math.floor(100 * bytes / this.size) : 0)
	)
	uploadSession?: FileUploadSession
	


	readonly uploadTokenPromise?: Promise<string>
		
	
	get uploadProgressBytes() {
		return this.bytesUploaded
	}

	get uploadProgressPercent() {
		if(!this.size) return 0
		return Math.floor(100 * this.bytesUploaded / this.size)
	}

	constructor(
		private httpClient: HttpClient,
		private file: File,
	) {
		this.fileName = file.name
		this.mimeType = file.type
		this.size = file.size
		
		this.uploadTokenPromise = new Promise((res, rej) => {
			this.promiseResolveFunc = res
			this.promiseRejectFunc = rej
		})

		const formData = new FormData()
		formData.append('uploadFile', file)

		const uploadEvents$ = this.httpClient.post('/__api/file/upload', formData, {
			reportProgress: true,
			observe: 'events',
			responseType: 'text',
		}).pipe(
			catchError(err => {
				this.promiseRejectFunc(err)
				this.status = 'failed'

				this.emitFinishedEvent()
				return EMPTY
			})
		)
		
		this.uploadSubscription = uploadEvents$.subscribe(event => {
			switch(event.type) {
				case HttpEventType.UploadProgress:
					this.size = event.total || this.size
					this.bytesUploaded = event.loaded
					this.uploadProgressBytesSubject.next(this.bytesUploaded)
					break

				case HttpEventType.Response:
					if(event.ok) {
						this.promiseResolveFunc(event.body ?? '')
						this.status = 'complete'
					} else {
						this.promiseRejectFunc(`HTTP ${event.status}: ${event.statusText}`)
						this.status = 'failed'
					}

					this.emitFinishedEvent()
					break
			}
		})
	}

	private emitFinishedEvent() {
		this.uploadFinishedSubject.next()
		this.uploadFinishedSubject.complete()
	}

	cancel() {
		this.uploadSubscription?.unsubscribe()
		this.uploadSubscription = null
		this.status = 'cancelled'

		this.emitFinishedEvent()
	}
}