import { BranchNameHeaderName, BranchNameType, LanguageHeaderName } from '@shared/types';
import { ClientLoginResponse } from '@shared/auth/login-response';
import { catchError, switchMap, filter, take, tap } from 'rxjs/operators';
import { AuthService } from './auth-service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router'
import { FrontendLanguageBridge } from '@ng-shared/lib/bridges/frontend-language-bridge'

export const AuthSkipInterceptorHeader = 'X-Skip-Interceptor'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isCurrentlyRenewingTokenSubject = new BehaviorSubject<boolean>(false);
  branchName!: BranchNameType

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const skipInterceptor = req.headers.has(AuthSkipInterceptorHeader)
    const headers = req.headers
      .set(BranchNameHeaderName, this.branchName || 'master')
      .set(LanguageHeaderName, FrontendLanguageBridge.language)
      .delete(AuthSkipInterceptorHeader)

    req = req.clone({
      headers: headers
    })

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && (error.status == 401) && !skipInterceptor) {
          return this.handle401Error(req, next);
          // return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      })
    )
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isCurrentlyRenewingTokenSubject.value) {
      this.isCurrentlyRenewingTokenSubject.next(true);

      return this.authService.renewLogin$().pipe(
        catchError(err => {
          this.router.navigate(['login'])
          return EMPTY
        }),
        switchMap((loginResponse: ClientLoginResponse) => {
          this.isCurrentlyRenewingTokenSubject.next(false)
          return next.handle(req)
        }),
      )
    } else {
      return this.retryRequest(req, next)
    }
  }

  private retryRequest(req: HttpRequest<any>, next: HttpHandler) {
    return this.isCurrentlyRenewingTokenSubject.pipe(
      filter(isRenewing => !isRenewing),
      take(1),
      switchMap(_ => {
        return next.handle(req)
      })
    )
  }
}
