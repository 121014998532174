import { Component } from '@angular/core';
import { StudioTrpcService } from '@ng-shared/lib/services/studio-trpc.service'
import * as Sys from '@ng-shared/lib/frontend-sys'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    trpcService: StudioTrpcService,
  ) {
    globalThis.api = trpcService
    globalThis.Sys = Sys
  }
}
