import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { DataUtil } from '@shared/util/data-util'
import { BoReference } from './bo-reference'

@jsonObject({ name: 'Permission' })
export class Permission extends BusinessObject {
	@jsonArrayMember(BoReference)
	childPermissions: BoReference[] = []
		
	constructor(init?: Partial<Permission>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}
}
