import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { PreviewType } from '../block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

export const LanguageSwitcherTypes = {
	pipeSeparated: 'Pipe-separated texts ("en | de")',
	dropdown: 'Dropdown',
	globe: 'Globe icon',
} as const
export type LanguageSwitcherType = keyof typeof LanguageSwitcherTypes

export const LanguageSwitcherTexts = {
	shortNames: 'Short names ("en")',
	displayNames: 'Display names ("English")',
	flags: 'Flags',
	flagsAndShortNames: 'Flags and short names',
	flagsAndDisplayNames: 'Flags and display names',
} as const
export type LanguageSwitcherText = keyof typeof LanguageSwitcherTexts

@jsonObject({ name: 'LanguageSwitcherBlock' })
export class LanguageSwitcherBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 1,
		label: 'Show list as',
		options: Object.entries(LanguageSwitcherTypes),
	})
	showListAs: LanguageSwitcherType = 'pipeSeparated'

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 1,
		label: 'Show language as',
		options: Object.entries(LanguageSwitcherTexts),
	})
	showLanguageAs: LanguageSwitcherText = 'shortNames'

	constructor(init: Partial<LanguageSwitcherBlock> = {}) {
		super()
		this.init(init)
	}

	producePreview(): PreviewType {
		return 'en | de | ...'
	}

	getEditorCategory(): string {
		return 'Others'
	}
}
