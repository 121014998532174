import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
	selector: '[dynamic-tab]'
})
export class DynamicTabDirective {
	@Input('label') label!: string
	
	constructor(
		public templateRef: TemplateRef<any>,
	) {}
}  