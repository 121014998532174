import { NgZone } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Class, ScreenDefinition } from '@shared/types'
import { original, isDraft } from 'immer'
import { AppContext } from '../app-context'
import { ProxyUtil } from '@shared/util/proxy-util'

export class FrontendScreenBridge {
	static navigateToScreen<P extends object>(screen: ScreenDefinition<P, {}>, params: P) {
		const screenName = AppContext.appModuleId == screen.moduleId ? screen.boId : `${screen.moduleId}.${screen.boId}`
		const router = AppContext.resolve(Router)
		
		router.navigateByUrl('/', { skipLocationChange: true }).then(() => { // first fake navigation to "/"" to force Angular router to refresh
			router.navigate([screenName], {
				queryParams: params
			})
		})
	}

	static navigateBack(steps?: number) {
		steps = steps ?? 1
		for(let i = 0; i < steps; i++) {
			window.history.back()
		}
	}

	static navigateForward(steps?: number) {
		steps = steps ?? 1
		for(let i = 0; i < steps; i++) {
			window.history.forward()
		}
	}

	static openUrl(url: string, inNewWindow: boolean | string) {
		if(inNewWindow) {
			window.open(url, typeof inNewWindow == 'string' ? inNewWindow : undefined)
		} else {
			window.location.assign(url)
		}
	}

	static async openDialogAndGetResponse<R, P extends object={}, I extends object={}>(screen: ScreenDefinition<P, I>, data: P & I): Promise<R> {
		const ngZone = AppContext.resolve(NgZone)
		const dialog = AppContext.resolve(MatDialog)

		if(typeof data == 'object') {
			const dataAny = data as any
			for(const key in dataAny) {
				if(isDraft(dataAny[key])) dataAny[key] = original(dataAny[key])
			}
		}

		return new Promise<R>(resolve => {
			ngZone.run(() => {
				const dialogRef = dialog.open(screen.component, {
					data
				})
				dialogRef.afterClosed().subscribe(result => resolve(result))
			})
		})
	}

	static closeDialogAndReturn(thisArg: any, result: any) {
		result = ProxyUtil.getTarget(result)
		
		const dialogRef = thisArg?.dialogRef as MatDialogRef<any>
		if(!dialogRef) console.error('DialogRef not found when trying to close dialog')
		dialogRef?.close(result)
	}

	static cancelDialog(thisArg: any) {
		this.closeDialogAndReturn(thisArg, undefined)
	}
}