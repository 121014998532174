import { BusinessObject, ValidateAndFixResult } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember, toJson } from 'typedjson'
import type { TsCodeType } from '@shared/types'
import { BoVisitor } from './bo-visitor'
import { DataUtil } from '@shared/util/data-util'
import { BoReference } from './bo-reference'

export const TriggerTypes = {
	manual: 'Manual',
	timer: 'Timer (cron job)',
	mail: 'Incoming mail',
	file: 'File (watch folder)',
} as const
export type TriggerType = keyof typeof TriggerTypes

@jsonObject({ name: 'Trigger' })
export class Trigger extends BusinessObject {
	@jsonMember(String)
	triggerType: TriggerType = 'manual'
	@jsonMember(String)
	tsCode: TsCodeType = ''

	@jsonArrayMember(String)
	timerCronExpressions: string[] = []

	@jsonMember(BoReference)
	mailConfigRef?: BoReference<'ConfigurationItem'>
	@jsonMember(String)
	mailFolderName: string = 'INBOX'
	@jsonMember(Boolean)
	mailIgnoreExisting: boolean = false
	@jsonMember(Boolean)
	mailIgnoreRead: boolean = true
	@jsonMember(Boolean)
	mailMarkAsRead: boolean = true
	@jsonMember(String)
	mailMoveToFolderName?: string
	// @jsonMember(String)
	// mailCriteriaCode: string = `['ALL']`
	@jsonMember(String)
	mailFilterCode?: string

	@jsonArrayMember(String)
	fileWatchGlobExpressions: string[] = []
	@jsonMember(Boolean)
	fileWatchIgnoreInitial: boolean = true

	
	constructor(init?: Partial<Trigger>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getClassNames(): string[] {
		return [this.getQualifiedName()]
	}

	validateAndFix(): ValidateAndFixResult {
		if(this.triggerType != 'timer') {
			this.timerCronExpressions = []
		}
		if(this.triggerType != 'file') {
			this.fileWatchGlobExpressions = []
			this.fileWatchIgnoreInitial = true
		}
		return {}
	}

	visit(visitor: BoVisitor): void {
		visitor.visitTsCode(this.tsCode, this, 'serverCodeReturningVoid', ['tsCode'], newCode => this.tsCode = newCode)
	}
}