import { Component, Input, OnInit } from '@angular/core'
import { FrontendLanguageBridge } from '@ng-shared/lib/bridges/frontend-language-bridge'
import { LanguageSwitcherText, LanguageSwitcherType } from '@shared/blocks/other/language-switcher-block'

@Component({
	selector: 'lowgile-language-switcher',
	templateUrl: './language-switcher.component.html',
})
export class LanguageSwitcherComponent implements OnInit {
	@Input({ required: true }) languageBridge!: typeof FrontendLanguageBridge
	@Input() texts: LanguageSwitcherText = 'shortNames'
	@Input() type: LanguageSwitcherType = 'pipeSeparated'

	languageList: [string, string][] = []

	ngOnInit() {
		this.languageList = this.languageBridge.languages.map(([id, info]) => {
			if(this.texts == 'shortNames') return [id, info.shortName]
			if(this.texts == 'displayNames') return [id, info.displayName]
			return [id, id]
		})
	}
}