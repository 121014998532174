import { Injectable } from '@angular/core';
import { from } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { openDB, IDBPDatabase } from 'idb';

const DbName = 'lowgile'
const DefaultStoreName = 'lowgile'

@Injectable({
  providedIn: 'root'
})
export class IdbService {
  private db!: IDBPDatabase
  constructor() { }
  
  async openDb() {
    if(!this.db) this.db = await openDB(DbName, 1, {
      upgrade(db, oldVersion, newVersion, tx) {
        db.createObjectStore(DefaultStoreName)
      }
    })
    return this.db
  }
 
  async put(key: IDBValidKey, value: any) {
    const db = await this.openDb()
    return db.put(DefaultStoreName, value, key)
  }

  async get<T=any>(key: IDBValidKey) {
    const db = await this.openDb()
    try {
      const value = await db.get(DefaultStoreName, key)
      return value
    } catch(err: any) {
      console.error(err)
    }
  }

  get$<T=any>(key: IDBValidKey) {
    return from(this.openDb()).pipe(
      switchMap(db => from(db.get(DefaultStoreName, key) as Promise<T>))
    )
  }
}