import { DataUtil } from '@shared/util/data-util'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { jsonMapMember } from 'typedjson'

export const SubsetPropertyAvailabilities = ['', 'y', 'n'] as const
export const SubsetPropertyAvailabilityNames: Record<typeof SubsetPropertyAvailabilities[number], string> = {
	'': '(default)',
	'y': 'Yes',
	'n': 'No'
}

@jsonObject
export class EntitySubset {
	@jsonMember	name!: string
	@jsonMember	parentSubset: string = ''
	@jsonMapMember(String, String)
	itemAvailability: Map<string, typeof SubsetPropertyAvailabilities[number]> = new Map()

	constructor(init?: Partial<EntitySubset>) {
		DataUtil.assignCommonProperties(this, init)
	}
}