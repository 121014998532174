import { UserIdType } from '@shared/types'
import { DataUtil } from '@shared/util/data-util'

export class UserAuthenticationToken {
	id?: number
	userId?: UserIdType
	token!: string
	issuedOn!: Date
	validUntil!: Date

	constructor(init?: Partial<UserAuthenticationToken>) {
		DataUtil.assignCommonProperties(this, init)
	}
}