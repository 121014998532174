import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, OnInit, forwardRef, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lowgile-text-or-input',
  templateUrl: './text-or-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextOrInputComponent),
    multi: true
  }]
})
export class TextOrInputComponent implements OnInit, ControlValueAccessor {
  @Input() width: string = '100%'
  @Output() blur = new EventEmitter<void>()
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>
  ngModel: string
  editMode: boolean
  onNgModelChange: (value: string) => void

  constructor() { }
  ngOnInit(): void {
  }
  writeValue(value: string): void {
    this.ngModel = value
    this.editMode = !value
  }
  registerOnChange(onChange: (value: string) => void): void {
    this.onNgModelChange = onChange
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
  setEditMode(editMode: boolean) {
    if(!this.ngModel) return

    this.editMode = editMode

    if(editMode) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus()
        this.inputElement.nativeElement.select()
      }, 0)
    }
  }
}
