import { MatSnackBar } from '@angular/material/snack-bar'
import { AppContext } from '../app-context'
import { Title } from '@angular/platform-browser'
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component'
import { ScreenUtil } from '@shared/util/screen-util'
import { ClientLogLevel, SessionCookieName } from '@shared/types'

export class FrontendAppBridge {
	static isSpinnerVisible = false

	static set title(title: string) {
		this.setTitle(title)
	}

	static get title() {
		return this.getTitle()
	}

	static setTitle(title: string) {
		AppContext.resolve(Title).setTitle(title)
	}

	static getTitle() {
		return AppContext.resolve(Title).getTitle()
	}

	static showNotification(type: ClientLogLevel, message: string, autoClose?: boolean | number) {
		const snackBar = AppContext.injector.get(MatSnackBar)
		if(autoClose === undefined) {
			autoClose = type != 'error' && type != 'warning'
		}
		if(typeof autoClose == 'boolean') {
			autoClose = autoClose ? 2000 : undefined
		}

		const snackBarRef = snackBar.openFromComponent(SnackBarComponent, {
			data: {
				message,
				type,
				duration: autoClose,
				dismiss: () => snackBarRef.dismiss(),
			},
			duration: autoClose,
			panelClass: `${type}-snackbar`,
		})
	}

	static provideDownload(content: any, fileName: string, mimeType?: string) {
		ScreenUtil.provideDownload(content, fileName, mimeType)
	}
}