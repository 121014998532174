import type { HtmlType } from './../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { StaticEntityBlock } from './static-entity-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'ToggleGroupBlock' })
export class ToggleGroupBlock extends StaticEntityBlock {
	constructor(init?: Partial<ToggleGroupBlock>) {
		super()
		this.appearance = undefined
		this.init(init)
	}

	producePreview(): HtmlType {
		return this.exampleText || `A | B | C`
	}

	getEditorCategory(): string {
		return 'Additional Form Fields'
	}

	canHaveAppearance() {
		return false
	}
}
