import { createDraft, finishDraft, Draft, Patch, applyPatches } from 'immer'
import DeepProxy from 'proxy-deep'
import { BehaviorSubject } from 'rxjs'
import { DataUtil } from '@shared/util/data-util'
import { FormControlCache } from './from-control-cache'
import { storeProxyHandler } from './store-proxy-handler'
import { PathOccurenceMap } from '@shared/data/path-occurence-map'
import { PathOccurenceVisitor } from '@shared/data/path-occurence-visitor'
import { Semaphore } from '@shared/util/semaphore'

export class Store<T extends object> {
	private stateSubject = new BehaviorSubject<T>({} as T)
	readonly state$ = this.stateSubject.asObservable()
	public stateInternal: T = {} as T
	public pathOccurenceMap = new PathOccurenceMap()
	private semaphore = new Semaphore()
	
	constructor(
		public name: string,
		state: T
	) {
		this.setState(state)
	}

	private setState(state: T) {
		this.stateInternal = state
		this.stateSubject.next(this.getState())
		this.pathOccurenceMap = new PathOccurenceVisitor().visit(state, this.name ? [this.name] : [])
	}

	getState() {
		return new DeepProxy<T>(this.stateInternal, storeProxyHandler, { path: this.name as any })
	}

	async updateState(state: T) {
		const lock = await this.semaphore.obtainLock()
		try {
			this.setState(state)
		} finally {
			lock.release()
		}
	}
	
	private async updateInternal(modifier: (draft: Draft<T>) => Promise<void>): Promise<[T, Patch[]]> {
		const lock = await this.semaphore.obtainLock()
		try {
			const draft = createDraft(this.stateInternal)
			try {
				await modifier(draft)
			} catch(err: any) {
				console.error(err)
			}
	
			let patches: Patch[] = []
			const newState = finishDraft(draft, p => patches = p) as T
	
			this.setState(newState)
			return [newState, patches]
		} finally {
			lock.release()
		}
	}

	private updateInternalSync(modifier: (draft: Draft<T>) => void, beforeNewStateSet?: (newState: T, oldState: T) => void): [T, Patch[]] {
		if(this.semaphore.isLocked()) {
			console.warn('Store is currently locked; cannot update')
			return [this.stateInternal, []]
		}

		const draft = createDraft(this.stateInternal)
		try {
			modifier(draft)
		} catch(err: any) {
			console.error(err)
		}

		let patches: Patch[] = []
		const newState = finishDraft(draft, p => patches = p) as T

		beforeNewStateSet?.(newState, this.stateInternal)
		this.setState(newState)
		return [newState, patches]
	}

	async update(modifier: (draft: Draft<T>) => Promise<void>) {
		const [newState, patches] = await this.updateInternal(modifier)
		return patches
	}

	updateSync(modifier: (draft: Draft<T>) => void) {
		const [newState, patches] = this.updateInternalSync(modifier)
		return patches
	}

	applyPatches(patches: Patch[]) {
		const newState = applyPatches(this.stateInternal, patches) as T
		this.setState(newState)
	}
	
	setProperty(parentPath: string[], property: string, value: any) {
		parentPath = parentPath.slice(1) // ignore element 0, which would be this.name
		const parentObj = DataUtil.getFromPath(this.stateInternal, parentPath);
		
		const [newState, patches] = this.updateInternalSync(draft => {
			const parentDraft = DataUtil.getFromPath(draft, parentPath)
			if(parentDraft) {
				Reflect.set(parentDraft, property, value)
			}
		}, newState => {
			const newParentObj = DataUtil.getFromPath(newState, parentPath);
			FormControlCache.migrateFormControls(parentObj, newParentObj)
		})
		
		const newParentObj = DataUtil.getFromPath(newState, parentPath);
		FormControlCache.getFormControl(newParentObj, property).formControl?.setValue(value)
		
		// this.applicationRef.tick()
		return patches
	}
}