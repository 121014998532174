<table mat-table [dataSource]="parameters" multiTemplateDataRows #table="matTable"
	cdkDropList [cdkDropListData]="parameters" (cdkDropListDropped)="onDropListDropped($event)"
	[style.display]="parameters.length ? 'table' : 'none'"
>
	<ng-container matColumnDef="actions">
		<th mat-header-cell *matHeaderCellDef class="icon-column"></th>
		<td mat-cell *matCellDef="let parameter; let idx = index" style="text-wrap: nowrap;">
			<mat-icon cdkDrag cdkDragHandle cdkDragRootElement="tr">drag_indicator</mat-icon>
			<button mat-icon-button (click)="removeParameter(idx)"><mat-icon>delete</mat-icon></button>
		</td>
	</ng-container>

	<ng-container matColumnDef="name">
		<th mat-header-cell *matHeaderCellDef>Name</th>
		<td mat-cell *matCellDef="let parameter">
			<lowgile-text-or-input
				[(ngModel)]="parameter.name"
				(blur)="onChange()"
			></lowgile-text-or-input>
		</td>
	</ng-container>
	
	<ng-container matColumnDef="type">
		<th mat-header-cell *matHeaderCellDef>Type</th>
		<td mat-cell *matCellDef="let parameter">
			<code-editor-new
				[ngModel]="parameter.type"
				(ngModelChange)="parameter.type = $event"
				(blur)="onChange()"
				size="singleline"
				[typeDeclarations]="typeDeclarations"
				[context]="boEditor.getCodeContext$('type', 'client') | async"
			></code-editor-new>
		</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="colNames"></tr>
	<tr mat-row *matRowDef="let row; columns: colNames"></tr>
</table>

<button mat-button color="primary" (click)="addParameter()">
	<mat-icon>add_circle</mat-icon>
	<span>Add new input</span>
</button>
