import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember, toJson, jsonMapMember } from 'typedjson'
import { type ConfigTypeName, ConfigTypes } from '@shared/config/configuration-types'
import { BoVisitor } from './bo-visitor'
import { DataUtil } from '@shared/util/data-util'
import { ConfigurationItemProperty } from './configuration-item-property'

export const ConfigurationItemScopes = ['cluster', 'server'] as const

@jsonObject({ name: 'ConfigurationItem' })
export class ConfigurationItem extends BusinessObject {
	@jsonMember(String)
	type: ConfigTypeName = 'blank'
	@jsonArrayMember(ConfigurationItemProperty)
	properties: ConfigurationItemProperty[] = []

	constructor(init?: Partial<ConfigurationItem>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getPredefinedProperties() {
		return ConfigTypes[this.type]?.properties ?? []
	}

	getEffectiveProperties() {
		return [
			...this.getPredefinedProperties(),
			...this.properties,
		]
	}

	getClassNames(): string[] {
		return ['$Config']
	}

	visit(visitor: BoVisitor): void {
		// visitor.visitTsCode(this.tsType, this, 'type', ['type'], newType => this.tsType = newType)
	}
}
