import { FileUpload } from './file-upload'

export class FileUploadSession {
	uploads: FileUpload[] = []

	async getAllUploadTokens() {
		return Promise.all(this.uploads.map(upload => upload.uploadTokenPromise))
	}

	cancelAllUploads() {
		this.uploads.forEach(u => u.cancel())
	}

	addFileUpload(upload: FileUpload) {
		this.uploads.push(upload)
	}

	clear(cancelUploadsInProgress = true) {
		if(cancelUploadsInProgress) {
			this.uploads.forEach(upload => {
				if(upload.status == 'inprogress') upload.cancel()
			})
		}
		this.uploads = []
	}
}