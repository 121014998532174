import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Component, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table'
import { Parameter } from '@shared/script/parameter'
import { calculateNextFreeName } from '@shared/util/string-util'
import { AbstractEditorComponent } from '../../abstract-editor.component'
import { BusinessObject } from '@shared/bos/business-object'

@Component({
  selector: 'lowgile-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss'],
})
export class ParametersComponent {
  @Input() parameters: Parameter[] = []
  @Input() typeDeclarations: string
  @Output() parametersUpdated = new EventEmitter<void>()

  @ViewChild('table') table: MatTable<Parameter>
  colNames = ['actions', 'name', 'type']

  constructor(
    @Optional() public boEditor: AbstractEditorComponent<BusinessObject>,
  ) {}

  addParameter() {
    this.parameters.push(new Parameter({
      name: calculateNextFreeName('input', this.parameters, p => p.name),
      type: 'string'
    }))
    this.onChange()
  }

  removeParameter(idx: number) {
    this.parameters.splice(idx, 1)
    this.onChange()
  }

  onDropListDropped(event: CdkDragDrop<any, any>) {
    if(event.previousIndex == event.currentIndex) return

    moveItemInArray(event.previousContainer.data, event.previousIndex, event.currentIndex)
    this.onChange()
  }

  onChange() {
    console.log('update')
    this.parametersUpdated.emit()
    this.table.renderRows()
  }
}
