import { tap, catchError, take, map } from 'rxjs/operators';
import { EMPTY, firstValueFrom, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ClientLoginResponse } from '@shared/auth/login-response';
import { ErrorService } from './error.service';
import { Injectable, inject } from '@angular/core';
import { AuthSkipInterceptorHeader } from './auth-interceptor'
import { UserRecord } from '@shared/auth/user-record'
import { SystemPermissionName } from '@shared/auth/system-permissions'
import { BaseJsonMapper } from '@shared/data/base-json-mapper'
import { User } from '@shared/auth/user'
import { BackendResponse } from '@shared/data/backend-response'
import { AppTrpcServiceInjectionToken } from './app-trpc.service'
import { LoginRequest } from '@shared/auth/login-request'
import { LocalStorageRefreshTokenProperty, LocalStorageUserProperty } from '@shared/types'


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private trpc = inject(AppTrpcServiceInjectionToken)
  refreshToken: string
  user?: UserRecord
  renewTimeout?: number
  heartbeat$ = this.trpc.subscriptionAsObservable(c => c.auth.heartbeat$, undefined)
  loginConfig$ = this.trpc.queryAsObservable(c => c.auth.getLoginConfig.query())
  
  
  constructor(
    private errorService: ErrorService,
    // private http: HttpClient,
  ) {
    this.refreshToken = localStorage.getItem(LocalStorageRefreshTokenProperty) ?? ''
    this.user = new UserRecord(JSON.parse(
      localStorage.getItem(LocalStorageUserProperty) ?? 'null'
    ))
  }


  login$(request: LoginRequest) {
    return this.trpc.queryAsObservable(c => c.auth.login.mutate(request)).pipe(
      catchError(err => {
        return of({
          nextStage: request.stage,
          error: err.message,
        } as ClientLoginResponse)
      }),
      tap(loginResponse => {
        this.setTokens(loginResponse, true)
        if(loginResponse.error) {
          this.errorService.error(loginResponse.error)
        }
      }),
    )
  }

  logout$() {
    return this.trpc.queryAsObservable(c => c.auth.logout.mutate()).pipe(
      map(() => {
        this.setTokens(null, true);
        this.trpc.csrfService.forgetCsrfToken()
      }),
      catchError(err => {
        this.errorService.error('Failed to log out')
        return EMPTY
      })
    )
  }

  requestAuthToken$(request: LoginRequest) {
    return this.trpc.queryAsObservable(c => c.auth.requestAuthToken.mutate({
      userName: request.userName,
      password: request.password,
    })).pipe(
      catchError(err => {
        this.errorService.error('Failed to request an SMS token')
        return EMPTY
      })
    )
  }

  renewLogin$() {
    return this.trpc.queryAsObservable(c => c.auth.renewLogin.mutate({
      refreshToken: this.refreshToken,
    }
    // , {
    //   headers: {
    //     [AuthSkipInterceptorHeader]: ''
    //   }
    // }
    ))
    .pipe(
      tap((loginResponse) => {
        this.setTokens(loginResponse, true);
      }),
      catchError(err => {
        this.errorService.error('Failed to renew login... redirecting to login page')
        setTimeout(() => window.location.pathname = 'studio/login', 1500)
        return EMPTY
      }),
    )
  }

  updatePassword(oldPassword: string, newPassword: string) {
    const updatePassword$ = this.trpc.queryAsObservable(c => c.user.updatePassword.mutate({
        oldPassword,
        newPassword,
      }))
      .pipe(
        catchError(err => {
          return of(false)
        }),
        tap((successful) => {
          if(!successful) {
            this.errorService.error('Failed to update password')
          }
        })
      )

    return firstValueFrom(updatePassword$)
  }

  isUsernameAvailable(userName: string) {
    const updatePassword$ = this.trpc.queryAsObservable(c => c.user.isUsernameAvailable.query({
      userName,
    }))
    .pipe(
      catchError(err => {
        this.errorService.error('Failed to get availability')
        return EMPTY
      }),
    )

    return firstValueFrom(updatePassword$)
  }

  async registerUser(user: User, password: string, generatePassword: boolean) {
    return  this.trpc.client.user.register.mutate({
      user,
      password,
      generatePassword,
    })
  }

  async startPasswordRecovery(userNameOrEmail: string) {
    return this.trpc.client.user.startPasswordRecovery.mutate({
      userNameOrEmail,
    })
  }

  async finalizePasswordRecovery(userName: string, recoveryToken: string, newPassword: string) {
    return this.trpc.client.user.finalizePasswordRecovery.mutate({
      userName,
      recoveryToken,
      newPassword,
    })
  }

  setTokens(loginResponse: ClientLoginResponse | null, storeInLocalStorage: boolean) {
    let doStore = false
    if(loginResponse) {
      this.refreshToken = loginResponse.refreshToken ?? ''
      this.user = new BaseJsonMapper().readFromObject(loginResponse.user!, UserRecord)
      doStore = !!this.refreshToken
    } else {
      this.refreshToken = ''
      this.user = undefined
      doStore = true
    }

    if (doStore && storeInLocalStorage) {
      if(this.refreshToken) {
        localStorage.setItem(
          LocalStorageRefreshTokenProperty,
          this.refreshToken
        );
        localStorage.setItem(
          LocalStorageUserProperty,
          JSON.stringify(this.user)
        );

        this.startRenewalTimeout(loginResponse?.expiresAt)
      } else {
        localStorage.removeItem(LocalStorageRefreshTokenProperty)
        localStorage.removeItem(LocalStorageUserProperty)
      }
    }
  }

  startRenewalTimeout(expiresAt: number | undefined) {
    if(!expiresAt) return
    if(this.renewTimeout) clearTimeout(this.renewTimeout)
    
    const renewInMs = Math.max(expiresAt - Date.now() - 5000, 2000)
    this.renewTimeout = setTimeout(() => {
      this.renewLogin$().subscribe()
    }, renewInMs) as any // renew 5s before expiration
  }

  isLoggedIn() {
    return !!this.user?.id;
  }

  getUser() {
    return this.user
  }

  getUserFullName() {
    if (!this.user) return '(Anonymous)';

    return `${this.user.firstName} ${this.user.lastName}\n(${this.user.userName})`;
  }

  hasSystemPermission(permissionName: SystemPermissionName) {
    return this.user?.hasSystemPermission(permissionName) ?? false
  }
}
