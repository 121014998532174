import { DataUtil } from '@shared/util/data-util'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { Parameter } from './parameter'

export const VariableDefinitionContexts = {
	local: 'Local',
	input: 'Input',
	pathParam: 'URL Parameter',
} as const
export type VariableDefinitionContext = keyof typeof VariableDefinitionContexts

@jsonObject
export class VariableDefinition extends Parameter {
	@jsonMember(String) context!: VariableDefinitionContext
	@jsonMember(Boolean) isStatic: boolean = false

	constructor(init?: Partial<VariableDefinition>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}
}