import { ScreenSizes, type EventType, type TsTypeType } from '../../types'
import type { ScreenSize, TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoVisitor } from '@shared/bos/bo-visitor'
import { TrackByFunctionNames, TrackByFunctions } from '../logic/for-block'
import { Block } from '..'
import { DataTableColumnBlock } from './data-table-column-block'
import { Parameter } from '@shared/script/parameter'
import { BoReference } from '@shared/bos/bo-reference'
import { PagedBlockDataSourceDefinition } from '../common/block-data-source-definition'

@jsonObject({ name: 'DataTableBlock' })
export class DataTableBlock extends Block {
	@jsonMember
	@ScreenEditorInput<DataTableBlock>({
		order: 1,
		label: '',
		compositionObj: blocks => blocks[0].dataSource,
	})
	dataSource: PagedBlockDataSourceDefinition = new PagedBlockDataSourceDefinition()

	@jsonMember
	@ScreenEditorInput<DataTableBlock>({
		inputType: 'checkbox',
		order: 5,
		tab: 'Advanced',
		label: 'Is configurable',
	})
	isConfigurable: boolean = true

	@jsonMember
	@ScreenEditorInput<DataTableBlock>({
		inputType: 'boRef',
		order: 6,
		tab: 'Advanced',
		label: 'Save/load configuration at',
		allowedBoTypes: ['UserDefinedConfiguration'],
		includeImportedModules: true,
		isVisible: (blocks) => blocks[0].isConfigurable,
	})
	userDefinedConfigurationRef: BoReference = new BoReference()

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 7,
		tab: 'Advanced',
		label: 'Vertical if screen is smaller than',
		tooltip: () => ({ text: 'Use XSmall if you never want to go vertical'}),
		options: ScreenSizes.map(s => [s, s]),
	})
	verticalIfScreenSmallerThan: ScreenSize = 'XSmall'

	@jsonMember
	@ScreenEditorInput<DataTableBlock>({
		inputType: 'checkbox',
		order: 5,
		tab: 'Advanced',
		label: 'Sticky header',
	})
	isHeaderSticky: boolean = true

	@jsonMember
	@ScreenEditorInput<DataTableBlock>({
		inputType: 'checkbox',
		order: 7,
		tab: 'Data Management',
		label: 'Use global filter',
	})
	useGlobalFilter: boolean = true
	
	@jsonMember(String)
	@ScreenEditorInput<DataTableBlock>({
		inputType: 'code',
		order: 7,
		label: 'Debouncing delay (ms)',
		tab: 'Data Management',
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: false,
		isVisible: blocks => blocks[0].dataSource.sourceType == 'entity',
		isMandatory: () => true,
		tooltip: () => ({
			text: 'Delay in milliseconds that the app will wait after keyboard inputs until the search is executed; this helps avoid sending a request after every single typed character',
		}),
	})
	debouncingDelayCode?: TsCodeType = '250'
	constructor(init: Partial<DataTableBlock> = {}) {
		super()
		this.init(init)
	}

	static createNewForEditor(): Block {
		return new this({
			children: [
				DataTableColumnBlock.createNewForEditor(),
			]
		})
	}

	getEditorTitle() {
		return `${super.getEditorTitle()}`
	}

	canHaveChild(child: Block): boolean {
		return child instanceof DataTableColumnBlock
	}

	getPreviewDirection(): 'column' | 'row' {
		return 'row'
	}

	get allowedEventTypes(): EventType[] {
		const rowClickEvent: EventType = { name: 'rowClick', parameters: [
			new Parameter({ name: this.dataSource.loopVariableDefinition, type: this.dataSource.getItemTsType(), defaultExpression: '$event[1]' }),
		]}

		if(this.dataSource.indexVariableDefinition) {
			if(!rowClickEvent.parameters) rowClickEvent.parameters = []
			rowClickEvent.parameters.push(new Parameter({ name: this.dataSource.indexVariableDefinition, type: 'number', defaultExpression: '$event[2]' }))
		}

		return [
			rowClickEvent,
		]
	}

	get allowedStylePrefixes(): string[] {
		return ['row']
	}

	protected getOwnVariables(includeDeclarationVariables: boolean): Record<string, string | null> {
		const varDefs = {
			[this.dataSource.loopVariableDefinition]: this.dataSource.getItemTsType(),
		}
		if(this.dataSource.indexVariableDefinition) varDefs[this.dataSource.indexVariableDefinition] = 'number'
		return varDefs
	}

	protected visitThisWithBoVisitor(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		// this.visitCodePropertyWithBoVisitor(visitor, pathPrefix, DataTableBlock, 'collectionCode')
		// this.visitCodePropertyWithBoVisitor(visitor, pathPrefix, DataTableBlock, 'loopVariableType')
	}

	getEditorCategory(): string {
		return 'Tables & Lists'
	}
}
