<input
	*ngIf="editMode; else elseTemplate"
	[(ngModel)]="ngModel"
	(ngModelChange)="onNgModelChange($event)"
	(click)="$event.stopPropagation()"
	(blur)="setEditMode(false); blur.emit()"
	(keyup.enter)="setEditMode(false)"
	tabindex="0"
	[style.width]="width || '100%'"
	cdkFocusInitial
	#inputElement
>
<ng-template #elseTemplate>
	<span
		(focus)="$event.stopPropagation(); setEditMode(true)"
		tabindex="0"
	>
		{{ngModel}}
	</span>
</ng-template>