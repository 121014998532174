import { DataUtil } from '@shared/util/data-util'
import type { ModuleIdType, BusinessObjectIdType, BusinessObjectTypeType } from '@shared/types';
import type { TsCodeType } from '@shared/types'
import { jsonMember, jsonObject } from 'typedjson'
import { BusinessObject } from '../business-object'
import { BoReference } from '../bo-reference'

@jsonObject
export class RestrictableItemRestriction extends BoReference {
	@jsonMember(String)
	itemName!: string
	@jsonMember(String)
	restrictionCode: TsCodeType = ''

	constructor(init?: Partial<RestrictableItemRestriction>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getFunctionName() {
		return `allow_${this.moduleId}__${this.boId}_${this.itemName?.replaceAll('.', '__')}`
	}

	matches(other: { moduleId: ModuleIdType, boType: BusinessObjectTypeType, boId: BusinessObjectIdType, itemName: string } | BusinessObject, itemName?: string) {
		if(other instanceof BusinessObject) {
			return this.moduleId == other.moduleId && this.boType == other.__type && this.boId == other.boId && this.itemName == itemName
		}
		return this.moduleId == other.moduleId && this.boType == other.boType && this.boId == other.boId && this.itemName == (itemName ?? other.itemName)
	}
}