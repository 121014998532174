import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

export const StepperOrientations = {
	horizontal: 'Horizontal',
	vertical: 'Vertical',
} as const
export type StepperOrientation = keyof typeof StepperOrientations

@jsonObject({ name: 'StepperBlock' })
export class StepperBlock extends Block {
	// @jsonMember(String)
	// @ScreenEditorInput({
	// 	inputType: 'dropdown',
	// 	order: 1,
	// 	label: 'Orientation',
	// 	options: Object.entries(StepperOrientations)
	// })
	// orientation: StepperOrientation = 'horizontal'
	@ScreenEditorInput<StepperBlock>({
		inputType: 'code',
		order: 1,
		label: 'Orientation',
		codeLanguage: 'ts',
		tsReturnType: () => `'horizontal' | 'vertical'`,
		editorSize: 'singleline',
		isBinding: false,
	})
	@jsonMember(String) orientationCode: string = "'horizontal'"

	@ScreenEditorInput<StepperBlock>({
		inputType: 'code',
		order: 2,
		label: 'Linear',
		codeLanguage: 'ts',
		tsReturnType: () => 'boolean',
		editorSize: 'singleline',
		isBinding: false,
	})
	@jsonMember(String) isLinearCode: string = "false"
	
	getPreviewDirection(): 'row' | 'column' {
		return 'column'
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'black'
	}

	getTitleColor(): string {
		return 'white'
	}

	getEditorCategory(): string {
		return 'Additional Form Fields'
	}
}
