import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class ServerDataStoreView {
	@jsonMember(String)
	name!: string
	@jsonMember(String)
	code?: string

	constructor(init?: Partial<ServerDataStoreView>) {
		DataUtil.assignCommonProperties(this, init)
	}
}