import type { BusinessObjectIdType, HasTokenAssignment, ModuleIdType, OnlyData, ProcessOutgoingFlowInfo, UserIdType } from '@shared/types'
import { DataUtil } from '@shared/util/data-util'
import { jsonObject, toJson, jsonMember, jsonMapMember, AnyT, jsonArrayMember } from 'typedjson'

@jsonObject
@toJson
export class BaseTaskToken {
	@jsonMember(String)
	id: string
	@jsonMember(String)
	taskName: string
	@jsonMember(AnyT)
	publicMetadata?: Record<string, any> = {}
	@jsonMember(Number)
	processInstanceId: number
}

@jsonObject
export class UserTaskToken extends BaseTaskToken {
	get __type(): string { return 'UserTaskToken' }
	set __type(_) { /*ignore*/ }

	@jsonMember(String)
	assignedToUserId: string
	@jsonMember(String)
	assignedToName: string
	@jsonMember(String)
	processModuleId: ModuleIdType
	@jsonMember(String)
	processBoId: BusinessObjectIdType
	@jsonMember(String)
	screenModuleId: ModuleIdType
	@jsonMember(String)
	screenBoId: BusinessObjectIdType

	constructor(init?: OnlyData<UserTaskToken>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getUrl() {
		return `/${this.screenModuleId}/t/${this.id}`
	}
}

@jsonObject
export class UserTaskTokenDetails extends UserTaskToken {
	get __type(): string { return 'UserTaskTokenDetails' }
	set __type(_) { /*ignore*/ }

	constructor(init?: OnlyData<UserTaskTokenDetails>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	@jsonArrayMember(AnyT)
	assignments: [string, any][] = []
	@jsonArrayMember(String)
	outputs: string[] = []
	@jsonArrayMember(AnyT)
	outgoingFlows: ProcessOutgoingFlowInfo[] = []
	@jsonMember(String)
	swimlaneName: string
	@jsonMember(String)
	layoutScreenModuleId: ModuleIdType
	@jsonMember(String)
	layoutScreenBoId: BusinessObjectIdType
}