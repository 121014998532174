<div class="top" (mousedown)="false">
	@if(authService.loginConfig$ | async; as loginConfig) {
	<div class="centerBox">
		<img src="assets/logo.svg" alt="Lowgile" width="212" style="margin-bottom: 2em;">

			@for(loginMethod of loginConfig.loginMethods; track loginMethod; let isLast = $last) {
				@switch(loginMethod) {
					@case('credentials') {
						<mat-form-field>
							<mat-label>Username or E-Mail</mat-label>
							<input matInput type="text" name="username" [(ngModel)]="loginRequest.userName" #userNameInput>
						</mat-form-field>
				
						<mat-form-field *ngIf="!recoveryToken">
							<mat-label>Password</mat-label>
							<input matInput type="password" name="password" [(ngModel)]="loginRequest.password" #passwordInput>
						</mat-form-field>
				
						<ng-container *ngIf="recoveryToken || loginRequest.stage == 'passwordChangeNeeded'">
							<p *ngIf="!recoveryToken">
								Your password has expired, please set a new password
							</p>
							<mat-form-field>
								<mat-label>New password</mat-label>
								<input matInput type="password" name="newPassword" [(ngModel)]="loginRequest.newPassword" #passwordInput>
							</mat-form-field>
					
							<mat-form-field>
								<mat-label>Repeat new password</mat-label>
								<input matInput type="password" name="newPassword2" [(ngModel)]="password2">
							</mat-form-field>
						</ng-container>
				
				
						<ng-container *ngIf="loginResponse.nextStage == 'mfa'">
							<mat-form-field>
								<mat-label>Login PIN</mat-label>
								<input matInput #mfaTokenInput [(ngModel)]="loginRequest.mfaToken">
								<mat-hint>{{getMfaTokenHint()}}</mat-hint>
								<button *ngIf="loginResponse.nextStage == 'mfa' && loginResponse.hasPhone" matSuffix (click)="requestToken()">Request PIN</button>
							</mat-form-field>
						</ng-container>
						
						<button *ngIf="!recoveryToken" mat-raised-button color="primary"
							(click)="login()"
						>Login</button>

						<button *ngIf="recoveryToken" mat-raised-button color="warn"
							(click)="setNewPassword()"
						>Set new password</button>
				
						<p *ngIf="loginRequest.stage == 'credentials' && !recoveryToken">
							<a (click)="startPasswordRecovery()" style="cursor: pointer;">
								Forgot your password?
							</a>
						</p>
					}

					@case('sso') {
						<button (click)="sso()" mat-raised-button color="primary">Login with SSO</button>
					}
				}

				@if(!isLast) {
					<div class="loginmethod-divider">
						<hr>
						<span>or</span>
						<hr>
					</div>
				}
			}
			
		</div>
	}

</div>