import { DataUtil } from '@shared/util/data-util';
import type { BranchNameType } from '@shared/types';
import { Setting } from '@shared/settings/setting';
import { BusinessObject } from '@shared/bos/business-object';
import { jsonArrayMember, jsonMember, jsonObject, toJson } from 'typedjson'
import { BoReference } from '@shared/bos/bo-reference'

@jsonObject
@toJson
export class DeploymentContainer {
	@jsonMember(String)
	get __type() { return 'DeploymentContainer' }
	set __type(_) {}

	@jsonMember(String)
	branchName!: BranchNameType
	@jsonArrayMember(String)
	boJsons: string[] = []
	@jsonArrayMember(Setting)
	settings: Setting[] = []

	#boRefsCache: BoReference[] | undefined
	get boRefs() {
		if(!this.#boRefsCache) {
			this.#boRefsCache = this.boJsons.map(boJson => new BusinessObject(JSON.parse(boJson)).getBoReference())
		}
		return this.#boRefsCache
	}

	constructor(init?: Partial<DeploymentContainer>) {
		DataUtil.assignCommonProperties(this, init)
	}
}