import { CodeEditorService } from './code-editor-service'
import { CodeContext } from '@ng-shared/lib/script/code-context'
import { DeclarationService } from '../../services/declaration.service'
import type * as Monaco from 'monaco-editor'
import {
  Component,
  Input,
  forwardRef,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TsCodeType, CodeEditorSize } from '@shared/types';
import { setUpWrappers } from './code-editor-helpers'
import { CodeLanguageType } from '@shared/blocks/decorators/editor-property-types'
import { first } from 'rxjs'

const languageAliases: Record<CodeLanguageType, string> = {
  ts: 'typescript',
  html: 'html',
  scss: 'scss',
  sql: 'sql',
}

const DontHideLines = false
let monaco: typeof import('monaco-editor')

@Component({
  selector: 'code-editor-new',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CodeEditorComponent),
    multi: true
  }],
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeEditorComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  private static isInitialized = false

  @Input() size: CodeEditorSize = 'normal'
  @Input() typeDeclarations: string
  @Input() context: CodeContext
  @Output() blur = new EventEmitter<void>()

  code: string
  visibility: 'visible' | 'hidden' = 'hidden'
  cssClass = ''

  static counter = 0

  onChange = (value: TsCodeType) => {};
  onTouched = () => {};
  disabled = false;

  constructor(
    private declarationService: DeclarationService,
    private editorService: CodeEditorService,
  ) {
    editorService.load()
  }


  public _editor: Monaco.editor.IStandaloneCodeEditor;
  @ViewChild('container', { static: true }) _editorContainer: ElementRef;

  private async initMonaco() {
    if(!this.editorService.loaded) {
      this.editorService.loadingFinished.pipe(first()).subscribe(() => {
        this.initMonaco();
      });
      return;
    }
    if(!this.context) {
      setTimeout(() => this.initMonaco(), 10)
      return
    }

    
    await this.staticInit()

    this.cssClass = this.context.theme == 'vs' ? 'code-editor--invert' : ''
    const editorOptions: Monaco.editor.IStandaloneEditorConstructionOptions = {
      ...(this.getDefaultEditorOptions()),
      theme: 'vs-dark', //this.context.theme, // this is currently not working as there can only be one theme in use. Using CSS class code-editor--invert instead
      language: languageAliases[this.context.codeLanguage],
      automaticLayout: true,
      model: monaco.editor.createModel(
        this.addPrePostfixToCode(this.code, this.context), 
        languageAliases[this.context.codeLanguage],
        monaco.Uri.file(`/code_${++CodeEditorComponent.counter}.ts`)
        ),
    }
  
    this._editor = monaco.editor.create(
      this._editorContainer.nativeElement,
      editorOptions
    )
    this._editor.onDidChangeModelContent(event => {
      const value = this._editor.getValue()
      this.code = this.getCodeWithoutPrePostfix(value, this.context)
      // console.log(value, this.code)
      this.onChange(this.code)
    })
    this._editor.onDidBlurEditorText(() => this.blur.emit())

    this.updateLibs()
    if(!DontHideLines) await setUpWrappers(this._editor, this.context)
    this.setHiddenAreas()
  }

  ngAfterViewInit(): void {
    this.initMonaco();
  }

  addPrePostfixToCode(code: string, context: CodeContext) {
    return (
      (context?.prefix != null ? `${context.prefix}\n` : '')
      + code +
      (context?.postfix != null ? `\n${context.postfix}` : '')
    )
  }
  
  getCodeWithoutPrePostfix(code: string, context: CodeContext) {
    if(context.prefix == null && context.postfix == null) return code

    let lines = code.split('\n')
    lines = lines.slice(
      context.prefix != null ? context.prefix.split('\n').length : 0,
      lines.length - (context.postfix != null ? context.postfix.split('\n').length : 0)
    )

    return lines.join('\n')
  }

  getDefaultEditorOptions(): Monaco.editor.IStandaloneEditorConstructionOptions {
    const defaultOptions: Monaco.editor.IStandaloneEditorConstructionOptions = {
      minimap: { enabled: true },
      autoDetectHighContrast: false,
    }
  
    if(this.size == 'singleline') {
      return {
        ...defaultOptions,
        lineNumbers: 'off',
        glyphMargin: false,
        folding: false,
        scrollbar: {
          vertical: 'hidden',
          horizontal: 'auto',
        },
        minimap: {
          enabled: false,
        },
        scrollBeyondLastLine: false,
        overviewRulerLanes: 0,
        overviewRulerBorder: false,
        hideCursorInOverviewRuler: true,
      }
    }
    
    return {
      ...defaultOptions,
      lineNumbers: num => `${num - this.context.prefixHiddenLineCount}`
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._editor?.dispose()
  }

  private async staticInit() {
    if(CodeEditorComponent.isInitialized) return

    monaco = await import('monaco-editor')

    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ESNext,
      allowNonTsExtensions: true,
      moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
      module: monaco.languages.typescript.ModuleKind.CommonJS,
      typeRoots: ["node_modules/@types"],
      esModuleInterop: true,
    })

    this.declarationService.getStaticTypeDeclarations$().subscribe(declarations => {
      for(const [path, decl] of Object.entries(declarations)) {
        // monaco.languages.typescript.typescriptDefaults.addExtraLib(decl, Uri.file(`/node_modules/${path}`))
        monaco.languages.typescript.typescriptDefaults.addExtraLib(decl, monaco.Uri.file(`/${path}`).toString())
      }
    })

    CodeEditorComponent.isInitialized = true
  }

  updateLibs() {
    if(typeof monaco == 'undefined') return

    if(this.typeDeclarations) {
      const extraLibs = monaco.languages.typescript.typescriptDefaults.getExtraLibs()
      monaco.languages.typescript.typescriptDefaults.addExtraLib(this.typeDeclarations, monaco.Uri.file('/__lowgileLibs.d.ts').toString());
      // console.log(monaco.languages.typescript.typescriptDefaults.getExtraLibs())
    }
  }

  writeValue(value: string): void {
    this.code = value
    this._editor?.setValue(this.addPrePostfixToCode(this.code, this.context))
    this.setHiddenAreas()
  }
  registerOnChange(onChange: (value: TsCodeType)=>void): void {
    this.onChange = onChange
  }
  registerOnTouched(onTouched: ()=>void): void {
    this.onTouched = onTouched
  }
  setDisabledState?(isDisabled: boolean): void {
    // TODO
  }

  setHiddenAreas() {
      if(DontHideLines) return

    if(this._editor) {
      // console.log('hiding')
      const model = this._editor.getModel() as Monaco.editor.ITextModel
      if(model) {
        const lastLine = model.getLineCount();
  
        const hiddenAreas: any[] = []
        if(this.context.prefixHiddenLineCount) {
          hiddenAreas.push({ startLineNumber: 1, endLineNumber: this.context.prefixHiddenLineCount })
        }
        if(this.context.postfixHiddenLineCount) {
          hiddenAreas.push({ startLineNumber: lastLine + this.context.postfixHiddenLineCount - 1, endLineNumber: lastLine })
        }
        
        (this._editor as any).setHiddenAreas(hiddenAreas) // not part of public API
        setTimeout(() => this.visibility = 'visible', 100)
        return
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.context) {
      if(changes.context.previousValue) {
        if(changes.context.currentValue?.prefix !== changes.context.previousValue?.prefix || changes.context.currentValue?.postfix !== changes.context.previousValue?.postfix) {
          // const pureCode = this.getCodeWithoutPrePostfix(this.code, changes.context.previousValue)
          this._editor?.setValue(this.addPrePostfixToCode(this.code, this.context))
        }
      }
      
      this.setHiddenAreas()
    }

    if(changes.typeDeclarations) {
      this.updateLibs()
    }
  }
}
