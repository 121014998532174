import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject, toJson } from 'typedjson'

export const IdentityProviderTypes = {
	oidc: 'OpenID Connect',
} as const
export type IdentityProviderType = keyof typeof IdentityProviderTypes
export const IdentityProviderProviderTypes = {
	m365: 'Microsoft 365',
	custom: 'Custom',
}
export type IdentityProviderProviderType = keyof typeof IdentityProviderProviderTypes

@jsonObject
@toJson
export class IdentityProvider {
	@jsonMember(String)
	get __type(): string { return 'IdentityProvider' }
	set __type(_) {}

	// TODO: add tenantId evantually
	@jsonMember(String)
	id: string
	@jsonMember(String)
	clientId: string
	@jsonMember(String)
	name: string
	@jsonMember(Boolean)
	useForSsoLogin: boolean = true
	@jsonMember(String)
	type: IdentityProviderType = 'oidc'
	@jsonMember(String)
	providerType?: IdentityProviderProviderType = 'm365'
	@jsonMember(String)
	clientSecret: string
	@jsonMember(String)
	idpTenantId: string
	@jsonMember(String)
	customIssuerUrl: string
	@jsonMember(String)
	scope: string = 'openid profile email'

	constructor(init?: Partial<IdentityProvider>) {
		DataUtil.assignCommonProperties(this, init)
	}

	get effectivveIssuerUrl() {
		if(this.type == 'oidc') {
			if(this.providerType == 'm365') {
				return `https://login.microsoftonline.com/${this.idpTenantId}/v2.0`
			}
		}
		return this.customIssuerUrl
	}
}