<div class="top">
	<div class="title" matDialogTitle>
		<h3>Select an icon</h3>
		<div class="configurations-row">
			<mat-form-field class="filter">
				<mat-label>Filter</mat-label>
				<input matInput [ngModel]="filterSubject.value" (ngModelChange)="filterSubject.next($event)">
			</mat-form-field>
			<mat-button-toggle-group class="font-set" [(ngModel)]="fontSet">
				<mat-button-toggle *ngFor="let fontSet of fontSets | keyvalue" [value]="fontSet.key">{{ fontSet.value }}</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
	</div>
	
	<div class="content" matDialogContent>
		<div *ngIf="icons$ | async as icons; else loading">
			<mat-expansion-panel [expanded]="true">
				<mat-expansion-panel-header>
					<mat-panel-title>{{ filterSubject.value.trim() ? 'Exact matches' : 'All icons' }} ({{ icons.exact.size }})</mat-panel-title>
				</mat-expansion-panel-header>
	
				<div class="icon-grid">
					<div class="icon" *ngFor="let iconName of icons.exact" (click)="onSelect(iconName)" matDialogClose>
						<mat-icon [fontSet]="fontSet">{{ iconName }}</mat-icon>
						<span class="icon-name">{{ iconName }}</span>
					</div>
				</div>
			</mat-expansion-panel>

			<mat-expansion-panel *ngIf="filterSubject.value.trim()" [expanded]="true">
				<mat-expansion-panel-header>
					<mat-panel-title>Approximate matches ({{ icons.approximate.size }})</mat-panel-title>
				</mat-expansion-panel-header>
	
				<div class="icon-grid">
					<div class="icon" *ngFor="let iconName of icons.approximate" (click)="onSelect(iconName)" matDialogClose>
						<mat-icon [fontSet]="fontSet">{{ iconName }}</mat-icon>
						<span class="icon-name">{{ iconName }}</span>
					</div>
				</div>
			</mat-expansion-panel>
		</div>

		<ng-template #loading>
			<mat-spinner class="loading"></mat-spinner>
		</ng-template>
	</div>
</div>
