import { Injector, NgZone, ProviderToken } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export class Context {
	static injector: Injector
	static ngZone: NgZone
  
	static set(data: Omit<typeof Context, 'prototype' | 'set' | 'resolve' | 'jsonMapper'>) {
		Object.assign(this, data)
	}

	static resolve<T>(token: ProviderToken<T>, notFoundValue?: T) {
		return this.injector.get(token, notFoundValue)
	}
}

export function openDialogAndGetPromise<R, D>(dialog: MatDialog, dialogComponent: ComponentType<any>, config: MatDialogConfig<D>) {
	return new Promise<R>(resolve => {
		Context.ngZone.run(() => {
			const dialogRef = dialog.open(dialogComponent, config)
			dialogRef.afterClosed().subscribe(result => resolve(result))
		})
	})
}