import { CodeLanguageType } from '@shared/blocks/decorators/editor-property-types'
import { BusinessObject } from '@shared/bos/business-object'
import { ExecutionContext } from '@shared/script/execution-context'
import { Parameter } from '@shared/script/parameter'

export interface CodeContextOptions<T extends BusinessObject = BusinessObject> {
	bo: T
	imports: string
	additionalVariables: Record<string, string>
	additionalLines: string[]
}

export abstract class CodeContext<T extends BusinessObject = BusinessObject> {
	bo: T
	imports: string
	additionalVariables: Record<string, string>
	additionalLines: string[]
	
	prefix: string | null = null
	postfix: string | null = null
	codeLanguage: CodeLanguageType = 'ts'
	tsReturnType = 'any'
	scopeVariables: Parameter[] = []
	theme: 'vs' | 'vs-dark' = 'vs-dark'

	constructor(options: CodeContextOptions<T>) {
		this.bo = options.bo
		this.imports = options.imports
		this.additionalVariables = options.additionalVariables
		this.additionalLines = options.additionalLines
		this.setPrePostfix()
	}
	setPrePostfix() {}

	getVariableDeclarationsLine(furtherVariables?: Record<string, string>, wrapInReadonly = false) {
		const vars = {
			...this.additionalVariables,
			...furtherVariables,
		}

		const decls = Object.entries(vars).map(e => {
			const type = wrapInReadonly ? `Readonly<${e[1]}>` : e[1]
			return `${e[0]}: ${type}`
		}).join(', ').replace(/\n/g, ' ')
		return decls ? `declare const ${decls}` : ''
	}

	get executionContext(): ExecutionContext | null { return null }

	// get prefixTotalLineCount(): number { return this.prefix != null ? this.prefix.split('\n').length : 0 }
	// get postfixTotalLineCount(): number { return this.prefix != null ? this.prefix.split('\n').length : 0 }
	get prefixHiddenLineCount(): number { return 0 }
	get postfixHiddenLineCount(): number { return 0 }
	get prefixDisabledLineCount(): number { return this.prefixHiddenLineCount }
	get postfixDisabledLineCount(): number { return this.postfixHiddenLineCount }
}