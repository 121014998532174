import { TypeReference } from './../data/type-reference';
import { EntitySubset } from './entity-subset'
import { EntityMethod } from './entity-method'
import type { TsCodeType, ModuleIdType } from './../types'
import { BusinessObject } from './business-object'
import { EntityProperty } from './entity-property'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { RestrictableItem } from './restrictions/restrictable-item'
import { Parameter } from '@shared/script/parameter'
import { BoVisitor } from './bo-visitor'
import { DataUtil } from '@shared/util/data-util'
import { EntityRelation } from './entity-relation'
import { BoSpecificDetails, BoSpecificDetailsQuery } from './bo-specific-details'

export const IdTypes = ['none', 'string', 'number'] as const
export type IdTypeType = typeof IdTypes[number]

@jsonObject({ name: 'Entity' })
export class Entity extends BusinessObject {
	@jsonMember(String)
	idType: IdTypeType = 'none'

	@jsonMember(String) baseClassModuleId?: ModuleIdType
	@jsonMember(String) baseClassBoId?: ModuleIdType

	@jsonArrayMember(EntityProperty) properties: EntityProperty[] = []
	@jsonArrayMember(EntityRelation) relations: EntityRelation[] = []
	@jsonArrayMember(EntityMethod) methods: EntityMethod[] = []
	@jsonArrayMember(EntitySubset) subsets: EntitySubset[] = []

	readonly affectsDb: boolean = true

	constructor(init?: Partial<Entity>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getMainSubset() {
		const subset = new EntitySubset()
		this.properties.forEach(p => subset.itemAvailability.set(p.name, 'y'))
		this.relations.forEach(r => r.getPropertyDefinitions().forEach(p => {
			subset.itemAvailability.set(p.name, 'y')
		}))
		this.methods.forEach(m => subset.itemAvailability.set(m.name, 'y'))
		return subset
	}

	getTypeReferences() {
		return [
			new TypeReference({
				kind: 'bo',
				boModuleId: this.moduleId,
				boType: 'Entity',
				boId: this.boId
			}),
			new TypeReference({
				kind: 'type',
				boModuleId: this.moduleId,
				typeName: `${this.boId}Id`
			}),
			...this.subsets.map(subset => new TypeReference({
				kind: 'bo',
				boModuleId: this.moduleId,
				boType: 'Entity',
				boId: `${this.boId}_${subset.name}`
			}))
		]
	}

	getClassNames(): string[] {
		const qualifiedName = this.getQualifiedName()
		return [
			qualifiedName,
			...this.subsets.map(subset => `${qualifiedName}__${subset.name}`)
		]
	}

	getRestrictableItems(): RestrictableItem[] {
		return this.methods
			.filter(m => m.availability == 'callableFromClient')
			.map(m => new RestrictableItem({
				itemName: m.name,
				parameters: [ // list must match what's defined in EntityApiRequest.checkAccess()
					...(m.isStatic ? [] : [
						new Parameter({ name: 'clientVersion', type: this.getQualifiedName() }),
						new Parameter({ name: 'loadDbVersion', type: `() => Promise<${this.getQualifiedName()}>` }),
					]),
					new Parameter({ name: 'accessController', type: `EntityMethodAccessController` }),
					...m.inputs.map(i => new Parameter({ name: i.name, type: i.type}))
				],
				contextImports: {
					'EntityMethodAccessController': '@shared/acl/entity-method-access-controller',
				},
				defaultCode: `\tif(Sys.User.currentUser.?hasPermission('WhicheverPermissionIsRequired')) {\n\t\taccessController.allowClient()\n\t}`,
}))
	}

	visit(visitor: BoVisitor): void {
		this.properties.forEach((property, idx) => property.visit(visitor, ['properties', idx]))
		this.relations.forEach((relation, idx) => relation.visit(visitor, ['relations', idx]))
		this.methods.forEach((method, idx) => method.visit(visitor, ['method', idx]))
	}

	getBoSpecificDetails(query?: BoSpecificDetailsQuery): BoSpecificDetails {
		const subTypes: Record<string, string> = { '': '(Main)' }
		for(const subset of this.subsets) {
			subTypes[subset.name] = `.${subset.name}`
		}

		return {
			...super.getBoSpecificDetails(query),
			subTypes,
		}
	}

	getIdTypeConstructor() {
		return this.idType == 'string' ? 'String' : 'Number'
	}

	getIdTypeInitializer() {
		return this.idType == 'string' ? `''` : '0'
	}
}
