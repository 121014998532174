import { filter, take, tap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs'

export interface SemaphoreLock {
	release(): void
}

export class Semaphore {
	private lockList: SemaphoreLock[] = []
	private readySubject: Subject<SemaphoreLock> = new Subject()

	async obtainLock(timeoutMs = 3000) {
		const lock: SemaphoreLock = {
			release: () => this.releaseCurrentLock()
		}
		
		const lockPromise = new Promise<SemaphoreLock>((res, rej) => {
			const timeout = setTimeout(() => {
				const message = `Timeout waiting for semaphore after ${timeoutMs}ms`
				console.error(message)
				rej(message)
			}, timeoutMs)
			const observable = this.readySubject.pipe(
				filter(nextLock => nextLock === lock),
				take(1),
				tap(() => {
					clearTimeout(timeout)
					subscription?.unsubscribe()
					res(lock)
				})
			)
			const subscription = observable.subscribe(null)
		})

		this.lockList.push(lock)
		if(this.lockList.length == 1) {
			this.readySubject.next(lock)
		}

		return lockPromise
	}

	isLocked() {
		return !!this.lockList.length
	}

	private releaseCurrentLock() {
		this.lockList.splice(0, 1)
		if(this.lockList.length) {
			this.readySubject.next(this.lockList[0])
		}
	}
}