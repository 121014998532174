import type { HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'PdfBlock' })
export class PdfBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'URL',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: (blocks) => (blocks[0] as PdfBlock).contentBase64Code ? false : '(*)',
	})
	urlCode: TsCodeType = ''
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Base64 content',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: (blocks) => (blocks[0] as PdfBlock).urlCode ? false : '(*)',
	})
	contentBase64Code: TsCodeType = ''
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Height',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	heightCode: TsCodeType = `'80vh'`

	constructor(init: Partial<PdfBlock> = {}) {
		super()
		this.exampleText = 'PDF'
		this.init(init)
	}

	producePreview(): HtmlType {
		return `<div style="background: #dddddd; border: 1px solid #bbbbbb; padding: 3em;">${this.transpileExpressionForHtml(this.exampleText)}</div>`
	}
}
