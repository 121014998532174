import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember, toJson } from 'typedjson'
import { BoReference } from './bo-reference'
import { DataUtil } from '@shared/util/data-util'
import { BoVisitor } from './bo-visitor'

export const ServiceInterfaceTypes = {
	mail: 'E-Mail',
} as const

@jsonObject({ name: 'ServiceInterface' })
export class ServiceInterface extends BusinessObject {
	@jsonMember(String)
	interfaceType: keyof typeof ServiceInterfaceTypes = 'mail'
	@jsonMember(String)
	mailDefaultFrom?: string
	@jsonMember
	credentialRef?: BoReference
	
	constructor(init?: Partial<ServiceInterface>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	visit(visitor: BoVisitor): void {
		if(this.credentialRef) visitor.visitBoReference(this.credentialRef, ['credentialRef'])
	}
}