import { jsonMember, jsonObject } from 'typedjson'

export const ScreenScrollPositionClassTargets = {
	screen: { title: 'This screen', element: 'this.elementRef.nativeElement' },
	body: { title: 'HTML body element', element: 'document.body' },
} as const
export type ScreenScrollPositionClassTarget = keyof typeof ScreenScrollPositionClassTargets

@jsonObject
export class ScreenScrollPositionClass {
	@jsonMember(String)
	className: string = ''
	@jsonMember(Number)
	removeIfScrollTopAtMost: number = 0
	@jsonMember(Number)
	addIfScrollTopAbove: number = 0
	@jsonMember(String)
	scrollableElementSelector: string = ''
	@jsonMember(String)
	setOnTarget: ScreenScrollPositionClassTarget = 'screen'
}