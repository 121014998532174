import { encode } from 'html-entities'
import { prettyPrintJson, FormatOptions } from 'pretty-print-json'

export type TextConversionType = 'ascii' | 'base64'
export type ConversionType = TextConversionType | 'binary'

export class CommonTextBridge {
	static readonly encodeUrl = encodeURIComponent
	static readonly decodeUrl = decodeURIComponent

	static convert(input: string, from: TextConversionType, to: TextConversionType): string
	static convert(input: ArrayBuffer, from: 'binary', to: TextConversionType): string
	static convert(input: string, from: TextConversionType, to: 'binary'): ArrayBuffer
	static convert(input: ArrayBuffer, from: 'binary', to: 'binary'): ArrayBuffer
	static convert(input: string | ArrayBuffer, from: ConversionType, to: ConversionType): string | ArrayBuffer {
		return '' // method is overridden in Frontend/Backend bridges
	}

	static truncateWithEllipsis(text: string, maxLength: number) {
		if(text.length <= maxLength) return text
		return text.substring(0, maxLength - 3) + '...'
	}

	static isValidIban(iban: string) {
		if(!iban) return false
		iban = iban.replace(/\s+/g, '').toUpperCase()
		const countryLengths = { AD: 24, AE: 23, AL: 28, AO: 25, AT: 20, AZ: 28, BA: 20, BE: 16, BF: 27, BG: 22, BH: 22, BI: 16, BJ: 28, BR: 29, CH: 21, CI: 28, CM: 27, CR: 22, CV: 25, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, DZ: 24, EE: 20, ES: 24, FI: 18, FO: 18, FR: 27, GB: 22, GE: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21, HU: 28, IE: 22, IL: 23, IR: 26, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28, LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MG: 27, MK: 19, ML: 28, MR: 27, MT: 31, MU: 30, MZ: 25, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29, RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, SN: 28, TN: 24, TR: 26, VG: 24, XK: 20 }

		const country = iban.substring(0, 2)	
		if(iban.length != countryLengths[country as keyof typeof countryLengths]) return false

		iban = iban.substring(4) + iban.substr(0, 4)
		iban = iban.replace(/[A-Z]/g, char => (char.charCodeAt(0) - 55).toString())
		
		var remainder = 0
		for (var i = 0; i < iban.length; i++) {
			var digit = parseInt(iban.charAt(i))
			remainder = (remainder * 10 + digit) % 97
		}
			
		return remainder == 1
	}

	static encodeHtml(text: string) {
		return encode(text)
	}

	static prettyPrintJson(value: any, options?: FormatOptions) {
		return prettyPrintJson.toHtml(value, options)
	}
}

