import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface PromptDialogData {
  title: string
  body: string
  buttonCancelText?: string
  buttonOkText?: string
  dropdownOptions?: [string, string][]
  startingValue?: string
  validationFunction?: (text: string) => (string | null)
  asyncValidationFunction?: (text: string) => Promise<string | null>
  width?: string
}

@Component({
  selector: 'lowgile-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent implements OnInit {
  @ViewChild('top') topDivRef: ElementRef<HTMLDivElement>
  formControl: FormControl
  // reply: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PromptDialogData,
    public dialogRef: MatDialogRef<PromptDialogComponent, string>
  ) { 
    if(!this.data.buttonCancelText) this.data.buttonCancelText = 'Cancel'
    if(!this.data.buttonOkText) this.data.buttonOkText = 'OK'

    this.formControl = new FormControl(data.startingValue ?? '', {
      validators: data.validationFunction ? control => {
        const result = data.validationFunction(control.value)
        if(result) {
          return { error: result }
        }
        return undefined
      } : undefined,
      asyncValidators: data.asyncValidationFunction ? async control => {
        const result = await data.asyncValidationFunction(control.value)
        if(result) {
          return { error: result }
        }
        return undefined
      } : undefined
    })
  }

  @HostListener('keydown.arrowleft')
  @HostListener('keydown.arrowright')
  cycleButtons() {
    const unselectedButton = this.topDivRef.nativeElement.querySelector<HTMLButtonElement>('button:not(:focus)')
    unselectedButton.focus()
  }

  ngOnInit(): void {
  }

  closeIfValid() {
    if(this.formControl.valid) {
      this.dialogRef.close(this.formControl.value)
    }
  }
}
