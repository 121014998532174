import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { PreviewType } from '../block'
import { TextOrCode, TextOrExpression } from '@shared/data/text-or-code'

export const IconBlockFontSets = {
	'material-icons': 'Filled',
	'material-icons-outlined': 'Outlined',
} as const
export type IconBlockFontSet = keyof typeof IconBlockFontSets

@jsonObject({ name: 'IconBlock' })
export class IconBlock extends LeafBlock {
	@jsonMember(TextOrExpression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Icon name',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		spanColumns: 'span 2',
	})
	textCode = new TextOrExpression('delete', 'T')

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 2,
		label: 'Icon style',
		options: Object.entries(IconBlockFontSets)
	})
	fontSet: IconBlockFontSet = 'material-icons'

	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 3,
		label: 'Inline',
	})
	@jsonMember(Boolean) inline: boolean = false

	constructor(init: Partial<IconBlock> = {}) {
		super()
		this.init(init)
	}

	producePreview(): PreviewType {
		let fontIcon = this.textCode.content?.match(/^\s*(['"]?)(?<icon>.*)\1$/)?.groups?.icon
		if(!fontIcon) {
			fontIcon = 'question_mark'
		}
		return {
			type: 'icon',
			fontIcon,
			fontSet: this.fontSet,
		}
	}

	getEditorCategory(): string {
		return 'Core'
	}
}
