import { NgModule } from '@angular/core';
import { ParametersComponent } from './parameters/parameters.component'
import { PropertiesComponent } from '../properties/properties.component'
import { SharedStudioModule } from '../../shared/shared.studio.module'


@NgModule({
  declarations: [ParametersComponent, PropertiesComponent],
  imports: [
    SharedStudioModule,
  ],
  exports: [
    SharedStudioModule,
    ParametersComponent,
    PropertiesComponent,
  ]
})
export class EditCommonModule { }
