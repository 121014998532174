import { EntityUtils } from '@shared/bos/utils/entity-utils'
import { BoTypeSymbol, DataQuery, EntityClass, EntityInfoSymbol, StaticEntityCommon } from '@shared/types'
import { path } from 'ramda'

export interface PropertyChain {
	chain: string[]
	propertyType: string
}
export interface Condition {
	propertyChains: PropertyChain[]
	operator: Operator
	value: string
	caseSensitive: boolean
	columnValueFn: ((item: any) => any) | undefined
}
export const Operators = [':=', ':', '=', '^=', '$=', '<', '<=', '>', '>='] as const
export type Operator = typeof Operators[number]

export class DataQueryFilter<C extends EntityClass> {
	private conditions: Condition[] = []

	constructor(
		private type: C,
		private query: DataQuery<InstanceType<C>>,
		private allClasses: Record<string, Record<string, any>>,
		private columnValueFns?: Record<string, ((item: InstanceType<C>) => any) | undefined>,
	) {
		const searchInProperties = (query.searchInProperties?.length ? query.searchInProperties : type[EntityInfoSymbol].searchInProperties! as typeof query.searchInProperties)!
		const fragments = (query.query ?? '').match(/([^" ]|"[^"]*")+/g) ?? [] // array of individual texts, or 'field:value', 'field=value', 'field:"multiple words"', etc.

		for(let fragment of fragments) {
			fragment = fragment.trim()
			let condition: Condition

			const parts = fragment.match(/^(?<hash>#?)((?<property>[^=:\^\$<>]+)?(?<operator>:=|:|=|\^=|\$=|<=?|>=?))?(?<value>.*)$/)?.groups as {
				hash: string
				property: string
				operator: Operator
				value: string
			}
			if(!parts) continue

			if(parts.hash && !parts.property) {
				// if fragment was just #columnName, "columnName" is attributed to the value; as they value would be empty, ignore this case and continue
				continue
			}
			condition = {
				propertyChains: (parts.property ? [parts.property] : searchInProperties).map(p => ({
					chain: [p],
					propertyType: '',
				})),
				operator: parts.operator as Operator || ':',
				value: parts.value?.trim() || '',
				caseSensitive: query.caseSensitive ?? false,
				columnValueFn: this.columnValueFns?.[parts.property],
			}

			if(!condition.value && condition.operator != '=') continue
			const quoteValueMatch = condition.value.match(/^"(.*)"$/)
			if(quoteValueMatch) condition.value = quoteValueMatch[1]

			for(const propertyChain of condition.propertyChains) {
				const propertyInfo = EntityUtils.getNestedPropertyInfo(this.type, propertyChain.chain[0], this.allClasses)
				if(propertyInfo) {
					propertyChain.chain = propertyInfo.propertyChain
					propertyChain.propertyType = propertyInfo.propertyType
				}
			}
			condition.propertyChains = condition.propertyChains.filter(propertyChain => propertyChain.propertyType)

			if(condition.propertyChains.length) {
				this.conditions.push(condition)
			}
		}
	}

	isSufficientlyDefined(minCharacters: number) {
		for(const condition of this.conditions) {
			if(condition.operator == '=' && condition.value.length) return true
			if(condition.value.length >= minCharacters) return true
		}
		return false
	}

	getConditions() {
		return this.conditions
	}

	filterPredicate(item: InstanceType<C>) {
		condition:
		for(const condition of this.conditions) {
			for(const propertyChain of condition.propertyChains) {
				let propertyValue = condition.columnValueFn?.(item) ?? path(propertyChain.chain, item)
				let propertyStr = String(propertyValue ?? '')
				if(propertyValue?.[BoTypeSymbol] == 'StaticEntity') propertyStr = propertyValue.id
				if(!condition.caseSensitive) propertyStr = propertyStr.toLocaleLowerCase()
				const filterStr = condition.caseSensitive ? condition.value : condition.value.toLocaleLowerCase()

				switch(condition.operator) {
					case ':=':
					case ':': {
						// TODO: change := (not 100% correct yet)
						if(propertyStr.includes(filterStr)) continue condition
						break
					}
					case '=': {
						if(propertyStr == filterStr) continue condition
						break
					}
					case '^=': {
						if(propertyStr.startsWith(filterStr)) continue condition
						break
					}
					case '$=': {
						if(propertyStr.endsWith(filterStr)) continue condition
						break
					}
					case '<': {
						if(typeof propertyValue == 'number' && filterStr.match(/^(\d+|\d*\.\d*)$/)) {
							if(propertyValue < Number('0' + filterStr)) continue condition
						}
						if(propertyStr < filterStr) continue condition
						break
					}
					case '<=': {
						if(typeof propertyValue == 'number' && filterStr.match(/^(\d+|\d*\.\d*)$/)) {
							if(propertyValue <= Number('0' + filterStr)) continue condition
						}
						if(propertyStr <= filterStr) continue condition
						break
					}
					case '>': {
						if(typeof propertyValue == 'number' && filterStr.match(/^(\d+|\d*\.\d*)$/)) {
							if(propertyValue > Number('0' + filterStr)) continue condition
						}
						if(propertyStr > filterStr) continue condition
						break
					}
					case '>=': {
						if(typeof propertyValue == 'number' && filterStr.match(/^(\d+|\d*\.\d*)$/)) {
							if(propertyValue >= Number('0' + filterStr)) continue condition
						}
						if(propertyStr >= filterStr) continue condition
						break
					}
				}
			}
			return false
		}
		return true
	}

	filterList(list: InstanceType<C>[]) {
		return list.filter(item => this.filterPredicate(item))
	}

	static findOperatorAtStart(queryStr: string) {
		for(const operator of Operators) {
			if(queryStr.startsWith(operator)) return operator
		}
		return null
	}
}