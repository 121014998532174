import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
  pure: false,
})
export class SafeUrlPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) {}

  transform(url: string, ...args: unknown[]): unknown {
    if(!url) return url
    return this.sanitizer.bypassSecurityTrustUrl(url)
  }
}
