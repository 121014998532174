import { Injectable, Injector, inject } from '@angular/core'
import { CsrfHeaderName } from '@shared/types'
import { Observable, ReplaySubject, Subject, of, shareReplay } from 'rxjs'
import { AppTrpcServiceInjectionToken } from './app-trpc.service'
import { BaseTrpcService } from './base-trpc.service'

@Injectable({
	providedIn: 'root',
})
export class CsrfService {
	csrfToken: string | undefined
	private loadingSubject: Subject<void> | undefined
	private loading$: Observable<void> | undefined
	private injector = inject(Injector)

	get csrfTokenLoaded$(): Observable<void> {
		// if(this.csrfToken != undefined && !this.loadingSubject) {
		// 	return of(undefined)
		// }
		if(!this.loadingSubject) {
			this.loadingSubject = new Subject<void>()
			this.loading$ = this.loadingSubject.asObservable().pipe(shareReplay(1))
			this.loadCsrfIfNeeded().then(() => {
				this.loadingSubject!.next()
				this.loadingSubject!.complete()
				// this.loadingSubject = undefined
			})
		} 

		return this.loading$!
	}

	private async loadCsrfIfNeeded(force = false) {
		if(!force && this.csrfToken != undefined) return

		const result = await fetch('/__csrfToken')
		this.csrfToken = result.headers.get(CsrfHeaderName) ?? ''
	}

	forgetCsrfToken() {
		this.csrfToken = undefined
		this.loadingSubject = undefined
	}
}