import { JsonMappable } from './json-mappable'
import type { Class } from './../types'
import { IndexedObject, TypedJSON } from 'typedjson'
import * as R from 'ramda'

export interface ValueAndDuplicatePaths {
	valueJson: string
	duplicatePaths: [string, string][]
}

export class BaseJsonMapper<T extends object> {
	constructor(
		private prettyPrint: boolean = false,
		private knownTypes: Record<string, Class<object>> = {},
		private typedJSON?: typeof TypedJSON,
	) {
		if(!typedJSON) this.typedJSON = TypedJSON
	}

	readFromObject<V extends T | T[]>(obj: V, clazz?: Class<T>): V {
		if(!obj || typeof obj != 'object') return obj

		if(obj instanceof Array) {
			return obj.map(item => this.readFromObject(item, clazz)) as V
		}

		if((obj as any).__type == 'ArrayBuffer') {
			// TODO: replace with native implementation when TC39 proposal https://github.com/tc39/proposal-arraybuffer-base64 is widely available
			var binaryString = atob((obj as any).data)
			var bytes = new Uint8Array(binaryString.length)
			for (var i = 0; i < binaryString.length; i++) {
				bytes[i] = binaryString.charCodeAt(i)
			}
			return bytes.buffer as V
		}

		if(obj instanceof Date) return obj

		let type = ''
		if(!clazz) {
			type = (obj as JsonMappable).__type
			if(type) {
				clazz = this.knownTypes[type] as Class<T>
				if(!clazz) {
					const [moduleId, boId, subType] = type?.split('.') ?? []
					if(subType) {
						clazz = (this.knownTypes[`${moduleId}.${boId}`] as any)?.[subType]
					}
				}
			}
		}

		if(clazz) {
			const jsonSerializer = new this.typedJSON!(clazz, {
				errorHandler: err => console.error(err),
				typeResolver: (resolveObj: IndexedObject, knownTypes) => {
					if(resolveObj === obj && clazz) return clazz
					if(resolveObj.__type) {
						let type = this.knownTypes[resolveObj.__type]
						if(!type) {
							const [moduleId, boId, subType] = resolveObj.__type?.split('.') ?? []
							if(subType) {
								type = (this.knownTypes[`${moduleId}.${boId}`] as any)?.[subType]
							}
						}
						if(type) return type
						// TODO: VALIDATE IF NOT BROKEN: CHANGED [] TO .GET ON 2023-10-11
						return knownTypes.get((resolveObj as JsonMappable).__type)
					}
					if(resolveObj instanceof Array) {
						return Array
					}
					return undefined
				},
			})
			const parsed: T = <any>jsonSerializer.parse(obj)
	
			if (!parsed) throw new Error(`Failed to parse object of type "${type}" from JSON`)
			return parsed as V
		}

		const entries = Object.entries(obj)
		entries.forEach(e => e[1] = this.readFromObject(e[1]))
		return Object.fromEntries(entries) as V
	}

	readFromJson(json: string, clazz?: Class<T>) {
		const jsObj: any = JSON.parse(json)
		const obj = this.readFromObject(jsObj, clazz)
		return obj
	}

	writeToObject<V extends T | T[]>(obj: V, convertTypedJsonObjectsToPlainObjects = true): V {
		if(typeof obj != 'object' || obj == null) return obj

		if(obj instanceof Array) {
			return obj.map(item => this.writeToObject(item)) as V
		}

		if(obj instanceof ArrayBuffer) {
			// TODO: replace with native implementation when TC39 proposal https://github.com/tc39/proposal-arraybuffer-base64 is widely available
			let ascii = ''
			const uint8 = new Uint8Array(obj)
			for(let i = 0; i < obj.byteLength; i++) {
				ascii += String.fromCharCode(uint8[i])
			}
			return {
				__type: 'ArrayBuffer',
				data: btoa(ascii),
			} as V
		}

		if(obj instanceof Date) return obj

		if(this.isTypedJsonObject(obj)) {
			if(!convertTypedJsonObjectsToPlainObjects) return obj

			const objJson = this.writeToJson(obj)
			const plainObj = JSON.parse(objJson)
			return plainObj
		}

		const entries = Object.entries(obj).map(([k, v]) => {
			return [k, this.writeToObject(v)]
		})
		return Object.fromEntries(entries)
	}

	writeToJson(obj: T | T[]) {
		if(this.isTypedJsonObject(obj)) {
			const jsonSerializer = new this.typedJSON!(obj.constructor as Class<T>, {
				indent: this.prettyPrint ? '\t' as any : undefined,
			})
			// obj is already a TypedJSON-compatible class instance
			const json = jsonSerializer.stringify(obj)
			return json
		} else {
			// obj is an plain object or array
			return JSON.stringify(obj, (key, value) => {
				if(this.isTypedJsonObject(obj)) {
					const objJson = this.writeToJson(obj)
					const plainObj = JSON.parse(objJson)
					return plainObj
				}
				return value
			})
		}
	}

	writeToJsonRemovingDuplicates(obj: T): ValueAndDuplicatePaths {
		const objPaths = new Map<any, string[]>()
		function convertValue(value: any, path: string): any {
			if(value && typeof value == 'object') {
				const paths = objPaths.get(value) ?? []
				paths.push(path)
				objPaths.set(value, paths)
				
				if(paths.length == 1) {
					const entries = Object.entries<any>(value).map(([k, v]) => [k, convertValue(v, path ? `${path}/${k}` : k)])
					console.log(value, path, entries)
					return Object.fromEntries(entries)
				} else {
					return undefined
				}
			}

			return value
		}

		const value = convertValue(obj, '')
		const valueJson = this.writeToJson(value)
		const duplicatePaths = [...objPaths.values()].filter(paths => paths.length > 1).flatMap(paths => {
			const [first, ...others] = paths
			return others.map(other => [other, first] as [string, string])
		})

		return {
			valueJson,
			duplicatePaths,
		}
	}

	readFromValueAndDuplicatePaths(valueAndDuplicatePaths: ValueAndDuplicatePaths) {
		let obj = this.readFromJson(valueAndDuplicatePaths.valueJson)

		for(const [source, target] of valueAndDuplicatePaths.duplicatePaths) {
			const sourceParts = source.split('/')
			const targetParts = target.split('/')
			
			const val = R.path(sourceParts, obj)
			obj = R.assocPath(targetParts, val, obj)
		}

		return obj
	}

	isTypedJsonObject(obj: any) {
		if(!obj || typeof obj != 'object') return false
		if(obj instanceof Date) return false
		if(obj instanceof Array) return false
	
		return obj.constructor && obj.constructor !== Object
	}
}