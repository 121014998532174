import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject, toJson } from 'typedjson'

@jsonObject
@toJson
export class ScreenOutput {
	@jsonMember(String)
	name: string
	@jsonMember(String)
	outputTsType: string
	@jsonMember(Boolean)
	canDriveProcess: boolean = false

	constructor(init: ScreenOutput) {
		DataUtil.assignCommonProperties(this, init)
	}
}