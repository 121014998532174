<div #top [style.width]="data.width">
	<h1 mat-dialog-title>{{ data.title }}</h1>
	<div mat-dialog-content>
		<div>
			{{ data.body }}
		</div>
		<div *ngIf="data.dropdownOptions; else: text">
			<select [formControl]="formControl">
				<option *ngFor="let option of data.dropdownOptions" [ngValue]="option[0]">
					{{option[1]}}
				</option>
			</select>
			<mat-error *ngIf="formControl.errors?.error">{{ formControl.errors?.error }}</mat-error>
		</div>
		<ng-template #text>
			<div style="display: flex; flex-direction: column;">
				<input type="text" [formControl]="formControl" #replyInput cdkFocusInitial (keyup.enter)="closeIfValid()">
				@if((formControl.touched || formControl.dirty) && formControl.errors?.error) {
					<mat-error>{{ formControl.errors?.error }}</mat-error>
				} @else {
					<span>&nbsp;</span>
				}
			</div>
		</ng-template>
	</div>
	
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close>{{ data.buttonCancelText }}</button>
		<button mat-button [mat-dialog-close]="formControl.value" [disabled]="formControl.invalid">{{ data.buttonOkText }}</button>
	</mat-dialog-actions>
</div>