import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { DataUtil } from '@shared/util/data-util'
import type { TsCodeType } from '@shared/types'

@jsonObject({ name: 'UserDefinedConfiguration' })
export class UserDefinedConfiguration extends BusinessObject {
	@jsonMember(String)
	tsType: TsCodeType = ''

	constructor(init?: Partial<UserDefinedConfiguration>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getClassNames(): string[] {
		return [this.getQualifiedName()]
	}
}
