import { CodeContextFactory } from '@ng-shared/lib/script/code-context-factory'
import { RawTypeDeclarationsType, DeclarationUtil } from '@shared/util/declaration-util';
import { Lock, LockLevelType } from '@shared/lock/lock';
import { TrackByBasis } from '@shared/util/track-by-basis';
import { MatDialog } from '@angular/material/dialog';
import { BusinessObject } from '@shared/bos/business-object';
import { ErrorService } from '@ng-shared/lib/services/error.service';
import { BoParamsType, BoReferencesFilter, BusinessObjectTypeType, Class, TypeIcons } from '@shared/types';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { OnInit, OnDestroy, HostListener, Component, ApplicationRef, NgZone, Renderer2, Injector, Signal, effect, inject, signal, InjectionToken, Inject, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EditorHelperService } from './editor-helpers/editor-helper.service'
import { ExecutionContext, ExecutionContextName, ExecutionLocation } from '@shared/script/execution-context'
import { BehaviorSubject, zip, of, from } from 'rxjs';
import { tap, map, switchMap, filter, shareReplay, take, share, distinctUntilChanged } from 'rxjs/operators';
import { LazyCache } from '@shared/data/lazy-cache'
import { LowgileJsonMapper } from '@shared/data/lowgile-json-mapper'
import { CodeContext, CodeContextOptions } from '@ng-shared/lib/script/code-context'
import { openDialogAndGetPromise } from '../util/ui-util'
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../dialogs/confirmation-dialog/confirmation-dialog.component'
import { StudioTrpcService } from '@ng-shared/lib/services/studio-trpc.service'
import { BoReference } from '@shared/bos/bo-reference'
import { Context } from '../util/ui-util'
import { toSignal } from '@angular/core/rxjs-interop'
import { CsrfService } from '@ng-shared/lib/services/csrf.service'

export const AbstractEditorComponentInjectionToken = new InjectionToken<AbstractEditorComponent<BusinessObject>>('AbstractEditorComponent')
@Component({
  template: ''
})
export class AbstractEditorComponent<T extends BusinessObject> extends TrackByBasis implements OnInit, OnDestroy {
  protected trpc = inject(StudioTrpcService)
  protected route = inject(ActivatedRoute)
  params: BoParamsType;
  params$ = this.route.params.pipe(
    map(params => ({ ...params, boType: this.boType }) as BoParamsType),
  )
  paramsSignal = toSignal(this.params$)
  bo: T;
  #boSignal = signal<T | undefined>(undefined)
  boSignal = this.#boSignal.asReadonly()
  readonly boType: BusinessObjectTypeType
  reloadLocalTypeDeclarationsSubject = new BehaviorSubject<void>(undefined)
  rawTypeDeclarations$ = combineLatest([
    this.params$,
    this.reloadLocalTypeDeclarationsSubject,
  ]).pipe(
    switchMap(([params]) => {
      // console.log(params)
      return this.trpc.subscriptionAsObservable(
        c => c.bo.getTypeDeclarations$, {
          branchName: params.branchName,
          moduleId: this.bo.moduleId,
          executionContexts: this.relevantExecutionContexts,
        }
      )
    }),
    shareReplay(1),
  )
   
  typeDeclarations$ = combineLatest([
    this.rawTypeDeclarations$,
    this.reloadLocalTypeDeclarationsSubject,
  ]).pipe(
    map(([rawTypeDeclarations]) => {
      // console.log('rawTypeDeclarations', rawTypeDeclarations)
      const typeDeclarations = this.calculateTypeDeclarations(rawTypeDeclarations)
      // console.log('typeDeclarations', typeDeclarations)
      return typeDeclarations
    }),
    shareReplay(1),
  )

  private lockSubscription: Subscription
  lock?: Lock
  protected subscriptions: Subscription[] = []
  readonly relevantExecutionContexts: ExecutionContextName[] | '*' = []
  protected codeContextObservables = new LazyCache<string, any, Observable<CodeContext>>()
  private cachedBoSignals = new Map<string, Signal<BusinessObject>>()

  protected router = inject(Router)
  protected titleService = inject(Title)
  protected errorService = inject(ErrorService)
  protected dialog = inject(MatDialog)
  protected ngZone = inject(NgZone)
  protected applicationRef = inject(ApplicationRef)
  protected csrfService = inject(CsrfService)
  protected renderer = inject(Renderer2)
  protected injector = inject(Injector)
  protected helperService = inject(EditorHelperService)
  
  constructor() {
    super()
    Context.set({
      injector: this.injector,
      ngZone: this.ngZone,
    })
    this.helperService.editorComponent = this
  }

  protected getBoClass(): Class<T> {
    throw new Error('getBoClass() must be implemented')
  }

  @HostListener('document:keydown.control.s', ['$event'])
  async save(event?: KeyboardEvent) {
    event?.preventDefault();
    if(!this.bo.isModifiable) return
    
    const success = await this.trpc.client.bo.saveBo.mutate({
      branchName: this.params.branchName,
      bo: this.bo
    })
    this.errorService.message(success, 'Successfully saved', 'Failed to save')
  }
  
  @HostListener('document:keydown.control.shift.s', ['$event'])
  async saveAs(event?: KeyboardEvent) {
    event?.preventDefault();
    if(!this.bo.isModifiable) return
    
    const qualifiedName = prompt(`Save ${this.boType} as:`, this.bo.getQualifiedName())
    if(!qualifiedName) return

    let [moduleId, boId] = qualifiedName.split('.')
    if(!boId) {
      boId = moduleId
      moduleId = this.bo.moduleId
    }

    const boClass = this.getBoClass()
    const newBo = new boClass({
      ...this.bo,
      moduleId,
      boId,
    })

    const success = await this.trpc.client.bo.saveBo.mutate({
      branchName: this.params.branchName,
      bo: newBo
    })
    this.errorService.message(success, 'Successfully saved', 'Failed to save')
    if(success) {
      this.router.navigate(['edit', this.boType, this.params.branchName, moduleId, boId])
    }
  }

  ngOnInit() {
    globalThis.mapper = new LowgileJsonMapper(true)
    globalThis.editor = this

    this.subscriptions.push(
      this.route.params.subscribe(
        async (params: Pick<BoParamsType, 'branchName' | 'moduleId' | 'boId'>) => {
          this.params = this.helperService.params = { ...params, boType: this.boType }
          this.titleService.setTitle(`${TypeIcons[this.boType]} ${this.params.moduleId}.${this.params.boId}`)

          this.bo = await this.trpc.client.bo.getBo.query({
            branchName: this.params.branchName,
            boType: this.params.boType,
            boId: this.params.boId,
            moduleId: this.params.moduleId,
          }) as T
          if(!this.bo) {
            const doCreate = await openDialogAndGetPromise<boolean, ConfirmationDialogData>(this.dialog, ConfirmationDialogComponent, {
              data: {
                title: `${this.boType} does not exist`,
                body: `${this.boType} ${this.params.moduleId}.${this.params.boId} does not exist. Would you like to create it?`,
                buttonCancelText: 'No',
                buttonConfirmText: 'Yes',
              }
            })
            
            if(doCreate) {
              const boClass = this.getBoClass()
              this.bo = new boClass({
                moduleId: this.params.moduleId,
                boId: this.params.boId
              } as Partial<BusinessObject>)
            } else {
              window.close()
            }
          }
        
          this.bo.validateAndFix()

          this.requestLock('partial')

          this.ngZone.run(async () => {
            // this.bo = bo
            this.onBoLoaded()
            this.forceUpdateTypeDeclarations()

            this.onAfterParamsChange()
          })

          globalThis.bo = this.bo
          this.#boSignal.set(this.bo)
        }
      ),

      this.csrfService.csrfTokenLoaded$.pipe(
        switchMap(() => this.trpc.subscriptionAsObservable(c => c.event.events$, { eventNames: ['bosRecompiled'] }))
      ).subscribe(event => {
        this.forceUpdateTypeDeclarations()
      })
    )

    this.ngZone.runOutsideAngular(() => {
      this.renderer.listen(document.body, 'mousemove', () => {})
    })  
  }

  onBoLoaded() {}

  onAfterParamsChange() {}

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  public forceUpdateTypeDeclarations() {
    this.reloadLocalTypeDeclarationsSubject.next()
    this.reloadCodeContexts()
    // this.rawTypeDeclarations$.next(this.rawTypeDeclarations$.getValue())
  }

  protected calculateTypeDeclarations(rawTypeDeclarations: RawTypeDeclarationsType) {
     const typeDeclarations = DeclarationUtil.calculateTypeDeclarations(rawTypeDeclarations)
    // console.log(typeDeclarations)
    return typeDeclarations
  }

  hasFullLock() {
    return this.lock && this.lock.lockLevel == 'full'
  }

  toggleFullLock() {
    this.requestLock(this.hasFullLock() ? 'partial' : 'full')
  }

  requestLock(lockLevel: LockLevelType | null) {
    this.lockSubscription?.unsubscribe()
    if(!lockLevel) return

    this.lockSubscription = this.trpc.subscriptionAsObservable(c => c.lock.request, {
      lock: Lock.forBo(this.bo, {
        lockLevel: this.hasFullLock() ? 'partial' : 'full',
      })
    }).subscribe(response => {
      this.lock = response.successful ? response.lock : undefined
    })
    this.subscriptions.push(this.lockSubscription)
  }

  getWrapperImports$(executionContext: ExecutionContext) {
    return DeclarationUtil.getWrapperImports$(executionContext, this.rawTypeDeclarations$, this.params.moduleId)
  }

  getCodeContext$(area: string, forObject: any, codeContextAdditionalObject?: any, additionalVariables?: Record<string, string>, additionalLines?: string[]) {
    // console.log('getCodeContext$', area, forObject)
    return this.codeContextObservables.get(area, forObject, obj => {
      const context = CodeContextFactory.create({
        bo: this.bo,
        imports: '', // not available yet
        additionalVariables,
        additionalLines,
      }, obj, area)

      const context$ = this.getWrapperImports$(context.executionContext).pipe(
        distinctUntilChanged(),
        map(imports => {
          const additionalObj = codeContextAdditionalObject ?? this.getCodeContextAdditionalObject(area, forObject)
          const context = CodeContextFactory.create({
            bo: this.bo,
            imports, // is available now
            additionalVariables,
            additionalLines,
          }, obj, area, additionalObj)
          // console.log('context', context)

          return context
        }),
        shareReplay(1),
      )

      return context$
    })
  }

  async getBoReferences<T extends BusinessObjectTypeType = BusinessObjectTypeType>(boTypes: '*' | BusinessObjectTypeType[], includeImportedModules: boolean, predefinedFilter?: BoReferencesFilter) {
    const boRefs = await this.trpc.client.bo.getBoReferences.query({
      branchName: this.params.branchName,
      moduleId: this.params.moduleId,
      boTypes,
      includeImportedModules,
      predefinedFilter,
    })

    return boRefs.map(boRef => new BoReference<T>(boRef)) as BoReference<T>[]
  }

  getBoReferences$(boTypes: '*', includeImportedModules: boolean, predefinedFilter?: BoReferencesFilter): Observable<BoReference[]>
  getBoReferences$<T extends BusinessObjectTypeType = BusinessObjectTypeType>(boTypes: T[], includeImportedModules: boolean, predefinedFilter?: BoReferencesFilter): Observable<BoReference<T>[]>
  getBoReferences$<T extends BusinessObjectTypeType = BusinessObjectTypeType>(boTypes: '*' | BusinessObjectTypeType[], includeImportedModules: boolean, predefinedFilter?: BoReferencesFilter) {
    return this.params$.pipe(
      switchMap(params => this.trpc.queryAsObservable(
        client => client.bo.getBoReferences.query({
          branchName: params.branchName,
          moduleId: params.moduleId,
          boTypes,
          includeImportedModules,
          predefinedFilter,
        })
      )),
      map(boRefs => boRefs.map(boRef => new BoReference<T>(boRef)) as BoReference<T>[]),
    )
  }

  getBoReferencesAsSignal(boTypes: '*', includeImportedModules: boolean, predefinedFilter?: BoReferencesFilter): Signal<BoReference[]>
  getBoReferencesAsSignal<T extends BusinessObjectTypeType = BusinessObjectTypeType>(boTypes: T[], includeImportedModules: boolean, predefinedFilter?: BoReferencesFilter): Signal<BoReference<T>[]>
  getBoReferencesAsSignal<T extends BusinessObjectTypeType = BusinessObjectTypeType>(boTypes: '*' | BusinessObjectTypeType[], includeImportedModules: boolean, predefinedFilter?: BoReferencesFilter) {
    return this.trpc.asSignal(
      client => client.bo.getBoReferences.query({
        branchName: this.params.branchName,
        moduleId: this.params.moduleId,
        boTypes,
        includeImportedModules,
        predefinedFilter,
      }),
      undefined,
      boRefs => boRefs.map(boRef => new BoReference<T>(boRef)) as BoReference<T>[],
    )
  }

  getBos$<T extends BusinessObject>(boClass: Class<T>, moduleId?: string): Observable<T[]>{
    const boType = new boClass().__type
    return this.params$.pipe(
      switchMap(params => this.trpc.queryAsObservable(
        client => client.bo.getBos.query({
          branchName: params.branchName,
          moduleId: moduleId || params.moduleId,
          boType,
        })
      )),
      map(boRefs => {
        const mapper = new LowgileJsonMapper(false)
        return boRefs.map(boRef => mapper.readFromObject(boRef) as T)
      }),
      shareReplay(1),
    )
  }

  async getBo<T extends BusinessObject = BusinessObject>(boRef: BoReference) {
    const bo = await this.trpc.client.bo.getBo.query({
      branchName: this.params.branchName,
      boType: boRef.boType,
      moduleId: boRef.moduleId,
      boId: boRef.boId,
    }) as T
    return bo
  }

  async getBos<T extends BusinessObject = BusinessObject>(boType: BusinessObjectTypeType, moduleId?: string) {
    const bos = await this.trpc.client.bo.getBos.query({
      branchName: this.params.branchName,
      boType,
      moduleId,
    }) as T[]
    return bos
  }

  getBoSignal<T extends BusinessObject = BusinessObject>(boRef: BoReference, processResponse?: (bo: T) => void) {
    let signal = this.cachedBoSignals.get(boRef.getQualifiedTypeAndName()) as Signal<T>
    if(!signal) {
      signal = this.trpc.asSignal(c => c.bo.getBo.query({
        branchName: this.params.branchName,
        boType: boRef.boType,
        moduleId: boRef.moduleId,
        boId: boRef.boId,
      }), undefined, processResponse
    ) as Signal<T>
      this.cachedBoSignals.set(boRef.getQualifiedTypeAndName(), signal)

      // if(processResponse) {
      //   effect(() => {
      //     processResponse(signal)
      //   }, { injector: this.injector })
      // }
    }

    return signal
  }

  getCodeContextAdditionalObject(area: string, forObject: any) {
    return null
  }

  reloadCodeContext(area: string, forObject: any) {
    this.codeContextObservables.clearItem(area, forObject)
  }

  reloadCodeContexts() {
    this.codeContextObservables.clear()
  }

  reloadCodeContextsForArea(area: string) {
    this.codeContextObservables.clearArea(area)
  }

  $log(text: string) {
    console.count(text)
    return ''
  }
}
