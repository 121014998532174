import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { DataUtil } from '@shared/util/data-util'
import { BoReference } from './bo-reference'

@jsonObject({ name: 'Role' })
export class Role extends BusinessObject {
	@jsonArrayMember(BoReference)
	childRoles: BoReference[] = []

	@jsonArrayMember(BoReference)
	permissions: BoReference[] = []

	constructor(init?: Partial<Role>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}
}
