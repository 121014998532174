import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class Tenant {
	@jsonMember(String) id!: string
	@jsonMember(String) name?: string
	@jsonMember(String) parentId?: string

	constructor(init?: Partial<Tenant>) {
		DataUtil.assignCommonProperties(this, init)
	}
}