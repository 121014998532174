import { PathOccurenceMap, PathType, ValueType } from './path-occurence-map'

export class PathOccurenceVisitor {
	visit(obj: ValueType, prefix?: PathType) {
		const map = new PathOccurenceMap()

		function visit(obj: ValueType, prefix?: PathType) {
			if(obj == null) return

			for(const [key, value] of Object.entries(obj)) {
				const path = [...prefix, key]
				
				if(value && typeof value == 'object') {
					map.set(path, value)
					visit(value, [...prefix, key])
				}
			}
		}

		visit(obj, prefix)
		return map
	}
}