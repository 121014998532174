<input type="text"
	matInput
	[matAutocomplete]="auto"
	[formControl]="formControl"
	(keyup)="onFilterChange($event)"
	#inputEl
>

<mat-autocomplete #auto="matAutocomplete"
	autoActiveFirstOption
	(optionSelected)="onSelected($event)"
	[displayWith]="getOptionDisplayText"
>
	<mat-option *ngFor="let option of filteredOptions" [value]="option">
		{{ option?.getDisplayText()}}
	</mat-option>
</mat-autocomplete>