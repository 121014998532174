import type { HtmlType } from './../../types'
import { Block } from '../block'

export abstract class LogicBlock extends Block {
	canHaveChild(child: Block): boolean {
		if (!this.parent) return true

		return this.parent.canHaveChild(child)
	}

	canHaveParent(parent: Block | undefined): boolean {
		let nonLogicParent = parent
		while (nonLogicParent && !(nonLogicParent instanceof LogicBlock)) {
			nonLogicParent = nonLogicParent.parent
		}
		if (!nonLogicParent) return true

		if (this.children) {
			for (let child of this.children) {
				if (!child.canHaveParent(nonLogicParent)) {
					return false
				}
			}
		}

		return true
	}

	isLogicBlock(): this is LogicBlock {
		return true
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'orange'
	}

	getPreviewDirection() {
		return this.parent?.getPreviewDirection() ?? 'column'
	}
}
