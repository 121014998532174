import { DataUtil } from '@shared/util/data-util'
import { UserIdCtor, type UserIdType } from '@shared/types'
import { JsonMappable } from '@shared/data/json-mappable'
import { jsonObject, jsonMember, jsonArrayMember, toJson } from 'typedjson'
import * as z from 'zod'

export const userRecordUpdateSchema = z.strictObject({
	userName: z.ostring(),
	firstName: z.ostring(),
	lastName: z.ostring(),
	email: z.ostring(),
	mobilePhone: z.ostring(),
})
export type UserRecordUpdate = z.infer<typeof userRecordUpdateSchema>

@jsonObject
@toJson
export class UserRecord implements Required<UserRecordUpdate> {
	@jsonMember(UserIdCtor)
	id: UserIdType = UserIdCtor()

	@jsonMember(String)
	get __type(): string { return 'UserRecord' }
	set __type(_) { /*ignore*/ }

	@jsonMember(String)
	userName: string = ''
	
	@jsonMember(String)
	firstName: string = ''
	
	@jsonMember(String)
	lastName: string = ''

	@jsonMember(String)
	email: string = ''

	@jsonMember(String)
	mobilePhone: string = ''
	
	@jsonArrayMember(String)
	roleNames: string[] = []

	@jsonArrayMember(String)
	permissionNames: string[] = []
	
	@jsonArrayMember(String)
	groupNames: string[] = []
	constructor(init?: Partial<UserRecord> & Pick<UserRecord, 'roleNames' | 'permissionNames' | 'groupNames'>) {
		DataUtil.assignCommonProperties(this, init)
	}

	getName() {
		return `${this.firstName} ${this.lastName}`
	}

	hasRole(roleName: string) {
		return !!this.roleNames.find(role => role == roleName)
	}

	hasSystemRole(roleName: string) {
		return this.hasRole(`System.${roleName}`)
	}

	hasPermission(permissionName: string) {
		return !!this.permissionNames.find(permission => permission == permissionName)
	}

	hasSystemPermission(permissionName: string) {
		return this.hasPermission(`System.${permissionName}`)
	}
}

export type UserRecordOptions = {
	includeEmail?: boolean
	includePhone?: boolean
	includeRoles?: boolean
	includePermissions?: boolean
	includeGroups?: boolean
} | '*'