import { FormInputBlock } from './form-input-block';
import type { HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { Expression, TextOrExpression } from '@shared/data/text-or-code'

@jsonObject({ name: 'DatePickerBlock' })
export class DatePickerBlock extends FormInputBlock {
	@jsonMember(TextOrExpression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
	})
	labelCode = new TextOrExpression('', 'T')

	@jsonMember(Expression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Binding',
		codeLanguage: 'ts',
		tsReturnType: () => 'Date',
		editorSize: 'singleline',
		isBinding: true,
		isMandatory: () => true,
	})
	bindingCode = new Expression()

	constructor(init: Partial<DatePickerBlock> = {}) {
		super()
		this.exampleText = '31.12.2020'
		this.init(init)
	}

	producePreview(): HtmlType {
		return `<input tabindex="-1" value="${this.exampleText || '31.12.2020'}">`
	}

	getEditorCategory(): string {
		return 'Core'
	}
}
