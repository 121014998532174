import { ApplicationRef, ChangeDetectorRef, NgZone } from '@angular/core'
import { Breakpoints, ScreenSize, ScreenSizes } from '@shared/types'
import { DataUtil } from '@shared/util/data-util'
import { AppContext } from '../app-context'
import { ChangeDetectionService } from '../services/change-detection.service'
import { LayoutService } from '../services/layout-service'

interface LayoutBridge extends Breakpoints {
	decideBySize<T>(options: Partial<Record<ScreenSize, T>> | [T, T, T, T, T]): T
}

let bridge: LayoutBridge = {
	sizeEquals: {} as any,
	sizeIsGreaterThan: {} as any,
	sizeIsGreaterThanOrEqual: {} as any,
	sizeIsLessThan: {} as any,
	sizeIsLessThanOrEqual: {} as any,
	isHandset: false,
	isTablet: false,
	isWeb: true,
	isHandsetPortrait: false,
	isTabletPortrait: false,
	isWebPortrait: false,
	isHandsetLandscape: false,
	isTabletLandscape: false,
	isWebLandscape: true,
	isPortrait: false,
	isLandscape: true,
	screenSize: 'Large',

	decideBySize<T>(options: Partial<Record<ScreenSize, T>> | [T, T, T, T, T]) {
		if(options instanceof Array) {
			const idx = ScreenSizes.findIndex(ss => ss == this.screenSize)
			return (options[idx] ?? options.at(-1))!
		}
		let lastEncounteredValue: T = options.XSmall!

		for(const screenSize of ScreenSizes) {
			if(this.sizeIsLessThan[screenSize]) {
				return lastEncounteredValue
			}
			const option = options[screenSize]
			if(option !== undefined) lastEncounteredValue = option
		}
		return lastEncounteredValue
	},
}
setTimeout(() => {
	if(!AppContext.injector) return
	const layoutService = AppContext.injector.get(LayoutService)
	layoutService.breakpoints$.subscribe(bp => {
		Object.assign(bridge, bp)
	})
}, 0)

export const FrontendLayoutBridge = new Proxy<LayoutBridge>({} as any, {
	get(target, p, receiver) {
		return Reflect.get(bridge, p)
	},
	set() { return false }
})