import { AsyncPipe } from '@angular/common'
import { Pipe, ɵisPromise, ɵisSubscribable } from '@angular/core';
import { Observable, Subscribable } from 'rxjs'

@Pipe({
  name: 'maybeAsync'
})
export class MaybeAsyncPipe extends AsyncPipe {
	transform<T>(obj: Observable<T> | Subscribable<T> | Promise<T> | T): T | null {
		if(ɵisPromise(obj) || ɵisSubscribable(obj)) {
			return super.transform<T>(obj)
		}
		return obj
  	}
}
