import { TRPCLink } from '@trpc/client'
import { Unsubscribable, observable } from '@trpc/server/observable'
import { CsrfService } from '../services/csrf.service'

export function csrfTokenLink(csrfService: CsrfService): TRPCLink<any> {

	return () => {
		return ({ next, op }) => {
			return observable(observer => {
				let unsubscribed = false
				let subscription: Unsubscribable | undefined

				if(op.path == 'auth.csrfToken') {
					subscription = next(op).subscribe(observer)
				} else {
					csrfService.csrfTokenLoaded$.subscribe({
						complete() {
							if(!unsubscribed) {
								subscription = next(op).subscribe(observer)
							}
						}
					})
				}

				return () => {
					unsubscribed = true
					subscription?.unsubscribe()
				}
			})
		}
	}
}