import { jsonMember, jsonObject } from 'typedjson'
import { Block } from '..'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'NamedGridBlock' })
export class NamedGridBlock extends Block {
	@jsonMember
	@ScreenEditorInput({
		inputType: 'input',
		order: 1,
		label: 'Rows (CSS grid-template-rows)',
		contentType: 'text',
		elementType: 'input',
		isMandatory: () => true,
	})
	rows: string = 'auto 1fr auto'
	
	@jsonMember
	@ScreenEditorInput({
		inputType: 'input',
		order: 2,
		label: 'Columns (CSS grid-template-columns)',
		contentType: 'text',
		elementType: 'input',
		isMandatory: () => true,
	})
	columns: string = 'auto 1fr auto'

	
	@jsonMember
	@ScreenEditorInput({
		inputType: 'input',
		order: 3,
		label: 'Layout (CSS grid-template-areas)',
		contentType: 'text',
		elementType: 'textarea',
		isMandatory: () => true,
	})
	layout: string = 'header header header\nleft main right\nfooter footer footer'

	constructor(init: Partial<NamedGridBlock> = {}) {
		super()
		this.init(init)
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'black'
	}

	getTitleColor(): string {
		return 'white'
	}

	getEditorCategory(): string {
		return 'Layout'
	}

	shouldRenderContentAreaWrappers() {
		return true
	}
}