import { TsCodeType } from '@shared/types';
import { LeafBlock } from '@shared/blocks/leaf-block';
import { FormValidationRule } from './form-validation-rule'
import { jsonArrayMember, jsonMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { Expression, TextOrExpression } from '@shared/data/text-or-code'

export const FormInputDisplayTypes = {
	formField: 'Form Field box with label',
	// labelOnLeftGrouped: 'Label on left side of input field (grouped)',
	// labelOnLeftSeparate: 'Label on left side of input field (separate; useful in grid layouts)',
	onlyInput: 'Only input field',
}
export type FormInputDisplayType = keyof typeof FormInputDisplayTypes

export abstract class FormInputBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 101,
		label: 'Display type',
		tab: 'Appearance',
		options: Object.entries(FormInputDisplayTypes),
	})
	displayType: FormInputDisplayType = 'formField'

	declare labelCode?: TextOrExpression
	declare bindingCode?: Expression

	@jsonArrayMember(FormValidationRule)
	validationRules: FormValidationRule[] = []

	canHaveAppearance(): boolean {
		return true
	}

	isRequired() {
		return !!this.validationRules.find(vr => vr.validationType == 'required')
	}
}