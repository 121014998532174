import { Block } from '@shared/blocks/block';
import { ComponentType } from '@angular/cdk/portal';
import { BranchNameType, BusinessObjectTypesOrAll, BusinessObjectTypeType, Class, CodeEditorSize } from '@shared/types'
import { Screen } from '@shared/bos'
import { BusinessObject } from '@shared/bos/business-object'
import { Process } from '@shared/bos/process'
import { BoReference } from '@shared/bos/bo-reference'

interface EditorGenericProperty<BO extends BusinessObject, Item> {
	order: number
	label: string | ((items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => string)
	tab?: string
	fieldset?: ((items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => string)
	isBinding?: boolean
	obsolete?: boolean
	inputType?: string
	isMandatory?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => boolean | '(*)'
	isReadonly?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => boolean
	isVisible?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => boolean
	leaveGapIfHidden?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => boolean
	spanColumns?: `span ${number}` | `${number} / ${number}` | `${number} / span ${number}`
	getter?(target: any, key: string): any
	setter?(target: any, key: string, value: any): void
	additionalVariables?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => Record<string, string> // { [name]: tsType }
	additionalLines?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => string[]
	tooltip?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => {
		text: string,
		acceptHtml?: boolean,
	}
	onChanged?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => void,
	onInitializedOrChanged?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => void,
}

export interface EditorInputProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
	inputType: 'input'
	contentType: 'text' | 'email' | 'number'
	elementType: 'input' | 'textarea'
	regex?: RegExp
}

// interface EditorTsProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
// 	inputType: 'ts'
// }

export interface EditorDropdownProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
	inputType: 'dropdown'
	options: [string, string][]
	noneOptionName?: string
	noneOptionValue?: any
}

export interface EditorCheckboxProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
	inputType: 'checkbox'
}

export interface EditorCodeProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
	inputType: 'code'
	codeLanguage: CodeLanguageType
	editorSize: CodeEditorSize
	isBinding: boolean
	alternativeContentAreaName?: string
}

export interface EditorTsCodeProperty<BO extends BusinessObject, Item> extends EditorCodeProperty<BO, Item> {
	codeLanguage: 'ts'
	tsReturnType: (items: Item[], bo: BO) => string
}

export interface EditorNonTsCodeProperty<BO extends BusinessObject, Item> extends EditorCodeProperty<BO, Item> {
	codeLanguage: 'html' | 'scss'
}

// interface EditorBindingProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
// 	inputType: 'binding'
// }

export interface EditorTypeDropdownProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
	inputType: 'typeDropdown'
	// allowedTsType: string
}

export interface EditorBoRefProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
	inputType: 'boRef'
	allowedBoTypes: BusinessObjectTypesOrAll
	includeImportedModules: boolean
	noneOptionName?: string
}

export interface EditorBoRefSubtypeProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
	inputType: 'boRefSubtype'
	boRef: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => BoReference
	noneOptionName?: string
}

export interface EditorCompositionProperty<BO extends BusinessObject, Item> extends EditorGenericProperty<BO, Item> {
	inputType?: ''
	compositionObj?: (items: Item[], properties: EditorPropertyWithKey<BO, Item>[], bo: BO) => object
}

export type EditorGeneralProperty<BO extends BusinessObject, Item> =
	EditorInputProperty<BO, Item>
	// | EditorTsProperty<BO, Item>
	| EditorDropdownProperty<BO, Item>
	| EditorCheckboxProperty<BO, Item>
	| EditorTsCodeProperty<BO, Item>
	| EditorNonTsCodeProperty<BO, Item>
	// | EditorBindingProperty<BO, Item>
	| EditorTypeDropdownProperty<BO, Item>
	| EditorBoRefProperty<BO, Item>
	| EditorBoRefSubtypeProperty<BO, Item>
	| EditorCompositionProperty<BO, Item>

export type EditorPropertyWithKey<BO extends BusinessObject, Item> = EditorGeneralProperty<BO, Item> & {
	key?: string
}

export type ScreenEditorProperty<Item extends Block = Block> = EditorPropertyWithKey<Screen, Item>
export type ProcessEditorProperty = EditorPropertyWithKey<Process, any>
	
export interface EditorWizardDialog {
	label: string
	getDialogClass: () => ComponentType<any>
}

export interface EditorWizardData<BO extends BusinessObject, Item> {
	branchName: BranchNameType
	bo: BO
	item: Item
  }

export type CodeLanguageType = 'ts' | 'html' | 'scss' | 'sql'
