import { DataUtil } from '@shared/util/data-util'
import { capitalizeFirstLetter } from '@shared/util/string-util'
import { jsonObject, jsonMember, jsonArrayMember, jsonMapMember } from 'typedjson'


@jsonObject
export class StaticEntityEntry {
	@jsonMember(String) identifier!: string
	@jsonMapMember(String, String) values: Map<string, string> = new Map()

	get id() { return this.values.get('id') }

	constructor(init?: Partial<StaticEntityEntry>) {
		DataUtil.assignCommonProperties(this, init)
	}

	getCapitalizedIdentifier() {
		return capitalizeFirstLetter(this.identifier)
	}

	isOtherEntry() {
		return this.values.get('$isOther') == 'true'
	}
}