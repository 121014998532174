// import { GlobalStateInterface, globalInitialState } from './global-state-interface';
import { Draft, Patch } from 'immer';
// import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ChangeDetectionService } from '@ng-shared/lib/services/change-detection.service'
import { enablePatches, setAutoFreeze } from 'immer'
import { ErrorService } from '@ng-shared/lib/services/error.service'
import { Store } from './store'
import * as R from 'ramda'
import { PathOccurenceMap } from '@shared/data/path-occurence-map';
import { DataUtil } from '@shared/util/data-util';
import { AppContext } from '../app-context'
import { LowgilePathSymbol } from './store-proxy-handler'
import { FormControlCache } from './from-control-cache'

const TempSessionStorageName = 'lowgile_SessionStore'

type State<GlobalStateInterface, ST> = {
  app: GlobalStateInterface
  screen: ST
}

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  get appStore() { return this.componentStores.app as Store<any> }
  private componentStores: Record<string, Store<object>> = {
    // app: new Store('app', globalInitialState),
  }
  private maxComponentId = 0

  constructor(
    // private store: Store<GlobalStateInterface>,
    private errorService: ErrorService,
    // private cdService: ChangeDetectionService,
  ) {
    setAutoFreeze(false)
    enablePatches();
    // this.setState(globalInitialState)
    Object.defineProperty(globalThis, 'stores', { get: () => this.componentStores })
  }

  initialize(globalInitialState: any) {
    this.loadFromSessionStorage()
    if(!this.componentStores.app) {
      this.componentStores.app = new Store('app', globalInitialState)
    }
  }

  createComponentStore<T extends object>(state: T) {
    const name = `component_${++this.maxComponentId}`
    const store = new Store(name, state)
    this.componentStores[name] = store as unknown as Store<object>

    return store
  }

  dropComponentStore(storeName: string) {
    delete this.componentStores[storeName]
  }

  private async updateInternal<GlobalStateInterface extends object, ST extends object>(modifier: (draft: Draft<State<GlobalStateInterface, ST>>) => Promise<void>, screenStore?: Store<ST>) {
    const oldAppState = this.appStore.stateInternal
    const oldScreenState = screenStore?.stateInternal
    const mixedState = { app: oldAppState, screen: oldScreenState }
    const mixedStore = new Store('', mixedState)

    const patches = await mixedStore.update(modifier as any)
    const updatedState = mixedStore.stateInternal

    const newScreenState = updatedState.screen
    const newAppState = updatedState.app

    for(const patch of patches) {
      const isForAppVariable = patch.path[0] == 'app'
      const storeName = (isForAppVariable ? this.appStore : screenStore)?.name ?? ''
      patch.path[0] = storeName
    }

    // FormControlCache.migrateFormControls(oldAppState, newAppState)
    // if(oldScreenState && newScreenState) {
    //   FormControlCache.migrateFormControls(oldScreenState, newScreenState)
    // }

    await this.appStore.updateState(newAppState)
    await screenStore?.updateState(newScreenState as any)

    return patches
  }
  
  async update<GlobalStateInterface extends object, ST extends object>(modifier: (draft: Draft<State<GlobalStateInterface, ST>>) => Promise<void>, screenStore?: Store<ST>) {
    const oldPathMaps = R.mapObjIndexed(store => store.pathOccurenceMap, this.componentStores)

    const patches = await this.updateInternal(modifier, screenStore)

    this.applyPatchesToOtherStores(patches, oldPathMaps)
  }

  setProperty(parentPath: string[], property: string, value: any) {
    const store = this.componentStores[parentPath?.[0]]
    if(!store) {
      this.errorService.error(`Failed to update; could not find store for path ${parentPath}`)
      return
    }

    const oldPathMaps = R.mapObjIndexed(store => store.pathOccurenceMap,this.componentStores)
    
    // const oldValue = R.path([...parentPath.slice(1), property], store.stateInternal)
    // console.log('old value', property, oldValue, value)
    const patches = store.setProperty(parentPath, property, value)
    patches.forEach(p => p.path.splice(0, 0, parentPath[0]))

    // await this.applyPatchesToOtherStores(patches, oldPathMaps)

    // this.applicationRef.tick()
  }

  setPropertyAndPropagateToOtherStores(parentPath: string[], property: string, value: any) {
    const store = this.componentStores[parentPath?.[0]]
    if(!store) {
      this.errorService.error(`Failed to update; could not find store for path ${parentPath}`)
      return
    }

    const oldPathMaps = R.mapObjIndexed(store => store.pathOccurenceMap,this.componentStores)
    
    // const oldValue = R.path([...parentPath.slice(1), property], store.stateInternal)
    // console.log('old value', property, oldValue, value)
    const patches = store.setProperty(parentPath, property, value)
    patches.forEach(p => p.path.splice(0, 0, parentPath[0]))

    this.applyPatchesToOtherStores(patches, oldPathMaps)

    // this.applicationRef.tick()
  }

  applyPatchesToOtherStores(patches: Patch[], oldPathMaps: Record<string, PathOccurenceMap>) {
    for(const [storeKey, store] of Object.entries(this.componentStores)) {
      const assignments: [path: string[], value: any][] = []

      for(const patch of patches) {
        const origStoreKey = patch.path[0]
        const oldPathMap = oldPathMaps[origStoreKey]
        const origStore = this.componentStores[origStoreKey]
        if(!origStore) {
          console.error(`Store ${origStoreKey} not found while trying to apply patches to other stores`)
          continue
        }

        const parentPath = patch.path.slice(0, patch.path.length - 1)
        const oldValue = oldPathMap.getValueAtPath(parentPath)!
        const newValue = origStore.pathOccurenceMap.getValueAtPath(parentPath)

        const paths = store.pathOccurenceMap.getPathsForValue(oldValue, storeKey == origStoreKey ? parentPath : undefined)
        if(!paths.length) continue

        for(const path of paths) {
          const storePath = path.slice(1).map(x => x.toString())
          assignments.push([storePath, newValue])
        }
      }

      store.updateSync(draft => {
        for(const assignment of assignments) {
          DataUtil.setFromPath(draft, assignment[0], assignment[1])
        }
      })
    }
  }

  getFromPath(path: string[], state?: object) {
    if(state) {
      const value = R.path(path, state)
      return value as any
    } else {
      const [storeName, ...storePath] = path
      const store = this.componentStores[storeName]
      const value = R.path(storePath, store.getState())
      return value as any
    }
  }

  replaceWithStateVersion<T>(value: T, state: object): T {
    const path = (value as any)?.[LowgilePathSymbol]
    if(path) {
      const newValue = this.getFromPath(path, state)
      // TODO!: investigate why this is not always working (seems that state is { app, screen }, not { component_1, ... })
      if(newValue !== undefined) return newValue
    }
    return value
  }

  saveToSessionStorage() {
    // const entries = Object.entries(this.componentStores).map(([key, store]) => [key, store.stateInternal])
    // const stateObj = Object.fromEntries(entries)
    const stateObj = { app: this.componentStores.app.stateInternal }
    console.log('storing state', stateObj)
    const stateAndDuplicatesJson = AppContext.jsonMapper.writeToJsonRemovingDuplicates(stateObj)
    sessionStorage.setItem(TempSessionStorageName, JSON.stringify(stateAndDuplicatesJson))
  }

  loadFromSessionStorage() {
    const stateAndDuplicatesJson = sessionStorage.getItem(TempSessionStorageName)
    if(!stateAndDuplicatesJson) return
    const stateAndDuplicates = JSON.parse(stateAndDuplicatesJson)

    let stateObj = AppContext.jsonMapper.readFromValueAndDuplicatePaths(stateAndDuplicates)
    const entries = Object.entries(stateObj).map(([key, state]) => [key, new Store(key, state as object)])
    this.componentStores = Object.fromEntries(entries)
    this.clearSessionStorage()
  }

  clearSessionStorage() {
    sessionStorage.removeItem(TempSessionStorageName)
  }

  // select<T>(selector: (state: GlobalStateInterface)=>T) {
  //   return this.store.select(selector)
  // }

  // selectAll() {
  //   return this.select(state => state)
  // }

  // subscribe<T>(selector: (state: GlobalStateInterface)=>T, assignment: (selection:T)=>void) {
  //   return this.select(selector).subscribe(assignment)
  // }

  // reload(...storeProperties: (keyof GlobalStateInterface)[]) {
  //   this.store.dispatch(reload({ properties: storeProperties }))
  // }

  // reloadMany() {
  //   const storeProperties = Object.keys(this.stateInternal) as (keyof GlobalStateInterface)[]
  //   this.reload(...storeProperties)
  // }
}
