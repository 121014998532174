import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject, toJson } from 'typedjson'

@toJson
@jsonObject
export class TriggerConfiguration {
	@jsonMember(String)
	get __type(): string { return 'TriggerConfiguration' }
	set __type(_) { /*ignore*/ }

	@jsonMember(String)
	moduleId!: string
	@jsonMember(String)
	boId!: string
	@jsonMember(String)
	branchNameToUse!: string
	@jsonMember(Boolean)
	isAutostart: boolean = false
	@jsonMember(Boolean)
	isActive: boolean = false

	constructor(init?: Partial<TriggerConfiguration>) {
		DataUtil.assignCommonProperties(this, init)
	}

	updateConfig(from: TriggerConfiguration) {
		const hasDbRelevantChanges = this.isAutostart != from.isAutostart || this.branchNameToUse != from.branchNameToUse // ignore isActive
		const hasRestartRelevantChanges = this.branchNameToUse != from.branchNameToUse || this.isActive != from.isActive // ignore isAutostart
		this.isActive = from.isActive
		this.isAutostart = from.isAutostart
		this.branchNameToUse = from.branchNameToUse

		return { hasDbRelevantChanges, hasRestartRelevantChanges }
	}

	getQualifiedName() {
		return `${this.moduleId}.${this.boId}`
	}
}