<div class="top">
	<div class="message" matSnackBarLabel>
		<mat-icon *ngIf="typeIcon" class="type-icon">{{ typeIcon }}</mat-icon>
		<span>{{ data.message }}</span>
	</div>
	<div matSnackBarAction>
		<button *ngIf="!data.duration"
			matSnackBarAction
			mat-icon-button
			mat-large-icon-button
			(click)="data.dismiss()"
		>
			<mat-icon>close</mat-icon>
		</button>
	</div>
</div>