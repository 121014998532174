import type { Locale } from 'date-fns'
import { BehaviorSubject, Subject } from 'rxjs'

export class CommonLanguageBridge {
	protected static readonly languageChangedSubject = new Subject<void>()
	protected static readonly editModeSubject = new BehaviorSubject<boolean>(false)
	protected static readonly defaultLanguageSubject = new BehaviorSubject<string>('en-US')
	static readonly languageChanged$ = this.languageChangedSubject.asObservable()
	protected static dateLocales: Record<string, Locale> = {}

	
	static get defaultLanguage() { return this.defaultLanguageSubject.value }
	static set defaultLanguage(defaultLanguage: string) {
		if(defaultLanguage == this.defaultLanguageSubject.value) return
		this.defaultLanguageSubject.next(defaultLanguage)
		this.languageChangedSubject.next()
	}
	static get languageIsoCode() { return '' } // overridden

	static θsetDateLocales(dateLocales: Record<string, Locale>) {
		Object.assign(this.dateLocales, dateLocales)
	}

	static get dateLocale() {
		return new Proxy<Locale>({} as any, {
			get: (target, p, receiver) => {
				const lang = this.languageIsoCode.replaceAll('-', '')
				const defaultLang = this.defaultLanguage.replaceAll('-', '')
				const locale = this.dateLocales[lang] ?? this.dateLocales[defaultLang] ?? {}
				return Reflect.get(locale, p, receiver)
			},
		})
	}

	static get language() { return '' } // overridden
	static set language(language: string) {} // overridden
}