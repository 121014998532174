<div #top>
	<h1 mat-dialog-title>{{ data.title }}</h1>
	<div mat-dialog-content>
		{{ data.body }}
	</div>
	
	<mat-dialog-actions align="end">
		<button mat-button mat-dialog-close>{{ data.buttonCancelText }}</button>
		<button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{ data.buttonConfirmText }}</button>
	</mat-dialog-actions>
</div>