import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember, toJson, jsonMapMember } from 'typedjson'
import { DataUtil } from '@shared/util/data-util'
import { Parameter } from '@shared/script/parameter'

@jsonObject({ name: 'Label' })
export class Label extends BusinessObject {
	@jsonMapMember(String, String) translations: Map<string, string> = new Map()
	@jsonMember(Boolean) canHaveInputs: boolean = false
	@jsonMember(Boolean) canContainHtml: boolean = false
	@jsonArrayMember(Parameter) inputs: Parameter[] = []



	constructor(init?: Partial<Label>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getClassNames(): string[] {
		return ['$T']
	}
}
