export function createSetNotifyProxy<T extends object>(target: T, setListeners: Partial<Record<keyof T, (value: any, p: keyof T, target: T) => void>>) {
	return new Proxy(target, {
		set(target, p, value) {
			const retVal = Reflect.set(target, p, value)

			const listener = setListeners[p as keyof T]
			listener?.(value, p as keyof T, target)

			return retVal
		}
	})
}