import { TypeReference } from './../data/type-reference';
import { BusinessObject } from './business-object'
import { StaticEntityProperty } from './static-entity-property'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { StaticEntityEntry } from './static-entity-entry'
import { DataUtil } from '@shared/util/data-util'
import { Parameter } from '@shared/script/parameter'

export const StaticEntitySources = [
	['static', 'Static', null],
	['dynamicServerAndClient', 'Dynamic (running on server and client)', 'serverAndClient'],
	['dynamicServer', 'Dynamic (needs to run on server)', 'server'],
] as const
export type StaticEntitySource = typeof StaticEntitySources[number][0]

@jsonObject({ name: 'StaticEntity' })
export class StaticEntity extends BusinessObject {
	@jsonMember(String) source: StaticEntitySource = 'static'
	
	// @jsonMember(String) idType: 'string' | 'number' = 'string'

	@jsonArrayMember(StaticEntityProperty) properties: StaticEntityProperty[] = []
	@jsonArrayMember(StaticEntityEntry) entries: StaticEntityEntry[] = []
	@jsonMember(Boolean) hasOtherEntries: boolean = false
	
	@jsonArrayMember(Parameter) inputs: Parameter[] = []
	@jsonMember(String) dynamicCode: string = ''
	/** 0=cache forever, -1=never cache */
	@jsonMember(Number) dynamicClientCachingSeconds: number = 0
	@jsonMember(Number) dynamicServerCachingSeconds: number = 0

	get idType() { return this.properties.find(p => p.name == 'id')?.type as 'string' | 'number' }
	readonly affectsDb: boolean = true
	
	constructor(init?: Partial<StaticEntity>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getTypeReferences() {
		return [
			new TypeReference({
				kind: 'bo',
				boType: 'StaticEntity',
				boModuleId: this.moduleId,
				boId: this.boId
			}),
			new TypeReference({
				kind: 'type',
				boModuleId: this.moduleId,
				typeName: `${this.boId}Id`
			})
		]
	}

	getClassNames(): string[] {
		const qualifiedName = this.getQualifiedName()
		return [
			qualifiedName,
			`${qualifiedName}Option`,
			`${qualifiedName}Entry`,
		]
	}

	getIdTypeConstructor() {
		return this.idType == 'string' ? 'String' : 'Number'
	}

	getIdTypeInitializer() {
		return this.idType == 'string' ? `''` : '0'
	}

	getOtherEntries() {
		return this.entries.filter(e => e.values.get('$isOther') == 'true')
	}

	ensureDefaultProperties() {
		if(!this.properties.find(p => p.name == 'id')) {
			this.properties.splice(0, 0, new StaticEntityProperty({
				name: 'id',
				type: 'string',
			}))
		}
		if(!this.properties.find(p => p.name == '$isOther') && this.hasOtherEntries) {
			this.properties.splice(1, 0, new StaticEntityProperty({
				name: '$isOther',
				type: 'boolean',
			}))
		}
	}

	isPropertyDeletable(property: StaticEntityProperty) {
		if(property?.name == 'id') return false
		if(property?.name == '$isOther' && this.hasOtherEntries) return false
		return true
	}

	isTypeAllowedForProperty(property: StaticEntityProperty, type: string) {
		if(property?.name == 'id') return ['string', 'number'].includes(type)
		if(property?.name == '$isOther' && this.hasOtherEntries) return ['boolean'].includes(type)
		return true
	}

	getPropertyDefaultValue(propertyName: string) {
		if(propertyName == 'id') return this.getIdTypeInitializer()
		if(propertyName == '$isOther') return 'false'
		
		const property = this.properties.find(p => p.name == propertyName)
		return property?.getDefaultValue()
	}
}
