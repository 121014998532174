// import moment from 'moment'

import CodeBlockWriter from 'code-block-writer'
import type { BranchNameType, Class, EntityClass, EntityInstance, TsTypeType, UnwrapReadonly } from '../types'
import { MonoTypeOperatorFunction, Observable, OperatorFunction, TruthyTypesOf, delay, map, of, share, shareReplay, switchMap, tap } from 'rxjs'
import { BaseJsonMapper } from '../data/base-json-mapper'
import { BoReference } from '@shared/bos/bo-reference'
import * as R from 'ramda'

export type ObjectVisitorType = (value: any, keyPath: string[], parent: object | null) => boolean

export class DataUtil {
	static deepAssign(targetObject: any, sourceObject: any) {
		for (let key in sourceObject) {
			if (typeof sourceObject[key] != 'object') {
				targetObject[key] = sourceObject[key]
			} else {
				targetObject[key] = {}
				this.deepAssign(targetObject[key], sourceObject[key])
			}
		}
	}

	static pickObjectProperties<T extends Record<string, any>, P extends keyof T>(
		obj: T,
		properties: P[],
		defaultIfUndefined: any = undefined
	): Pick<T, P> {
		const entries = Object.entries(obj) as [P, any][]
		let pickedEntries = entries.filter(e => properties.includes(e[0]))
		if (defaultIfUndefined !== undefined) {
			pickedEntries = pickedEntries.map(e => [e[0], e[1] !== undefined ? e[1] : defaultIfUndefined])
		}
		const pickedObj: Pick<T, P> = <any>Object.fromEntries(pickedEntries)

		return pickedObj
	}

	static hasPropertyChanged<T extends Record<string, any>>(obj1: T, obj2: T, properties: (keyof T)[]) {
		for (let property of properties) {
			if (obj1[property] != obj2[property]) return true
		}
		return false
	}

	static unique<T>(array: T[]): T[] {
		return array.filter((item, idx) => array.indexOf(item) == idx)
	}

	static convertFromString(value: string, targetType: TsTypeType) {
		switch (targetType) {
			case 'string':
				return value
				break
			case 'number':
				return parseFloat(value)
				break
			case 'boolean':
				const lcValue = value.toLowerCase()
				if (lcValue == 'true' || lcValue == '1') return true
				if (lcValue == 'false' || lcValue == '0') return false
				break
			case 'Date':
				return Date.parse(value)
		}
		console.warn(`Failed to parse "${value}" to ${targetType}`)
		return undefined
	}

	static pickOwnProperties<T extends object>(obj: object, targetClass: new() => T) {
		// obj = {...obj}
		const objKeys = Object.getOwnPropertyNames(obj)
		const classKeys = Object.getOwnPropertyNames(new targetClass())
		const classMetadataKeys = Reflect.getOwnMetadataKeys(targetClass)

		const filteredObj = <T>{}
		for(let key in obj) {
			if(key in classKeys) {
				Reflect.set(filteredObj, key, Reflect.get(obj, key))
			}
		}
		return filteredObj
		// const ownEntries = entries.filter(e => e[0] in classKeys && !(e[0] in classMetadataKeys))

		// return Object.fromEntries(ownEntries)
	}

	static visitObjectRecursively(rootObj: Record<string, any>, visitor: ObjectVisitorType, includeRootObject: boolean) {
		if(!rootObj) return

		function visitPropertiesRecursively(obj: Record<string, any>, keyPath: string[]) {
			for(const childKey of Object.keys(obj)) {
				const childValue = obj[childKey]
				const childKeyPath = [...keyPath, childKey]

				const visitProperties = visitor(childValue, childKeyPath, obj)
				if(visitProperties && childValue instanceof Object) {
					visitPropertiesRecursively(childValue, childKeyPath)
				}
			}
		}

		let visitProperties = true
		if(includeRootObject) {
			visitProperties = visitor(rootObj, [], null)
		}
		if(visitProperties) visitPropertiesRecursively(rootObj, [])
	}

	static ensureObjectTree<T>(rootObject: any, keys: string | string[], newLeafNode: T): T {
		if(typeof keys == 'string') keys = keys.split('.')
		let obj = rootObject

		for(let [i, key] of keys.entries()) {
			if(! obj[key]) {
				obj[key] = (i == keys.length - 1) ? newLeafNode : {}
			}
			obj = obj[key]
		}

		return obj // the new leaf node or existing leaf node
	}

	static setInObjectTree<T>(rootObject: any, keys: string | string[], value: T): void {
		if(typeof keys == 'string') keys = keys.split('.')
		let obj = rootObject

		const entries = [...keys.entries()]
		for(let [i, key] of entries.slice(0, entries.length-1)) {
			if(! obj[key]) {
				obj[key] = {}
			}
			obj = obj[key]
		}

		obj[keys[keys.length-1]] = value
	}

	static retrieveInObjectTree<T>(rootObject: any, keys: string | string[], valueIfNotFound: T, insertIfNotFound?: boolean): T
	static retrieveInObjectTree<T>(rootObject: any, keys: string | string[], valueIfNotFound?: T, insertIfNotFound?: boolean): T | undefined
	static retrieveInObjectTree<T>(rootObject: any, keys: string | string[], valueIfNotFound: T | undefined = undefined, insertIfNotFound = false): T | undefined {
		if(typeof keys == 'string') keys = keys.split('.')
		let obj = rootObject

		for(let key of keys) {
			obj = obj[key]
			if(! obj) {
				if(insertIfNotFound) {
					this.ensureObjectTree(rootObject, keys, valueIfNotFound)
				}
				return valueIfNotFound
			}
		}

		return obj
	}

	static groupArrayIntoRecordByItemProperty<T, K extends string>(list: T[], propertyFunction: (item: T) => K): Record<K, T[]> {
		const obj: Record<K, T[]> = {} as any
		for(const item of list) {
			const propertyValue = propertyFunction(item)
			const itemList = obj[propertyValue] ?? []
			obj[propertyValue] = itemList

			itemList.push(item)
		}

		return obj
	}

	static groupArrayIntoMapByItemProperty<T, K extends string>(list: T[], propertyFunction: (item: T) => K): Map<K, T[]> {
		const obj = this.groupArrayIntoRecordByItemProperty(list, propertyFunction)
		const map = this.convertObjectToMap(obj)

		return map
	}

	static mapObject<T, K extends string | number, R>(obj: Record<K, T>, mapping: (item: T) => R): Record<K, R> {
		const mappedObj: Record<K, R> = {} as any

		for(const key of Object.keys(obj)) {
			mappedObj[key as K] = mapping(obj[key as K])
		}

		return mappedObj
	}

	static convertObjectToMap<K extends string, V>(obj: Record<K, V>): Map<K, V> {
		const entries = Object.entries(obj) as any as [K, V][]
		const map = new Map<K, V>(entries)

		return map
	}

	static convertMapToObject<K extends string, V>(map: Map<K, V>): Record<K, V> {
		const obj = Object.fromEntries(map.entries()) as Record<K, V>
		return obj
	}

	static assignCommonProperties<T extends object>(target: T, source: Partial<T> | object | undefined, options?: {
		onlyProperties?: (keyof T)[],
		omitProperties?: (keyof T)[],
		restrictToClass?: Class<Partial<T>, [Partial<T>]>,
	}) {
		if(!source || !target) return

		if(options?.restrictToClass) source = new options.restrictToClass(source)

		for(let key in source) {
			if(options?.onlyProperties && !options.onlyProperties.includes(key as any as keyof T)) continue
			if(options?.omitProperties && options.omitProperties.includes(key as any as keyof T)) continue

			if(key in target) {
				const value = Reflect.get(source, key)
				Reflect.set(target, key, value)
			}
		}
	}

	static getJsonTypeName(obj: object, fallback?: string): string {
		const metaData = obj.constructor.prototype['__typedJsonJsonObjectMetadataInformation__']
		const className = metaData?.name
		return className || fallback
	}

	static mergeObjectsRecursively(objs: object[], levels: number) {
		let mergedObj = {}

		for(const obj of objs) {
			const flattenedObj = this.flattenOneObjectLevel(obj)
			mergedObj = {
				...mergedObj,
				...flattenedObj
			}
		}
	}

	static flattenOneObjectLevel<K extends string, NK extends string, V extends any>(obj: Record<K, V>, newKeyFun: (parentKey: K, childKey: K) => NK = (parentKey, childKey) => `${parentKey}.${childKey}` as NK) {
		const flattenedObj = {} as Record<NK, V>
		for(const key of Object.keys(obj)) {
			const value = obj[key as K]

			if(typeof value == 'object') {
				for(const childKey of Object.keys(value as object)) {
					const newKey = newKeyFun(key as K, childKey as K)
					flattenedObj[newKey] = (value as Record<K, V>)[childKey as K]
				}
			} else {
				flattenedObj[key as NK] = value
			}
		}

		return flattenedObj
	}

	static getFromPath(root: object, path: string[]) {
		const pathParts = path ?? []
		
		let obj: any = root
		for(const part of pathParts) {
		  if(obj && part) {
			obj = obj[part]
		  }
		}
	
		return obj
	}
	
	static setFromPath(root: object, path: string[], value: any) {
		const pathParts = path ?? []
		
		let obj: any = root
		for(const part of pathParts.slice(0, pathParts.length - 1)) {
			if(obj && obj[part] === undefined) {
				obj[part] = {}
			}
		  	if(part) {
				obj = obj[part]
		  	}
		}
	
		const paramName = pathParts[pathParts.length - 1]
		const oldValue = obj[paramName]
		obj[paramName] = value

		return oldValue
	}

	static pushToArrayByPath(root: object, pathToArray: string[], value: any, ensureUniqueness = false) {
		let array: any[] = this.getFromPath(root, pathToArray)
		if(!array) {
			array = []
			this.setFromPath(root, pathToArray, array)
		}
		if(!ensureUniqueness || !array.includes(value)) {
			array.push(value)
		}
	}

	//
	// static convertDateToString(date: Date = new Date()) {
	// 	let m = moment(date)
	// 	return m.format()
	// }

	// static pickOwnProperties<T extends object>(obj: object, targetClass: new() => T) {
	// 	// obj = {...obj}
	// 	const objKeys = Object.getOwnPropertyNames(obj)
	// 	const classKeys = Object.getOwnPropertyNames(new targetClass())
	// 	const classMetadataKeys = Reflect.getOwnMetadataKeys(targetClass)

	// 	const filteredObj = <T>{}
	// 	for(let key in obj) {
	// 		if(key in classKeys) {
	// 			Reflect.set(filteredObj, key, Reflect.get(obj, key))
	// 		}
	// 	}
	// 	return filteredObj
	// 	// const ownEntries = entries.filter(e => e[0] in classKeys && !(e[0] in classMetadataKeys))

	// 	// return Object.fromEntries(ownEntries)
	// }

	// static ensureObjectTree<T>(rootObject: any, keys: string[], newLeafNode: T): T {
	// 	let obj = rootObject

	// 	for(let [i, key] of keys.entries()) {
	// 		if(! obj[key]) {
	// 			obj[key] = (i == keys.length - 1) ? newLeafNode : {}
	// 		}
	// 		obj = obj[key]
	// 	}

	// 	return obj // the new leaf node or existing leaf node
	// }

	// static retrieveInObjectTree<T>(rootObject: any, keys: string[], valueIfNotFound: T, insertIfNotFound: boolean): T {
	// 	let found = false
	// 	let obj = rootObject

	// 	for(let key of keys) {
	// 		obj = obj[key]
	// 		if(! obj) {
	// 			if(insertIfNotFound) {
	// 				this.ensureObjectTree(rootObject, keys, valueIfNotFound)
	// 			}
	// 			return valueIfNotFound
	// 		}
	// 	}

	// 	return obj
	// }
	//
	// static convertDateToString(date: Date = new Date()) {
	// 	let m = moment(date)
	// 	return m.format()
	// }

	static createCodeBlockWriter() {
		return new CodeBlockWriter({
			useTabs: true,
			useSingleQuote: true,
		})
	}

	static unwrapReadonly<T>(obj: T) {
		return obj as UnwrapReadonly<T>
	}

	static coerceDataType(value: any, newType: string | EntityClass, isArray: boolean, allowNull: boolean): any {
		// if(value?.__type) {
		// 	console.log('Coercing ', value.__type, 'with ID', value.id, 'to', typeof newType == 'string' ? newType : newType.__type)
		// }
		if(value == null) return value
		if(isArray) {
			if(!(value instanceof Array) && typeof value == 'object') value = Object.assign([], value) // convert from {0:'a',1:'b'} to ['a','b']
			if(!(value instanceof Array)) return []
			return value.map(v => this.coerceDataType(v, newType, false, allowNull))
		}

		let oldType = typeof value as string
		if(value instanceof Date) oldType = 'Date'
		if(newType instanceof Function) oldType = (value as EntityInstance)?.__type || oldType
		const newTypeName = (newType instanceof Function) ? newType.__type : newType

		if(oldType == newTypeName) return value
		if(value === null && allowNull) return value

		if(newType instanceof Function) {
			return new newType(value)
		}

		switch(newType) {
			case 'Date':
				return new Date(value)
			case 'string':
				return String(value)
			case 'number':
				return Number(value)
			case 'boolean':
				return Boolean(value)
			case 'bigint':
				return BigInt(value)
		}

		return value
	}

	static deepFreeze(obj: object) {
		this.visitObjectRecursively(obj, value => {
			if(value && typeof value == 'object') {
				Object.freeze(value)
				return true
			}
			return false
		}, true)
	}

	static createReadonlyProxy<T extends object>(deep: boolean, obj: T): T {
		const propertyProxies = new Map<string | symbol, any>()

		return new Proxy(obj, {
			get(target, p, receiver) {
				if(propertyProxies.has(p)) return propertyProxies.get(p)

				const value = Reflect.get(target, p, receiver)
				if(!deep) return value

				if(typeof value == 'function') return value.bind(target)
				if(value && typeof value == 'object') {
					const propertyProxy = DataUtil.createReadonlyProxy(deep, value)
					propertyProxies.set(p, propertyProxy)
					return propertyProxy
				}
				return value
			},
			defineProperty() { return false },
			set() { return false },
			setPrototypeOf() { return false },
		})
	}

	static createDeferredReadonlyProxy<T extends object>(deep: boolean, objFunc: () => T) {
		let target: T

		return new Proxy({}, {
			get(_, p, receiver) {
				if(target === undefined) target = objFunc()
				const value = Reflect.get(target, p, receiver)
				if(!deep) return value

				if(typeof value == 'function') return value.bind(target)
				if(value && typeof value == 'object') return DataUtil.createReadonlyProxy(deep, value)
				return value
			},
			set() { return false },
			setPrototypeOf() { return false },
		})
	}

	static mergeObjectsWhereNotEmpty<K1 extends string | number, K2 extends string | number, V>(obj1: Record<K1, V>, obj2: Record<K2, V>): Record<K1 | K2, V> {
		const obj = { ...obj1 } as Record<K1 | K2, V>
		for(const [key, value] of Object.entries(obj2)) {
			if(value != undefined || !Object.hasOwn(obj, key)) obj[key as K2] = value as V
		}

		return obj
	}
}

export function on$<T>(trigger: Observable<any>, observableGenerator: () => Observable<T>) {
	return trigger.pipe(
		switchMap(() => observableGenerator()),
		shareReplay(),
	)
}

export function delayed$<T>(observableFactory: () => Observable<T>, delayMs = 0) {
	return of(undefined).pipe(
		delay(delayMs),
		switchMap(() => observableFactory())
	)
}

type JsonMapping<T, M> = [(input: T) => M, (mapped: M, input: T) => T]

export function jsonMap<T, M extends object, M_or_Arr extends M | M[]>(ctor: Class<M>, mappings?: JsonMapping<T, M_or_Arr>[]): OperatorFunction<T, T> {
	if(!mappings) mappings = [[(x: T) => x, (x: M_or_Arr) => x]] as any
	return input$ => {
		return input$.pipe(
			map(input => {
				const jsonMapper = new BaseJsonMapper()

				let obj = input
				for(const mapping of mappings!) {
					const toBeMapped = mapping[0](obj)
					let mapped: typeof toBeMapped = jsonMapper.readFromObject(toBeMapped, ctor)

					obj = mapping[1](mapped as any, obj)
				}
				return obj
			}),
			share(),
		)
	}
}

export function nonNullable<T>(value: T): value is NonNullable<T> {
	return value != null
}

export function assertInstance<T>(obj: any, clazz: Class<T>): asserts obj is T {
	if(!(obj instanceof clazz)) throw new Error('assertInstance failed')
}

export function instanceFilter<T>(clazz: Class<T>): ((obj: any) => obj is T) {
	return (obj => obj instanceof clazz) as (obj: any) => obj is T
}

export function cast<T>(obj: any): asserts obj is T {}

export function memoize<T, U=T>(create: () => T, read?: (memoized: T) => U) {
	let result: T | undefined
	let resultReady = false

	return () => {
		if(!resultReady) {
			result = create()
			resultReady = true
		}
		if(read) return read(result!)
		return result as any as U
	}
}

export function pick<T extends object, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
	return Object.fromEntries(Object.entries(obj).filter(e => keys.includes(e[0] as K))) as Pick<T, K>
}

export function omit<T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
	return Object.fromEntries(Object.entries(obj).filter(e => !keys.includes(e[0] as K))) as Omit<T, K>
}

export async function awaitAndAssign<T extends object>(target: T, assignments: {
	[P in keyof InstanceType<Class<T>>]?: T[P] extends Function ? never : Promise<T[P]>
}) {
	await Promise.all(Object.values(assignments)) // ensure this function throws in case of rejections

	for(const key in assignments) {
		if(Object.hasOwn(target, key)) {
			Reflect.set(target, key, await assignments[key])
		}
	}
}

export function reloadIfInvalid<T>(isValid: (value: T) => boolean, ifInvalid: () => Observable<T>): OperatorFunction<T, T>
export function reloadIfInvalid<T, R>(isValid: (value: T) => boolean, ifInvalid: () => Observable<R>, ifValid: () => Observable<R>): OperatorFunction<T, R>
export function reloadIfInvalid<T, R>(isValid: (value: T) => boolean, ifInvalid: () => Observable<R>, ifValid?: () => Observable<R>): OperatorFunction<T, R> {
	let reloadObservable: Observable<R> | undefined

	return source => {
		return source.pipe(
			switchMap(value => {
				if(isValid(value)) {
					if(ifValid) {
						return ifValid()
					} else {
						return of(value) as unknown as Observable<R>
					}
				} else {
					if(!reloadObservable) {
						reloadObservable = ifInvalid()
					}
					return reloadObservable.pipe(
						tap(() => reloadObservable = undefined)
					)
				}
			})
		)
	}
}

export function getBoEditorPath(branchName: BranchNameType, boRef: BoReference, omitStudio = false) {
	return `${omitStudio ? '' : '/studio'}/edit/${boRef.boType}/${branchName}/${boRef.moduleId}/${boRef.boId}`
}

export class PropertyRestorer {
	private backups: { obj: object, property: string | number | symbol, value: any }[] = []

	backup<T extends object>(obj: T, property: keyof T) {
		this.backups.push({ obj, property, value: obj[property] })
	}

	backupMany<T extends object>(objs: T[], property: keyof T) {
		objs.forEach(obj => this.backup(obj, property))
	}

	restoreAll() {
		for(const backup of this.backups) {
			Reflect.set(backup.obj, backup.property, backup.value)
		}
	}
}

function isSpecialObjectType(value: object) {
	if(value === Array || value === Object) return value

	return value instanceof Boolean
	 || value instanceof Date
	 || value instanceof Number
	 || value instanceof RegExp
	 || value instanceof String
	 || value instanceof BigInt
}

export function replaceCircularDependencies<T extends object>(value: T): T {
	const objPaths = new Map()

	function dedup(value: any, fullPathToValue: (string | number)[]): any {
		if(!value || typeof value != 'object') return value
		if(objPaths.has(value)) {
			const $ref = objPaths.get(value)
			return { $ref }
		} else {
			objPaths.set(value, fullPathToValue)
			if(Array.isArray(value)) {
				return value.map((v, idx) => dedup(v, [...fullPathToValue, idx]))
			} else if(isSpecialObjectType(value)) {
				return value
			} else {
				const entries = Object.entries(value)
				const dedupEntries = entries.map(e => [e[0], dedup(e[1], [...fullPathToValue, e[0]])])
				const dedupObj = Object.fromEntries(dedupEntries)
				dedupObj.__proto__ = value.__proto__
				return dedupObj
			}
		}
	}

	return dedup(value, [])
}

export function reintroduceCircularDependencies(value: object) {
	const root = value as any
	const path: (string | number)[] = []

	function getFromRootByPath(path: (string | number)[]) {
		const x = R.path(path, root)
		const y = path.reduce((obj, property) => Reflect.get(obj, property), root)
		return x
	}

	function redup(parent: any, property: string | number): any {
		path.push(property)
		const value = Reflect.get(parent, property)
		try {
			if(!value || typeof value != 'object') return
			// if(debugPath.length % 1000 == 0) {
			// 	console.log('Redup path', debugPath.length)
			// 	console.log(debugPath.join('/'), '\n\n\n')
			// }
			if(Object.hasOwn(value, '$ref')) {
				Reflect.set(parent, property, getFromRootByPath(value.$ref))
				return
			}
	
			if(Array.isArray(value)) {
				value.forEach((v, idx) => redup(value, idx))
			}
			if(isSpecialObjectType(value)) return
	
			for(const key in value) {
				redup(value, key)
			}
		} finally {
			path.pop()
		}
	}

	for(const key in value) {
		redup(value, key)
	}
}

export function filterAsync<T>(predicate: (value: T, index: number) => Promise<boolean>): MonoTypeOperatorFunction<T> {
	return source => {
		let index = 0
		let pendingCount = 0
		let upstreamCompleted = false

		return new Observable(subscriber => {
			source.subscribe({
				next: async value => {
					try {
						pendingCount++
						const result = await predicate(value, index++)
						if(result) subscriber.next(value)
						pendingCount--
						if(!pendingCount && upstreamCompleted) subscriber.complete()
					} catch(err) {
						subscriber.error(err)
					}
				},
				error: err => subscriber.error(err),
				complete: () => {
					upstreamCompleted = true
					if(!pendingCount) subscriber.complete()
				},
			})
		})
	}
}