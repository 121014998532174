import { DataUtil } from '@shared/util/data-util';
import { jsonMember, jsonObject, toJson } from 'typedjson'

@jsonObject
@toJson
export class DeploymentSource {
	@jsonMember(String)
	get __type() { return 'DeploymentSource' }
	set __type(_) {}
	@jsonMember(Number)
	id?: number
	@jsonMember(String)
	name?: string
	@jsonMember(String)
	hostname?: string
	@jsonMember(Number)
	port?: number
	@jsonMember(String)
	publicKeyPem!: string
	@jsonMember(String)
	privateKeyPem!: string

	constructor(init?: Partial<DeploymentSource>) {
		DataUtil.assignCommonProperties(this, init)
	}
}