import type { HtmlType, EventType, TsCodeType } from './../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'SpinnerBlock' })
export class SpinnerBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Value',
		tooltip: () => ({ text: 'Use undefined to show an indeterminate spinner' }),
		codeLanguage: 'ts',
		tsReturnType: () => 'number | undefined',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	valueCode?: TsCodeType = 'undefined'

	constructor(init: Partial<SpinnerBlock> = {}) {
		super()
		this.init(init)
	}

	canHaveColor(): boolean {
		return true
	}

	producePreview(): HtmlType {
		if(this.getContentArea('content')) return ''
		return `<mat-spinner></mat-spinner>`
	}

	getEditorCategory(): string {
		return 'Others'
	}
}
