import { sentenceCase } from '@shared/util/string-util';
import { DropdownBlock } from '@shared/blocks/form/dropdown-block';
import { BoReference } from '@shared/bos/bo-reference';
import { CheckboxBlock } from '@shared/blocks/form/checkbox-block';
import { DatePickerBlock } from '@shared/blocks/form/datepicker-block';
import { Entity, Screen } from '@shared/bos';
import { Block } from '@shared/blocks/block';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormBlock } from '@shared/blocks/form/form-block'
import { FormControlElement, FormElement } from '@shared/blocks/form/form-elements'
import { InputBlock } from '@shared/blocks'
import { BranchNameType, TsTypeType } from '@shared/types'
import { EditorWizardData } from '@shared/blocks/decorators/editor-property-types'
import { FormInputBlock } from '@shared/blocks/form/form-input-block'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { ScreenEditorComponent } from '../screen-editor.component'
import { StudioTrpcService } from '@ng-shared/lib/services/studio-trpc.service'
import { EditCommonModule } from '../../edit-common/edit-common.module'
import { Expression } from '@shared/data/text-or-code'

interface AvailableElement {
  variable: string
  bindingPropertyName: string
  tsType: TsTypeType
  usageCount: number
}

@Component({
  selector: 'lowgile-form-editor',
  templateUrl: './form-editor.component.html',
  styleUrls: ['./form-editor.component.scss'],
  standalone: true,
  imports: [
    EditCommonModule,
  ]
})
export class FormEditorComponent implements OnInit {
  form: FormBlock
  entities = new Map<string, Entity>()
  elements: FormElement[] = []
  availableElements: AvailableElement[] = []
  @ViewChild('usedList') usedList: ElementRef<HTMLUListElement>
  @ViewChild('availableList') availableList: ElementRef<HTMLUListElement>
  branchName: BranchNameType = 'master'
  typeBoRefs: BoReference[] = []
  possibleVariables: Record<string, BoReference> = {}
  isVariableSelected: Record<string, boolean> = {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditorWizardData<Screen, Block>,
    private trpcService: StudioTrpcService
  ) {
    this.form = data.item as FormBlock
  }

  async ngOnInit() {
    // const promises: Promise<void>[] = []
    // for(const [variable, boRef] of this.form.variables.entries()) {
    //   // TODO: make branch smarter; there's a strange error when trying to inject ScreenEditorComponent in order to access params.branchName
    //   promises.push(this.boRepoService.getBoFromRef<Entity>(this.branchName, boRef).then(entity => {
    //     this.entities.set(variable, entity)
    //   }))
    // }
    // await Promise.all(promises)

    this.trpcService.client.bo.getBoReferences.query({
      branchName: this.data.branchName,
      moduleId: this.data.bo.moduleId,
      boTypes: ['Entity', 'StaticEntity'],
      includeImportedModules: true,
    }).then(typeBoRefs => {
      this.typeBoRefs = typeBoRefs
      
      this.possibleVariables = {}
      for(const [variable, type] of Object.entries(this.form.getVariableScope(true))) {
        const typeRef = typeBoRefs.find(ref => ref.getQualifiedName() == type)
        if(typeRef) {
          this.possibleVariables[variable] = typeRef
          this.isVariableSelected[variable] = !!this.form.variables.get(variable)
        }
      }  

      this.recalcAvailableElements()
    })
  }

  getFormControlElements() {
    return this.elements.filter(el => el instanceof FormControlElement) as FormControlElement[]
  }

  private async recalcAvailableElements() {
    const promises: Promise<void>[] = []
    for(const [variable, boRef] of this.form.variables) {
      if(!this.entities.get(variable)) {
        promises.push(this.trpcService.client.bo.getBo.query({
          branchName: this.branchName,
          ...boRef,
        }).then((entity: Entity) => {
          this.entities.set(variable, entity)
        }))
      }
    }
    await Promise.all(promises)

    this.elements = this.form.getFormElements()
    this.availableElements = []
    for(const [variable, boRef] of this.form.variables) {
      const entity = this.entities.get(variable)
      if(!entity) continue

      for(const property of entity.properties) {
        const usages = this.elements.filter(el => el instanceof FormControlElement && el.variable == variable && el.bindingPropertyName == property.name)

        this.availableElements.push({
          variable,
          bindingPropertyName: property.name,
          usageCount: usages.length,
          tsType: property.type,
        })
      }
    }
  }

  addElement(element: AvailableElement, idx: number) {
    const bindingCode = new Expression(`${element.variable}.${element.bindingPropertyName}`)

    let newBlock: FormInputBlock
    switch(element.tsType) {
      case 'Date':
        newBlock = new DatePickerBlock({
          bindingCode
        })
        break

      case 'boolean':
        newBlock = new CheckboxBlock({
          bindingCode
        })
        break

      default:
        const typeRef = this.typeBoRefs.find(ref => ref.getQualifiedName() == element.tsType)
        if(typeRef?.boType == 'StaticEntity') {
          // element refers to a static entity; use dropdown
          newBlock = new DropdownBlock()
          newBlock.init({
            bindingCode: `${bindingCode}.id`,
            optionListCode: `${element.tsType}.optionList`,
            optionValueCode: 'option.id',
            optionLabelCode: 'option.name',
          })
        } else {
          newBlock = new InputBlock({
            bindingCode
          })
        }
    }

    if(newBlock) {
      newBlock.exampleText = sentenceCase(element.bindingPropertyName)
      newBlock.id = ++this.data.bo.maxBlockIdEncountered
      this.form.children.push(newBlock)
    }
    this.recalcAvailableElements()
  }

  removeElement(element: FormElement, idx: number) {
    if(element instanceof FormControlElement) {
      element.parentBlock.children = element.parentBlock.children.filter(block => block !== element.block)
      this.recalcAvailableElements()
    }

    if(this.elements.length) {
      const newFocusIdx = Math.min(idx, this.elements.length - 1)
      const li = this.usedList.nativeElement.children.item(newFocusIdx) as HTMLLIElement
      this.focusButtonInElement(li)
    }
  }

  private focusButtonInElement(el: HTMLElement) {
    // TODO: doesn't work for some reason
    const buttons = el.getElementsByTagName('button')
    if(buttons.length) {
      setTimeout(() => buttons[0].focus(), 10)
    }
  }

  getPossibleVariableNames() {
    return Object.keys(this.possibleVariables)
  }

  updateVariableSelection(variable: string) {
    if(this.isVariableSelected[variable]) {
      this.form.variables.set(variable, this.possibleVariables[variable])
    } else {
      this.form.variables.delete(variable)
    }

    this.recalcAvailableElements()
  }

  sortUsedElements(event: CdkDragDrop<FormElement, FormElement>) {
    const element = event.item.data as FormElement

    const oldBlockIndex = this.form.children.findIndex(block => block === element.getBlocks()[0])
    let newBlockIndex = 0
    if(event.currentIndex) {
      // TODO: calculation of new index doesn't always work

      const prevElement = this.elements[event.currentIndex - 1]
      const blocks = prevElement.getBlocks()
      const lastBlockBefore = blocks.length && blocks[blocks.length - 1]
      if(lastBlockBefore) {
        newBlockIndex = this.form.children.findIndex(block => block === lastBlockBefore) + 1
      }
    }

    console.log(oldBlockIndex, '-->', newBlockIndex)
    console.log(this.form.children.map(b => b.id))

    this.form.children.splice(oldBlockIndex, element.getBlocks().length)
    if(oldBlockIndex < newBlockIndex) newBlockIndex -= element.getBlocks().length
    this.form.children.splice(newBlockIndex, 0, ...element.getBlocks())
    console.log(this.form.children.map(b => b.id))

    this.recalcAvailableElements()
  }
}
