<h1>Variables</h1>
<ul>
	<li *ngFor="let variable of getPossibleVariableNames()">
		<input type="checkbox" [(ngModel)]="isVariableSelected[variable]" (ngModelChange)="updateVariableSelection(variable)">
		{{ variable }} ({{ possibleVariables[variable].getQualifiedName() }})
	</li>
</ul>

<h1>Used</h1>
<ul cdkDropList (cdkDropListDropped)="sortUsedElements($event)">
	<li *ngFor="let element of getFormControlElements(); let index = index" #usedList cdkDrag [cdkDragData]="element">
		<button mat-icon-button (click)="removeElement(element, index)">
			<mat-icon>delete</mat-icon>
		</button>
		<span>
			{{ element.variable }}.{{ element.bindingPropertyName }}
		</span>
	</li>
</ul>

<h1>Available</h1>
<ul #availableList>
	<li *ngFor="let element of availableElements; let index = index" cdkFocusInitial>
		<button mat-icon-button (click)="addElement(element, index)">
			<mat-icon>add_circle_outline</mat-icon>
		</button>
		<span [style.color]="element.usageCount ? 'lightgray' : 'black'">
			{{ element.variable }}.{{ element.bindingPropertyName }} ({{ element.usageCount }})
		</span>
	</li>
</ul>

<!-- <button [mat-dialog-close]="form">Return</button> -->