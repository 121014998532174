import { ScopeBlock } from './../blocks/logic/scope-block';
import { DataUtil } from '@shared/util/data-util'
import { VariableDefinition, VariableDefinitionContext } from './../script/variable-definition';
import { Block } from '../blocks/block';
import type { TsCodeType, TsTypeType } from './../types';
import { BusinessObject } from './business-object';
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LowgileAction } from '@shared/script/lowgile-action'
import { BoInput } from './bo-input'
import { BoVisitor } from './bo-visitor'
import { Parameter } from '@shared/script/parameter'
import { TextBlock } from '@shared/blocks'
import { BoSpecificDetails, BoSpecificDetailsQuery } from './bo-specific-details'
import { PlaceholderBlock } from '@shared/blocks/layout/placeholder-block'
import { ScreenScrollPositionClass } from './screen-scroll-position-class'
import { ScreenOutput } from './screen-output'

export const ScreenRenderAsTypes = [
	['contents', 'Contents only (default)'],
	['block', 'Block'],
	['inline-block', 'Inline block (for block-based component screens, e.g. a date picker)'],
	['inline', 'Inline (for text-based component screens)'],
] as const
export type ScreenRenderAsType = typeof ScreenRenderAsTypes[number][0]

@jsonObject({ name: 'Screen' })
export class Screen extends BusinessObject {
	@jsonArrayMember(Block)
	blocks: Block[] = []

	@jsonArrayMember(VariableDefinition)
	variableDefinitions: VariableDefinition[] = []
	@jsonArrayMember(LowgileAction)
	actions: LowgileAction[] = []
	@jsonArrayMember(ScreenOutput)
	outputs: ScreenOutput[] = []
	@jsonMember(String)
	renderAs: ScreenRenderAsType = 'contents'
	@jsonMember(Boolean)
	fillParent: boolean = false
	@jsonMember(String)
	staticHtmlHeaders: string = ''
	@jsonMember(String)
	css: string = ''
	@jsonArrayMember(ScreenScrollPositionClass)
	scrollPositionClasses: ScreenScrollPositionClass[] = []

	maxBlockIdEncountered = 0

	constructor(init?: Partial<Screen>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	initializeNewBo(): void {
		this.blocks.push(new TextBlock({
			text: `Screen ${this.getQualifiedName()}`
		}))
	}

	getHtmlSelector() {
		return `${this.moduleId}-${this.boId}`
	}

	getBoSpecificDetails(query?: BoSpecificDetailsQuery): BoSpecificDetails {
		const contexts = query?.inputContexts ?? ['input']
		const placeholderBlocks = this.findAllMatchingBlocks(block => block instanceof PlaceholderBlock) as PlaceholderBlock[]
		
		return {
			...super.getBoSpecificDetails(query),
			inputs: this.variableDefinitions.filter(vd => contexts.includes(vd.context)).map(vd => ({
				name: vd.name,
				type: vd.type,
				optional: !!vd.defaultExpression
			})),
			outputs: this.outputs,
			placeholders: placeholderBlocks.map(b => b.name),
		}
	}

	visitBlocks(visitor: (block: Block) => void) {
		function visit(blocks: Block[], visitor: (block: Block) => void) {
			for(const block of blocks ?? []) {
				visitor(block)
				visit(block.children, visitor)
			}
		}
		visit(this.blocks, visitor)
	}

	findAllMatchingBlocks(blockPredicate: (block: Block) => boolean) {
		const matches: Block[] = []
		this.visitBlocks(block => {
			if(blockPredicate(block)) {
				matches.push(block)
			}
		})
		return matches
	}

	validateAndFix() {
		this.actions = this.actions.filter(a => a.name)
		this.maxBlockIdEncountered = 0
		this.visitBlocks(block => this.maxBlockIdEncountered = Math.max(this.maxBlockIdEncountered, block.id || 0))
		this.visitBlocks(block => {
			if(!block.id) block.id = ++this.maxBlockIdEncountered
		})

		return {}
	}

	visit(visitor: BoVisitor): void {
		this.blocks.forEach((block, idx) => block.visitWithBoVisitor(visitor, ['blocks', idx]))
		this.actions.forEach((action, idx) => action.visit(visitor, ['actions', idx]))
		this.variableDefinitions.forEach((vd, idx) => vd.visit(visitor, ['variableDefinitions', idx]))
	}

	createVirtualRootBlockAndLinkParents() {
		const rootBlock = new ScopeBlock({
			children: this.blocks,
			// variables: this.variableDefinitions,
			declarationOnlyVariables: [
				...this.variableDefinitions,
				// TODO: change type of app & screen variables (not sure it really matters as they are explicitly declared in DS __DataStore types)
				new Parameter({ name: 'app', type: 'any' }),
				new Parameter({ name: 'screen', type: 'any' }),
				new Parameter({ name: '$pathSegments', type: 'string[]' }),
				new Parameter({ name: '$disabled', type: 'boolean' }),
			]
		})
		
		rootBlock.visit((block, parent) => {
			if(parent) block.parent = parent
		}, null, 0, 'last')

		return rootBlock
	}

	replaceBlock(oldBlock: Block, newBlock: Block) {
		const rootBlock = new ScopeBlock({
			children: this.blocks
		})

		const success = rootBlock.replaceBlock(oldBlock, newBlock, true)
		
		this.blocks = rootBlock.children
		return success
	}
}