import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { AuthService } from './auth-service'

export const studioRoleGuard: CanActivateFn = (route, state) => {
	const authService = inject(AuthService)
	const redirect = state.url ? `?redirect=${encodeURIComponent('/studio' + state.url)}` : ''

	if(!authService.isLoggedIn()) {
		window.location.replace(`/studio/login${redirect}`)
		return false
	}

	if(!authService.hasSystemPermission('UseStudio')) {
		window.location.replace(`/studio/no-studio-access${redirect}`)
		return false
	}

	return true
}