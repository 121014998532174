import { UserIdCtor, type LogLevel, type UserIdType } from '@shared/types'
import { DataUtil } from '@shared/util/data-util'
import { format } from 'date-fns'
import { jsonMember, jsonObject, toJson } from 'typedjson'

export const LogEntryTypeParams: Record<LogLevel, {
	typeString: string,
	priority: number,
	consoleFormat: string,
}> = {
	critical: { typeString: 'CRITICAL', priority: 1, consoleFormat: '\x1b[37m\x1b[41m\x1b[1m%s\x1b[0m' }, // white on red, bold
	error: { typeString: 'ERROR', priority: 2, consoleFormat: '\x1b[31m\x1b[1m%s\x1b[0m' }, // red, bold
	warning: { typeString: 'warn', priority: 3, consoleFormat: '\x1b[33m%s\x1b[0m' }, // yellow
	info: { typeString: 'info', priority: 4, consoleFormat: '%s' }, // normal
	debug: { typeString: 'debug', priority: 5, consoleFormat: '\x1b[36m%s\x1b[0m' }, // blue
	status: { typeString: 'status', priority: 1, consoleFormat: '\x1b[1m%s\x1b[0m' }, // bold
}

const LogComponents = [
	'Initializer', 'Bootstrapper', 'Repository',
	'CompilationManager', 'AngularFrontendCompiler', 'BackendCompiler', 'BuildManager',
	'DbManager',
	'EventManager',
	'ServiceManager',
	'ExtensionManager',
	'Api', 'ApiExecutor',
	'InterThreadComm.',
	'TriggerManager',
	'CertificateManager',
	'Console',
	'AppCode',
	'LicenseManager',
	'AuthManager',
] as const

const consolePadLength = {
	typeStr: Math.max(...Object.entries(LogEntryTypeParams).map(([k, v]) => v.typeString.length)) + 3,
	componentStr: Math.max(...LogComponents.map(c => c.length)) + 3,
}

export type LogComponent = typeof LogComponents[number]

class BaseLogEntry {
	@jsonMember(Number)
	id?: number
	@jsonMember
	date!: Date
	@jsonMember(String)
	type!: LogLevel
	@jsonMember(String)
	component?: LogComponent
	@jsonMember(String)
	appModuleId?: string
	@jsonMember(String)
	moduleId?: string
	@jsonMember(String)
	message?: string
}

@jsonObject
export class LogEntry extends BaseLogEntry {
	@jsonMember(String)
	stackTrace?: string
	@jsonMember(() => UserIdCtor)
	userId?: UserIdType

	@jsonMember(String)
	get __type(): string { return 'LogEntry' }
	set __type(_) { /* ignore */ }

	constructor(init?: Partial<LogEntry>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	formatForConsole() {
		const params = LogEntryTypeParams[this.type]
		
		const dateStr = format(this.date, 'yyyy-MM-dd HH:mm:ss')
		const typeStr = `(${params.typeString})`.padEnd(consolePadLength.typeStr)
		const componentStr = (this.component ? `(${this.component})` : '').padEnd(consolePadLength.componentStr)
		
		const message = `[${dateStr}] ${typeStr}${componentStr}${this.message}`
		return [params.consoleFormat, message]
	}
}

export class LogEntryWithoutStack extends BaseLogEntry {
	@jsonMember(Boolean)
	hasStackTrace: boolean = false
	@jsonMember(String)
	userName?: string

	constructor(init?: LogEntryWithoutStack) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}
}