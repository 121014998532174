import type { TsTypeType } from './../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { Actionable } from './actionable'
import { ExecutionContext } from '@shared/script/execution-context'
import { BoVisitor } from './bo-visitor'
import { DataUtil } from '@shared/util/data-util'

export const EntityMethodAvailabilities = [
	{ id: 'serverOnly', name: 'On server only', executionLocation: 'server' },
	{ id: 'clientOnly', name: 'On client only', executionLocation: 'client' },
	{ id: 'serverAndClient', name: 'On server and client', executionLocation: 'serverAndClient' },
	{ id: 'calculation', name: 'Calculated field (read-only, available on server and client)', executionLocation: 'calculation' },
	{ id: 'callableFromClient', name: 'Runs on server, but is callable from client', executionLocation: 'server' },
] as const

@jsonObject
export class EntityMethod extends Actionable {
	@jsonMember(Boolean) isStatic: boolean = false
	@jsonMember(Boolean) isAsync: boolean = false
	@jsonMember(String)
	returnType: TsTypeType = 'void'
	@jsonMember(String)
	availability: typeof EntityMethodAvailabilities[number]['id'] = 'serverOnly'

	constructor(init?: Partial<EntityMethod>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getSignature(executionContext: ExecutionContext, forDeclaration: boolean, thisType?: string, includeFunctionKeyword=false) {
		const isCallableAndNeedsAsync = this.availability == 'callableFromClient' && executionContext.needsAsyncForClientCallableMethods()
		const promisify = this.isAsync || isCallableAndNeedsAsync

		const returnType = promisify ? `Promise<${this.returnType}>` : this.returnType
		const returns = returnType ? `: ${returnType}` : ''

		const inputDecls: string[] = []
		if(thisType) {
			if(this.isStatic) {
				thisType = `typeof ${thisType}`
			}
			inputDecls.push(`this: ${thisType}`)
			if(this.availability == 'callableFromClient' && !this.isStatic) {
				inputDecls.push(`clientThis: ${thisType}`)
			}
		}
		inputDecls.push(...this.inputs.map(i => {
			if(forDeclaration) {
				return `${i.name}${i.defaultExpression ? '?' : ''}: ${i.type}`
			} else {
				return `${i.name}: ${i.type}` + (i.defaultExpression ? ` = ${i.defaultExpression}` : '')
			}
		}))

		const keywords: string[] = []
		if(this.isStatic && forDeclaration) keywords.push('static')
		if(!forDeclaration && (this.isAsync || promisify)) keywords.push('async')
		if(includeFunctionKeyword) keywords.push('function')

		return `${keywords.join(' ')} ${this.name}(${inputDecls.join(', ')})${returns}`
	}

	getExecutionLocation() {
		return EntityMethodAvailabilities.find(av => av.id == this.availability)?.executionLocation
	}

	visit(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		// super.visit(visitor, pathPrefix)
		visitor.visitTsCode(this.code, this, 'method', [...pathPrefix, 'code'], newCode => this.code = newCode)
		visitor.visitTsCode(this.returnType, this, 'type', [...pathPrefix, 'returnType'], newType => this.returnType = newType)
		this.inputs.forEach((input, idx) => input.visit(visitor, [...pathPrefix, 'inputs', idx]))
	}
}
