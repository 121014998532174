import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember, toJson } from 'typedjson'
import { BoReference } from './bo-reference'
import { RestrictableItemRestriction } from './restrictions/restrictable-item-restriction'
import { BoVisitor } from './bo-visitor'
import { DataUtil } from '@shared/util/data-util'

@jsonObject({ name: 'AccessControl' })
export class AccessControl extends BusinessObject {
	@jsonArrayMember(BoReference)
	coveredBos: BoReference[] = []

	@jsonArrayMember(RestrictableItemRestriction)
	restrictions: RestrictableItemRestriction[] = []

	constructor(init?: Partial<AccessControl>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	isBoCovered(bo: BusinessObject | BoReference) {
		const boRef = this.coveredBos.find(ref => ref.matches(bo))
		return !!boRef
	}

	getRestrictionForBo(boRef: BoReference, itemName: string) {
		if(!this.isBoCovered(boRef)) return null
		const restriction = this.restrictions.find(r => r.matches({
			...boRef,
			itemName,
		}))
		return restriction
	}

	getClassNames(): string[] {
		return [this.getQualifiedName()]
	}

	visit(visitor: BoVisitor): void {
		this.coveredBos.forEach(boRef => visitor.visitBoReference(boRef, ['coveredBos']))
		this.restrictions.forEach(restriction => {
			visitor.visitTsCode(restriction.restrictionCode, restriction, 'restriction', ['restrictions', restriction.getQualifiedTypeAndName(), restriction.itemName, 'code'], newCode => restriction.restrictionCode = newCode)
		})
	}
}