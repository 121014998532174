import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class BlockStyle {
	@jsonMember(String)
	name!: string
	@jsonMember(String)
	prefix?: string
	@jsonMember(String)
	value?: string
	@jsonMember(Boolean)
	isExpression: boolean = false

	constructor(init?: Partial<BlockStyle>) {
		DataUtil.assignCommonProperties(this, init)
	}
}