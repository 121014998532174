import { ScopeBlock } from './blocks/logic/scope-block';
import { FormBlock } from './blocks/form/form-block';
import { Process } from './bos/process'
import { Trigger } from './bos/trigger';
import { Theme } from './bos/theme';
import { AccessControl } from './bos/access-control';
import { Resource } from './bos/resource';
import { Language } from './bos/language';
import { CheckboxBlock } from './blocks/form/checkbox-block';
import { DropdownBlock } from './blocks/form/dropdown-block';
import { LinkBlock } from '@shared/blocks/other/link-block';
import { ListBlock } from './blocks/layout/list-block';
import { ToolbarBlock } from './blocks/layout/toolbar-block';
import { MainLayoutBlock } from './blocks/layout/main-layout-block';
import { ServerDataStore } from './bos/server-data-store';
import { ServiceInterface } from './bos/service-interface'
import { DataType } from './bos/data-type';
import { Role } from './bos/role';
import { Permission } from './bos/permission';
import { StaticEntity } from './bos/static-entity';
import { Label } from './bos/label';
import { DataStore } from './bos/data-store';
import { Setting } from './settings/setting'
import { ConfigurationItem } from './bos/configuration-item'
import { User, UserRecord } from './auth'
import { RawHtmlBlock } from './blocks/layout/raw-html-block'
import { Screen } from './bos/screen'
import { ScreenBlock } from './blocks/layout/screen-block'
import { ForBlock } from './blocks/logic/for-block'
import { IfBlock } from './blocks/logic/if-block'
import { LayoutBlock } from './blocks/layout/layout-block'
import { InputBlock } from './blocks/form/input-block'
import { TextBlock } from './blocks/other/text-block'
import { Module } from './bos/module'
import { Entity } from './bos'
import { UserDefinedConfiguration } from './bos/user-defined-configuration'
import { ButtonBlock } from './blocks/form/button-block'
import { MainLayoutSidebarBlock } from './blocks/layout/main-layout-sidebar-block'
import { ContentAreaBlock } from './blocks/layout/content-area-block'
import { ListItemBlock } from './blocks/layout/list-item-block'
import { DatePickerBlock } from './blocks/form/datepicker-block'
import { PlaceholderBlock } from './blocks/layout/placeholder-block'
import { PdfBlock } from './blocks/other/pdf-block'
import { ImageBlock } from './blocks/other/image-block'
import { VideoBlock } from './blocks/other/video-block'
import { NamedGridBlock } from './blocks/layout/named-grid-block'
import { DataTableBlock } from './blocks/layout/data-table-block'
import { DataTableColumnBlock } from './blocks/layout/data-table-column-block'
import { TabGroupBlock } from './blocks/layout/tab-group-block'
import { TabGroupTabBlock } from './blocks/layout/tab-group-tab-block'
import { FileUploadBlock, RichTextEditorBlock, SliderBlock } from './blocks'
import { ElseBlock } from './blocks/logic/else-block'
import { DisableBlock } from './blocks/logic/disable-block'
import { BuildConfiguration } from './build/build-configuration';
import { LogEntry } from './monitoring/log-entry';
import { Tenant } from '@shared/tenants/tenant'
import { SearchableDropdownBlock } from './blocks/form/searchable-dropdown-block'
import { IconBlock } from './blocks/other/icon-block'
import { DividerBlock } from './blocks/layout/divider-block'
import { ExpansionPanelBlock } from './blocks/layout/expansion-panel-block'
import { ToggleGroupBlock } from './blocks/form/toggle-group-block'
import { CardBlock } from './blocks/layout/card-block'
import { LanguageSwitcherBlock } from './blocks/other/language-switcher-block'
import { ChartBlock } from './blocks/other/chart-block'
import { SpinnerBlock } from './blocks/other/spinner-block'
import { StepperBlock } from './blocks/form/stepper-block'
import { StepperStepBlock } from './blocks/form/stepper-step-block'
import { Lock } from './lock/lock'
import { DeploymentContainer } from './deployment/deployment-container'
import { DeploymentSource } from './deployment/deployment-source'
import { DeploymentAuthorization } from './deployment/deployment-authorization'
import { TriggerConfiguration } from './trigger/trigger-configuration'
import { UserTaskToken, UserTaskTokenDetails } from './types'
import { IdentityProvider } from './auth/identity-provider'

export const AllConcreteBlockTypes = [
	// core blocks
	TextBlock,
	LinkBlock,
	InputBlock,
	DropdownBlock,
	CheckboxBlock,
	DatePickerBlock,
	ButtonBlock,
	IconBlock,
	ImageBlock,
	// logic blocks
	IfBlock,
	ElseBlock,
	ForBlock,
	DisableBlock,
	// layout blocks
	LayoutBlock,
	NamedGridBlock,
	CardBlock,
	// tables & lists
	DataTableBlock,
	DataTableColumnBlock,
	ListBlock,
	ListItemBlock,
	// additional form fields
	FormBlock,
	ToggleGroupBlock,
	FileUploadBlock,
	SearchableDropdownBlock,
	SliderBlock,
	StepperBlock,
	StepperStepBlock,
	RichTextEditorBlock,
	// screen structure blocks
	ScreenBlock,
	PlaceholderBlock,
	ContentAreaBlock,
	TabGroupBlock,
	TabGroupTabBlock,
	ToolbarBlock,
	ExpansionPanelBlock,
	DividerBlock,
	MainLayoutBlock,
	MainLayoutSidebarBlock,
	// other blocks
	ChartBlock,
	LanguageSwitcherBlock,
	PdfBlock,
	ScopeBlock,
	SpinnerBlock,
	RawHtmlBlock,
	VideoBlock,
] as const

export const AllConcreteBlockTypesMap = Object.fromEntries(
	AllConcreteBlockTypes.map(blockType => [new blockType().__type, blockType])
)

export const BusinessObjectClassesMap = {
	Module,
	AccessControl,
	ConfigurationItem,
	DataStore,
	Entity,
	StaticEntity,
	Label,
	Screen,
	Process,
	Role,
	Permission,
	DataType,
	ServerDataStore,
	ServiceInterface,
	Resource,
	Language,
	Trigger,
	Theme,
	UserDefinedConfiguration,
} as const

export const MappableRootClasses = {
	...BusinessObjectClassesMap,
	Module,
	// Dtos
	Tenant,
	User,
	UserRecord,
	Setting,
	BuildConfiguration,
	IdentityProvider,
	LogEntry,
	Lock,
	DeploymentContainer,
	DeploymentSource,
	DeploymentAuthorization,
	TriggerConfiguration,
	UserTaskToken,
	UserTaskTokenDetails,
} as const
