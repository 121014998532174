import { DataUtil } from '@shared/util/data-util'
import type { TsCodeType, TsTypeType } from './../types';
import { ValidationRule } from './../data/validation-rule';
import type { MandatoryType } from "../data/validation-rule";
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { TypeReference } from '@shared/data/type-reference'
import { BoVisitor } from './bo-visitor'
import type { Scope } from 'ts-morph'


@jsonObject
export class EntityProperty {
	@jsonMember(String) name!: string
	@jsonMember(String) type: TsTypeType = 'string'
	@jsonArrayMember(ValidationRule) validationRules: ValidationRule[] = []
	@jsonMember({ constructor: Number }) mandatoryLevel: MandatoryType = 0 // 0=not mandatory
	@jsonMember(Boolean) isStatic: boolean = false
	@jsonMember(String) defaultExpression: TsCodeType = ''
	@jsonMember(Number) maxLen: number = 0
	@jsonMember(String) mandatoryExpression?: TsCodeType
	@jsonMember(String) scope?: Scope
	@jsonMember(Boolean) isSearchableByDefault: boolean = false

	questionToken: boolean = false
	exclamationToken: boolean = false

	constructor(init?: Partial<EntityProperty>) {
		DataUtil.assignCommonProperties(this, init)
	}

	visit(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		// super.visit(visitor, pathPrefix)
		visitor.visitTsCode(this.defaultExpression, this, 'property', [...pathPrefix, 'defaultExpression'], newCode => this.defaultExpression = newCode)
		visitor.visitTsCode(this.type, this, 'type', [...pathPrefix, 'type'], newType => this.type = newType)
		this.validationRules.forEach((rule, idx) => rule.visit(visitor, [...pathPrefix, 'validationRules', idx]))
	}
}