<div *ngIf="type == 'pipeSeparated'">
	<ng-container *ngFor="let language of languageList; let last = last">
		<a class="has-click-handler" [style.font-weight]="languageBridge.language == language[0] ? 'bold' : ''" (click)="$event.stopPropagation(); languageBridge.language = language[0]">
			{{ language[1] }}
		</a>
		<span *ngIf="!last"> | </span>
	</ng-container>
</div>
<div *ngIf="type == 'dropdown'">
	<mat-select [(ngModel)]="languageBridge.language" panelClass="auto-width">
		<mat-option *ngFor="let language of languageList" [value]="language[0]">
			<a class="has-click-handler" [style.font-weight]="languageBridge.language == language[0] ? 'bold' : ''">
				{{ language[1] }}
			</a>
		</mat-option>

	</mat-select>
</div>
<div *ngIf="type == 'globe'">
	<mat-icon [matMenuTriggerFor]="menu">language</mat-icon>
	<mat-menu #menu="matMenu">
		<button mat-menu-item *ngFor="let language of languageList" (click)="languageBridge.language = language[0]">
			<span [style.font-weight]="languageBridge.language == language[0] ? 'bold' : ''">
				{{ language[1] }}
			</span>
		</button>
	</mat-menu>
</div>
