import { UserRecord, UserRecordUpdate } from '@shared/auth/user-record'
import { AuthService } from '../services/auth-service'
import { AppContext } from '../app-context'
import { User } from '@shared/app-types'
import { firstValueFrom, tap } from 'rxjs'
import { SessionCookieName } from '@shared/types'
import { AppTrpcService } from '../services/app-trpc.service'
import { LocalStorageUserProperty } from '@shared/types'

export class FrontendUserBridge {
	private static _user?: UserRecord | null = undefined

	static get currentUser() {
		if(this._user === undefined) {
			this._user = null

			const userJson = localStorage.getItem(LocalStorageUserProperty)
			if(userJson) this._user = new UserRecord(JSON.parse(userJson))
		}
		return this._user
	}

	static get sessionId() {
		const cookies = document.cookie.split(/;\s*/)
		const sessionCookie = cookies.find(c => c.startsWith(`${SessionCookieName}=`))
		const parts = sessionCookie?.split('=') ?? []
		return parts[1]
	}

	static async updatePassword(oldPassword: string, newPassword: string) {
		const authService = AppContext.resolve(AuthService)
		return authService.updatePassword(oldPassword, newPassword)
	}

	static async isUsernameAvailable(userName: string) {
		const authService = AppContext.resolve(AuthService)
		return authService.isUsernameAvailable(userName)
	}

	static async registerUser(user: User, password: string, generatePassword: boolean) {
		const authService = AppContext.resolve(AuthService)
		return authService.registerUser(user, password, generatePassword)
	}

	static async updateUser(user: UserRecordUpdate) {
		const trpc = AppContext.resolve(AppTrpcService)
		const updatedUser = await trpc.client.user.updateUserRecord.mutate({ user })
		if(updatedUser) {
			this._user = updatedUser
			localStorage.setItem(LocalStorageUserProperty, JSON.stringify(user))
		}
		return updatedUser
	}

	static async login(userName: string, password: string, mfaToken?: string) {
		const authService = AppContext.resolve(AuthService)
		const login$ = authService.login$({
			stage: mfaToken ? 'mfa' : 'credentials',
			userName,
			password,
			mfaToken,
		}).pipe(
			tap(() => this._user = undefined)
		)
		return firstValueFrom(login$)
	}

	static async logout() {
		const authService = AppContext.resolve(AuthService)
		const logout$ = authService.logout$().pipe(
			tap(() => this._user = undefined)
		)
		return firstValueFrom(logout$)
	}
}