import type { EventType, TsCodeType, TsTypeType } from './../../types';
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { LeafBlock } from '../leaf-block'
import { Parameter } from '@shared/script/parameter'

@jsonObject({ name: 'ChartBlock' })
export class ChartBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Chart configuration (variable)',
		tooltip: () => ({ text: `Store chart configuration in variable for performance reasons` }),
		codeLanguage: 'ts',
		tsReturnType: () => 'Sys.Types.ChartConfiguration',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	chartConfigCode?: TsCodeType

	constructor(init: Partial<ChartBlock> = {}) {
		super()
		this.init(init)
	}

	getEditorCategory(): string {
		return 'Chart'
	}

	get allowedEventTypes(): EventType[] {
		return [
			{ name: 'chartClick', parameters: [new Parameter({ name: 'event', type: 'any', defaultExpression: '$event' })] }
		]
	}
}
