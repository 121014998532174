import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'title-box',
  templateUrl: './title-box.component.html',
  styleUrls: ['./title-box.component.scss']
})
export class TitleBoxComponent implements OnInit {
  @Input() cardClasses: string = ''
  @Input() headerClasses: string = ''
  @Input() contentClasses: string = ''

  constructor() { }

  ngOnInit(): void {
  }

}
