import { BoVisitor } from '@shared/bos/bo-visitor'

export type ValidationType = 'regex' | 'server' | 'client'
export type MandatoryType = number

export class ValidationRule {
	type: ValidationType = 'server'
	code: string = ''

	visit(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		visitor.visitTsCode(this.code, this, 'validation', [...pathPrefix, 'code'], newCode => this.code = newCode)
	}
}