import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({
  name: 'base64Src'
})
export class Base64SrcPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer
  ) {}

  transform(base64: string, mimeType: string): unknown {
    const url = `data:${mimeType};base64,${base64}`
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

}
