import { BranchNameType, BusinessObjectIdType, BusinessObjectTypeType, ModuleIdType, ScreenQualifiedNameAttribute } from '@shared/types'
import { AppContext } from '../app-context'
import { Injectable, OnInit } from '@angular/core'
import { IdbService } from './idb.service'
import { FrontendUserBridge } from '../bridges/frontend-user-bridge'
import { EnvironmentSettings, idbSettingName } from '@ng-shared/lib/services/environment-settings'
import { getBoEditorPath } from '@shared/util/data-util'
import { BoReference } from '@shared/bos/bo-reference'

const OverlayElementId = 'dev-bar-screen-overlay'
const NameElementId = 'dev-bar-screen-name'

interface OverlayDivs {
	overlay: HTMLDivElement
	screenName: HTMLDivElement
}
interface DevBarSettings {
	isAutoReload: boolean
	isKeepData: boolean
	isFloating: boolean
  }
const DevBarSettingsIdbKey = 'Lowgile_DevBarSettings'
  
@Injectable({
	providedIn: 'root'
})
export class DevBarService {
	environmentSettings?: EnvironmentSettings
	settings: DevBarSettings = {} as any
	isCollapsed = true
	canUseDevBar = false

	constructor(
		private idbService: IdbService,
	) {
		const user = FrontendUserBridge.currentUser
		this.canUseDevBar = user?.hasSystemPermission('UseConsole') ?? false
	}

	async init() {
		this.environmentSettings = await this.idbService.get(idbSettingName)
		this.settings = await this.idbService.get(DevBarSettingsIdbKey) || {
			isAutoReload: false,
			isKeepData: false,
			isFloating: false,
		}
	    this.isCollapsed = this.settings.isFloating
	}

	async saveSettings() {
		await this.idbService.put(DevBarSettingsIdbKey, this.settings)
	}

	openBoEditor(branchName: BranchNameType, boRef: BoReference) {
		const path = getBoEditorPath(branchName, boRef)
	
		window.open(path, path);
	}

	handleMouseEvent(event: MouseEvent) {
		const highlightModeActive = event.altKey && event.ctrlKey
		if(!highlightModeActive) {
			this.removeOverlayDivs()
			return
		}

		const overlayDivs = this.getOrCreateOverlayDivs()

		switch(event.type) {
			case 'click':
				return this.openDefiningScreenAtMouseEvent(event)

			case 'mousemove':
				return this.updateOverlay(event, overlayDivs)
		}
	}

	private updateOverlay(event: MouseEvent, overlayDivs: OverlayDivs) {
		const definingScreen = this.findDefiningScreenAtPosition(event.x, event.y)
		if(definingScreen.qualifiedName && definingScreen.rect) {
			const style = overlayDivs.overlay.style
			const { rect } = definingScreen
			style.top = `${rect.top}px`
			style.left = `${rect.left}px`
			style.width = `${rect.width}px`
			style.height = `${rect.height}px`

			overlayDivs.screenName.innerText = definingScreen.qualifiedName
		}
	}

	private findDefiningScreenAtPosition(x: number, y: number): {
		qualifiedName?: string,
		rect?: DOMRect
	} {
		let foundScreenId: string | undefined = undefined
		let foundRect: DOMRect | undefined = undefined

		for(const el of document.elementsFromPoint(x, y)) {
			let screenId = el.getAttribute(ScreenQualifiedNameAttribute)
			if(screenId) {
				if(foundScreenId && foundScreenId != screenId) {
					break
				}
				foundScreenId = screenId
				foundRect = el.getBoundingClientRect()
			}
		}

		return {
			qualifiedName: foundScreenId,
			rect: foundRect,
		}
	}

	private openDefiningScreenAtMouseEvent(event: MouseEvent) {
		if(event.target instanceof HTMLElement) {
			const { qualifiedName} = this.findDefiningScreenAtPosition(event.x, event.y)
			if(qualifiedName) {
				const boRef = BoReference.fromQualifiedName(qualifiedName, 'Screen')
				this.openBoEditor(AppContext.branchName, boRef)
			}
		}
	}

	openTopLevelScreen() {
		const qualifiedName = this.getTopLevelScreenName()
		const boRef = BoReference.fromQualifiedName(qualifiedName, 'Screen')
		this.openBoEditor(AppContext.branchName, boRef)
	}

	getTopLevelScreenName(): string {
		return document.querySelector(`[${ScreenQualifiedNameAttribute}]`)?.attributes?.getNamedItem(ScreenQualifiedNameAttribute)?.value ?? ''
	}

	getOrCreateOverlayDivs(): OverlayDivs {
		let overlay = document.getElementById(OverlayElementId)
		if(!overlay) {
			overlay = document.createElement('div')
			overlay.id = OverlayElementId
			overlay.innerHTML = `<div id="${NameElementId}"></div>`
			document.body.appendChild(overlay)
		}

		return {
			overlay: overlay as HTMLDivElement,
			screenName: document.getElementById(NameElementId) as HTMLDivElement,
		}
	}

	removeOverlayDivs() {
		let overlay = document.getElementById(OverlayElementId)
		if(overlay) document.body.removeChild(overlay)
	}
}