import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface ConfirmationDialogData {
  title: string
  body: string
  buttonCancelText?: string
  buttonConfirmText?: string
}

@Component({
  selector: 'lowgile-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @ViewChild('top') topDivRef: ElementRef<HTMLDivElement>

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) { 
    if(!this.data.buttonCancelText) this.data.buttonCancelText = 'Cancel'
    if(!this.data.buttonConfirmText) this.data.buttonConfirmText = 'Confirm'
  }

  @HostListener('keydown.arrowleft')
  @HostListener('keydown.arrowright')
  cycleButtons() {
    const unselectedButton = this.topDivRef.nativeElement.querySelector<HTMLButtonElement>('button:not(:focus)')
    unselectedButton.focus()
  }

  ngOnInit(): void {
  }

}
