import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@ng-shared/lib/services/auth-service';
import { Component, OnInit, NgZone, HostListener, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ClientLoginResponse } from '@shared/auth/login-response'
import { ErrorService } from '@ng-shared/lib/services/error.service'
import { LoginRequest } from '@shared/auth/login-request'
import { CsrfService } from '@ng-shared/lib/services/csrf.service'

@Component({
  selector: 'lowgile-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginRequest: LoginRequest = {
    stage: 'credentials',
    userName: '',
    password: '',
    mfaToken: '',
    newPassword: '',
  }
  password2 = ''
  recoveryToken = ''
  loginResponse: ClientLoginResponse = {
    nextStage: 'credentials',
  }
  // loginMethods: loginme
  @ViewChild('userNameInput') userNameInputEl?: ElementRef<HTMLInputElement>
  @ViewChild('passwordInput') passwordInputEl?: ElementRef<HTMLInputElement>
  @ViewChild('mfaTokenInput') mfaTokenInputEl?: ElementRef<HTMLInputElement>

  constructor(
    protected authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private csrfService: CsrfService,
    // private zone: NgZone,
  ) { }

  async ngOnInit() {
    this.loginRequest.userName = this.route.snapshot.queryParamMap.get('userName') ?? ''
    this.recoveryToken = this.route.snapshot.queryParamMap.get('recoveryToken') ?? ''

    await this.csrfService.forgetCsrfToken()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.recoveryToken) {
        this.passwordInputEl?.nativeElement.focus()
      } else {
        this.userNameInputEl?.nativeElement.focus()
      }
    }, 0)
  }

  @HostListener('document:keydown.enter')
  async login() {
    if(this.recoveryToken) return

    if(this.loginRequest.stage == 'passwordChangeNeeded') {
      if(this.loginRequest.newPassword != this.password2) {
        this.errorService.error('New password and passwork repetition do not match')
        return
      }
    }

    this.authService.login$(this.loginRequest).subscribe(loginResponse => {
      this.loginResponse = loginResponse
      this.loginRequest.stage = loginResponse.nextStage
      console.log(loginResponse)

      switch(loginResponse?.nextStage) {
        case 'mfa': {
            setTimeout(() => this.mfaTokenInputEl?.nativeElement.focus(), 0)
          break
        }

        case 'passwordChangeNeeded': {
          // TODO:
          break
        }

        case 'successful': {
          this.loginRequest.password = ''
          this.loginRequest.newPassword = ''
          this.password2 = ''
          const redirect = this.route.snapshot.queryParamMap.get('redirect')
          window.location.replace(redirect || '/')
          break
        }
      }
    })
  }

  sso() {
    const redirect = this.route.snapshot.queryParamMap.get('redirect')
    window.location.href = '/__sso' + (redirect ? `?redirect=${encodeURIComponent(redirect)}` : '')
  }

  async requestToken() {
    this.authService.requestAuthToken$(this.loginRequest).subscribe(loginResponse => {
      setTimeout(() => this.mfaTokenInputEl?.nativeElement.focus(), 0)
    })
  }

  getMfaTokenHint() {
    const r = this.loginResponse
    if(r.hasAuthenticator && r.hasPhone)
      return 'Enter authenticator pin or request SMS pin'
    if(r.hasAuthenticator)
      return 'Enter authenticator pin'
    if(r.hasPhone)
      return 'Enter SMS pin'
    return 'Warning: no authenticator or mobile phone is configured!'      
  }

  async startPasswordRecovery() {
    if(!this.loginRequest.userName) {
      this.errorService.error('Please first provide your username or e-mail')
      return
    }

    await this.authService.startPasswordRecovery(this.loginRequest.userName)
    this.errorService.success('A password recovery e-mail was sent if the account was found', 10_000)
  }

  @HostListener('document:keyup.enter')
  async setNewPassword() {
    if(!this.recoveryToken) return

    if(this.loginRequest.newPassword != this.password2) {
      this.errorService.error('New password and passwork repetition do not match')
      return
    }

    await this.authService.finalizePasswordRecovery(this.loginRequest.userName, this.recoveryToken, this.loginRequest.newPassword)
    this.loginRequest.stage = 'credentials'
    this.loginRequest.password = this.loginRequest.newPassword
    this.loginRequest.newPassword = ''
    this.recoveryToken = ''
    this.password2 = ''
    await this.login()
  }
}
