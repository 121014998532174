import { SharedStudioModule } from '../shared/shared.studio.module';
import { NgModule } from '@angular/core';

import { EditRoutingModule } from './edit-routing.module';
import './abstract-editor.component'

@NgModule({
  declarations: [],
  imports: [
    SharedStudioModule,
    EditRoutingModule,
  ],
})
export class EditModule {}
