import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'
import { BackendError, BackendResponse } from '@shared/data/backend-response'

@Injectable({
	providedIn: 'root'
})
export class ErrorService {
	
	constructor(
		private snackBar: MatSnackBar
	) { }
	
	success(message: string, durationMs?: number) {
		this.snackBar.open(message, undefined, {
			duration: durationMs ?? 3000,
			horizontalPosition: 'center',
			verticalPosition: 'top'
		})
	}
	
	error(message: string | BackendError, durationMs?: number) {
		if(typeof message != 'string') {
			message = message.message + (message.context ? ` [${message.context}]` : '')
		}
		this.snackBar.open(message, undefined, {
			duration: durationMs ?? 10000,
			horizontalPosition: 'center',
			verticalPosition: 'top'
		})
	}

	response(response: BackendResponse<any>) {
		if(response.error) {
			this.error(response.error)
		}
	}

	message(isSuccessful: boolean, successMessage: string, errorMessage: string) {
		if(isSuccessful) {
			this.success(successMessage)
		} else {
			this.error(errorMessage)
		}
	}
}
	