import { DataUtil } from '@shared/util/data-util'
import type { PrimitiveType, TsTypeType } from './../types';
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { TypeReference } from '@shared/data/type-reference'

export const StaticEntityPropertyTypes = {
	string: 'string',
	number: 'number',
	boolean: 'boolean',
	staticEntity: '[Static Entity]',
} as const
export type StaticEntityPropertyType = keyof typeof StaticEntityPropertyTypes

@jsonObject
export class StaticEntityProperty {
	@jsonMember(String) name!: string
	@jsonMember(String)
	type: StaticEntityPropertyType = 'string'
	@jsonMember(Boolean)
	isExpression: boolean = false
	@jsonMember(String)
	staticEntityQualifiedName?: string
	constructor(init?: Partial<StaticEntityProperty>) {
		DataUtil.assignCommonProperties(this, init)
	}

	getDefaultValue() {
		switch (this.type) {
			case 'string':
				return ''
			case 'number':
				return 0
			case 'boolean':
				return false
			case 'staticEntity':
				return null
		}
	}
}