import { Component, ComponentRef, DestroyRef, effect, EventEmitter, Input, input, model, OnDestroy, OnInit, output, Output, signal, ViewContainerRef } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FormControl } from '@angular/forms'
import type * as NgxEditor from 'ngx-editor'

@Component({
	selector: 'lowgile-rich-text-editor',
	template: `
		@if(editor()) {
			<div class="NgxEditor__Wrapper">
				<ng-container *ngComponentOutlet="editorImport.MenuComponent; inputs: { editor: editor() }"></ng-container>
				<ng-container *ngComponentOutlet="editorImport.NgxEditorComponent; inputs: { editor: editor() }"></ng-container>
  				<!-- <ngx-editor-menu [editor]="editor"> </ngx-editor-menu> -->
  				<!-- <ngx-editor [editor]="editor" [ngModel]="html" [disabled]="false" [placeholder]="'Type here...'"></ngx-editor> -->
			</div>
		}
	`,
	styles: [`
		
	`]
})
export class RichTextEditorComponent implements OnInit, OnDestroy {
	protected editorImport: typeof NgxEditor
	protected editor = signal<NgxEditor.Editor | undefined>(undefined)
	protected editorComponentRef = signal<ComponentRef<NgxEditor.NgxEditorComponent> | undefined>(undefined)
	html = input.required<string>()
	@Output() htmlChange = new EventEmitter<string>()

	disabled = input<boolean>(false)
	// formControl = new FormControl('Gugus')


	constructor(
		protected viewContainerRef: ViewContainerRef,
		private destroyRef: DestroyRef,
	) {
		effect(() => {
			const editor = this.editor()
			if(editor) {
				console.log('setting content', this.html())
				editor.setContent(this.html())
				// editor.setDisabled(this.disabled())
			}
		})
	}

	async ngOnInit() {
		this.editorImport = await import('ngx-editor')
		const editor = new this.editorImport.Editor()
		this.editor.set(editor)

		editor.valueChanges.pipe(
			takeUntilDestroyed(this.destroyRef),
		).subscribe(jsonDoc => {
			const html = this.editorImport.toHTML(jsonDoc)
			this.htmlChange.emit(html)
		})

		// const componentRef = this.viewContainerRef.createComponent(this.editorImport.NgxEditorComponent)
		// this.editorComponentRef.set(componentRef)
		// componentRef.instance.editor = this.editor()!
		// componentRef.instance.setDisabledState(true)
		// this.editor()?.setContent(this.html())
		// componentRef.instance.writeValue(this.html())
		// this.editor.update = (value) => this.html.set(value.)
		// componentRef.instance.registerOnChange(() => this.html.set(this.editor))
	}

	ngOnDestroy() {
		this.editor()?.destroy()
	}
}