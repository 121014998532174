import { HasVariableScope } from './has-variable-scope';
import { RestrictableItem } from './restrictions/restrictable-item';
import { DataUtil } from './../util/data-util';
import { TypeReference } from '@shared/data/type-reference';
import { JsonMappable } from './../data/json-mappable'
import type { UserIdType, ModuleIdType, BusinessObjectTypeType, BusinessObjectIdType, TsCodeType, TsTypeType } from './../types'
import { jsonObject, jsonMember, toJson } from 'typedjson'
import { CanBeSecret } from '@shared/data/can-be-secret'
import { BoReference } from './bo-reference'
import { BoVisitor } from './bo-visitor'
import { BusinessObjectWithDataFile } from './business-object-with-data-file'
import { BoSpecificDetails, BoSpecificDetailsQuery } from './bo-specific-details'

type BoLike = { moduleId: ModuleIdType, boId: BusinessObjectIdType }
export function boSort(a: BoLike, b: BoLike) {
	const moduleIdSort = a.moduleId.localeCompare(b.moduleId)
	if(moduleIdSort != 0) return moduleIdSort
	if("boId" in a && "boId" in b) {
		return a.boId.localeCompare(b.boId)
	}
	throw Error('Cannot sort BoLike objects without id or boId')
}

export interface ValidateAndFixResult {}


@jsonObject
@toJson
export class BusinessObject implements JsonMappable, CanBeSecret, HasVariableScope {
	@jsonMember(String)
	get __type(): BusinessObjectTypeType { 
		return DataUtil.getJsonTypeName(this, 'BusinessObject') as BusinessObjectTypeType
	}
	set __type(_) { /*ignore*/ }

	@jsonMember(String) boId: BusinessObjectIdType = ''
	@jsonMember(String) moduleId: ModuleIdType = ''
	// @jsonMember(String) type: BusinessObjectTypYeType = ''
	@jsonMember(Date) creationTimestamp: Date = new Date()
	@jsonMember(Date) updateTimestamp: Date
	@jsonMember(String) lastModifiedBy?: UserIdType
	
	@jsonMember(Boolean) isModifiable: boolean = true
	@jsonMember(Boolean) isDeletable: boolean = true
	@jsonMember(Boolean) isSecret: boolean = false

	@jsonMember(String) systemVersion!: string
	@jsonMember(Boolean) isDeleted?: boolean

	readonly affectsDb: boolean = false

	constructor(init?: Partial<BusinessObject>) {
		this.creationTimestamp = this.updateTimestamp = new Date()
		DataUtil.assignCommonProperties(this, init)
	}

	initializeNewBo() {}

	getQualifiedName() {
		return `${this.moduleId}.${this.boId}`
	}
	
	getQualifiedTypeAndName() {
		return `${this.__type}:${this.moduleId}.${this.boId}`
	}

	getImportName() {
		return `${this.moduleId}__${this.boId}`
	}

	getDependencies(allBos: BusinessObject[]): BusinessObject[] {
		return []
	}

	getTypeReferences(): TypeReference[] {
		return []
	}

	getClassNames(): string[] {
		return []
	}

	getRestrictableItems(): RestrictableItem[] {
		return []
	}

	getRestrictableItem(itemName: string): RestrictableItem | undefined {
		return this.getRestrictableItems().find(i => i.itemName == itemName)
	}

	validateAndFix(): ValidateAndFixResult {
		return {}
	}

	getBoReference(includeBoType = true) {
		return new BoReference({
			moduleId: this.moduleId,
			boId: this.boId,
			boType: includeBoType ? this.__type : undefined!,
		})
	}
	
	getBoSpecificDetails(query?: BoSpecificDetailsQuery): BoSpecificDetails {
		return {}
	}
	
	hasDataFile(): this is BusinessObjectWithDataFile {
		return false
	}

	visit(visitor: BoVisitor) {}

	getVariableScope(includeDeclarationVariables: boolean): Record<string, string> {
		return {}
	}
}
