import type { EventType } from './../../types';
import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { TextBlock } from '../other/text-block'

@jsonObject({ name: 'ListItemBlock' })
export class ListItemBlock extends Block {
	constructor(init: Partial<ListItemBlock> = {}) {
		super()
		this.init(init)
	}
	get allowedEventTypes(): EventType[] { return [
		{ name: 'click' },
		{ name: 'dblclick' },
	]}

	getEditorCategory(): string {
		return 'Tables & Lists'
	}

	static createNewForEditor(): Block {
		return new this({
			children: [
				new TextBlock({ text: 'Text'})
			]
		})
	}
}
