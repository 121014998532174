import { Component } from '@angular/core'
import { AgRendererComponent } from 'ag-grid-angular'
import { ICellRendererParams } from 'ag-grid-community'

@Component({
	selector: 'grid-checkbox-editor',
	template: '<input type="checkbox" [(ngModel)]="params.value" (change)="refresh(params)">'
})
export class GridCheckboxEditorComponent implements AgRendererComponent {
	public params!: ICellRendererParams
	public setValue!: (data: any, value: boolean) => void

	agInit(params: ICellRendererParams) {
		this.params = params
		this.setValue = (params as any).setValue
	}

	refresh(params: ICellRendererParams): boolean {
		//params.data[params.column?.getColId()] = params.value
		this.setValue?.(params.data, params.value)
		params.refreshCell?.()
		return false
	}
}