import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember, toJson, jsonMapMember } from 'typedjson'
import { DataUtil } from '@shared/util/data-util'

@jsonObject({ name: 'Language' })
export class Language extends BusinessObject {
	@jsonMember(String)
	isoCode?: string
	@jsonMember(String)
	shortName?: string
	@jsonMember(String)
	displayName?: string

	constructor(init?: Partial<Language>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getClassNames(): string[] {
		return ['$T']
	}
}
