import { type FlexLayoutDirection, FlexLayoutDirectionOptions, type ScreenSize, ScreenSizes, EventType } from '@shared/types'
import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { LogicBlock } from '../logic/logic-block'
import { Expression, TextOrExpression } from '@shared/data/text-or-code'

@jsonObject({ name: 'LayoutBlock' })
export class LayoutBlock extends Block {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 1,
		label: 'Layout',
		options: Object.entries(FlexLayoutDirectionOptions)
	})
	layoutDirection: FlexLayoutDirection = 'horizontal'

	@jsonMember(String)
	@ScreenEditorInput<LayoutBlock>({
		inputType: 'dropdown',
		order: 2,
		label: 'Vertical if screen is smaller than',
		tooltip: () => ({ text: 'Use XSmall if you never want to go vertical'}),
		options: ScreenSizes.map(s => [s, s]),
		isVisible(blocks, properties, bo) {
			return blocks[0].showVerticalIfScreenSmallerThan()
		},
		leaveGapIfHidden: () => true,
	})
	verticalIfScreenSmallerThan: ScreenSize = 'XSmall'

	@ScreenEditorInput<LayoutBlock>({
		inputType: 'code',
		order: 3,
		label: blocks => blocks[0].layoutDirection == 'grid-horizontal' ? 'Number of columns' : 'Number of rows',
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: false,
		isVisible(blocks, properties, bo) {
			return blocks[0].layoutDirection == 'grid-horizontal' || blocks[0].layoutDirection == 'grid-vertical'
		},
	})
	@jsonMember(Expression) gridColumnCountCode = new Expression('2')
	
	@ScreenEditorInput<LayoutBlock>({
		inputType: 'input',
		order: 4,
		label: 'Number of columns for preview',
		contentType: 'number',
		elementType: 'input',
		setter: (target, key, value) => Reflect.set(target, key, Number.isNaN(Number(value)) ? undefined : Number(value)),
		isVisible(blocks, properties, bo) {
			return blocks[0].layoutDirection == 'grid-horizontal' || blocks[0].layoutDirection == 'grid-vertical'
		},
	})
	@jsonMember(Number) columnCountPreview?: number

	@ScreenEditorInput({
		inputType: 'code',
		order: 5,
		label: 'Gap between children',
		tooltip: () => ({ text: 'Specify gap in CSS units (e.g. 1em, 24px, 5%, etc.). If one value is given, it will be applied both vertically and horizontally; provide vertical and horizontal values separately for more control (e.g. 0.5em 1em)' }),
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
	})
	@jsonMember(TextOrExpression) gapCode = new TextOrExpression('1em', 'T')

	@ScreenEditorInput<LayoutBlock>({
		inputType: 'code',
		order: 10,
		label: 'Row definitions',
		tab: 'Advanced',
		tooltip: () => ({ text: 'Specify exact CSS row definitions if desired.<br>E.g. <pre>auto 1fr 2fr</pre> for one auto-sized row and two additional rows that split the size 1:2. Leave empty for auto-sized grid', acceptHtml: true }),
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isVisible(blocks, properties, bo) {
			return blocks[0].layoutDirection == 'grid-horizontal' || blocks[0].layoutDirection == 'grid-vertical'
		},
	})
	@jsonMember(String) gridRowDefinitionCode: string = ""
	
	@ScreenEditorInput<LayoutBlock>({
		inputType: 'code',
		order: 10,
		label: 'Column definitions',
		tab: 'Advanced',
		tooltip: () => ({ text: 'Specify exact CSS column definitions if desired.<br>E.g. <pre>auto 1fr 2fr</pre> for one auto-sized column and two additional columns that split the size 1:2. Leave empty for auto-sized grid', acceptHtml: true }),
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isVisible(blocks, properties, bo) {
			return blocks[0].layoutDirection == 'grid-horizontal' || blocks[0].layoutDirection == 'grid-vertical'
		},
	})
	@jsonMember(String) gridColumnDefinitionCode: string = ""
	
	constructor(init: Partial<LayoutBlock> = {}) {
		super()
		this.init(init)
	}

	isLayoutBlock(): this is LayoutBlock {
		return true
	}

	getEditorTitle() {
		return `${super.getEditorTitle()} ${
			FlexLayoutDirectionOptions[this.layoutDirection]
		}`
	}

	getPreviewDirection(): 'row' | 'column' {
		if (
			this.layoutDirection == 'horizontal' ||
			this.layoutDirection == 'horizontal-reverse' ||
			this.layoutDirection == 'grid-horizontal' ||
			this.layoutDirection == 'grid-vertical'
		) {
			return 'row'
		}
		return 'column'
	}

	getPreviewStyles(): string {
		if(this.layoutDirection == 'grid-horizontal') {
			return `display: grid; grid-template-columns: repeat(${this.columnCountPreview || 2}, auto);`
		}
		if(this.layoutDirection == 'grid-vertical') {
			return `display: grid; grid-template-rows: repeat(${this.columnCountPreview || 2}, auto); grid-auto-flow: column;`
		}
		return super.getPreviewStyles()
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'black'
	}

	getTitleColor(): string {
		return 'white'
	}

	getEditorCategory(): string {
		return 'Layout'
	}

	showVerticalIfScreenSmallerThan() {
		return this.layoutDirection == 'horizontal' || this.layoutDirection == 'horizontal-reverse' || this.layoutDirection == 'grid-horizontal'
	}

	get allowedEventTypes(): EventType[] { return [
		{ name: 'click' },
		// { name: 'clickOutside' },
	]}
}
