import type { BusinessObjectIdType, ModuleIdType, TsCodeType, VariableNameType, HtmlType } from '../../types';
import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember, jsonMapMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'RawHtmlBlock' })
export class RawHtmlBlock extends Block {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'HTML',
		tooltip: () => ({ text: 'To insert the content of a Content Area named "content", use the following in your HTML: <code>{{CONTENT:content}}</code>', acceptHtml: true}),
		codeLanguage: 'html',
		editorSize: 'large',
		isBinding: false,
		isMandatory: () => true,
	})
	html: HtmlType = ''

	@jsonMember
	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 2,
		label: 'Encapsulate in component',
	})
	encapsulateInComponent: boolean = false
	
	constructor(init: Partial<RawHtmlBlock> = {}) {
		super()
		this.init(init)
	}
}