import { BusinessObject } from '@shared/bos/business-object'
import { Block } from '..'
import { EditorPropertiesManager } from './editor-properties-manager'
import { EditorGeneralProperty, ScreenEditorProperty } from './editor-property-types'

export function ScreenEditorInput<Item extends Block = Block>(editorProperty: ScreenEditorProperty<Item>): PropertyDecorator {
	return function (target: Object, propertyKey: string | symbol) {
		EditorPropertiesManager.setProperty(target, propertyKey, editorProperty as EditorGeneralProperty<BusinessObject, any>)
	}
}
