import type {
	BusinessObjectIdType,
	ModuleIdType,
	TsCodeType,
	VariableNameType,
} from '../../types'
import { Block } from '../block'
import {
	jsonObject,
	jsonMember,
	jsonArrayMember,
	jsonMapMember,
} from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoReference } from '@shared/bos/bo-reference'

@jsonObject({ name: 'ScreenBlock' })
export class ScreenBlock extends Block {
	@jsonMember
	@ScreenEditorInput({
		inputType: 'boRef',
		order: 1,
		label: 'Screen',
		allowedBoTypes: ['Screen'],
		includeImportedModules: true,
		isMandatory: () => true,
	})
	screenRef: BoReference<'Screen'> = new BoReference()
	@jsonMapMember(String, String) assignments?: Map<
		VariableNameType,
		TsCodeType
	> = new Map()

	constructor(init: Partial<ScreenBlock> = {}) {
		super()
		this.init(init)
	}

	getEditorTitle() {
		return `${super.getEditorTitle()} ${this.screenRef?.getQualifiedName()}`
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'green'
	}

	getTitleColor(): string {
		return 'white'
	}

	getEditableBo(): BoReference {
		return this.screenRef
	}

	shouldRenderContentAreaWrappers() {
		return true
	}

	getEditorCategory(): string {
		return 'Screen Structure'
	}

	needsBoSpecificDetailsForRefs(): BoReference[] {
		return [this.screenRef]
	}
}
