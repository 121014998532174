import { DBSchema, IDBPDatabase, IDBPObjectStore, OpenDBCallbacks, StoreKey, StoreNames, openDB } from 'idb'
import { AppContext } from '../app-context'

export class SimpleIdbConnection<T = any> {
	private storeName: StoreNames<T>
	private db?: IDBPDatabase<T>
	private dbCloseTimeout?: any

	constructor(
		storeName: string,
		private dbName: string,
	) {
		this.storeName = storeName as StoreNames<T>
	}

	private async openDbIfNeeded() {
		if(this.db) {
			clearTimeout(this.dbCloseTimeout)
			this.dbCloseTimeout = undefined
		} else {
			this.db = await openDB(this.dbName, undefined, {
				upgrade: (database, oldVersion, newVersion, transaction, event) => {
					if(!database.objectStoreNames.contains(this.storeName)) {
						database.createObjectStore(this.storeName)
					}
				},
			})
		}

		this.dbCloseTimeout = setTimeout(() => {
			if(this.db) this.db.close()
			this.db = undefined
		}, 10_000)
	}

	async set<Name extends StoreNames<T>>(key: StoreKey<T, Name> | IDBKeyRange, value: any) {
		await this.openDbIfNeeded()
		await this.transaction(store => {
			value = AppContext.jsonMapper.writeToObject(value)
			return store.put(value, key)
		})
	}

	async getFirst<Name extends StoreNames<T>>(key: StoreKey<T, Name> | IDBKeyRange) {
		await this.openDbIfNeeded()
		return this.transaction(async store => {
			let result = await store.get(key)
			result = AppContext.jsonMapper.readFromObject(result as any)
			return result
		})
	}

	async getAll<Name extends StoreNames<T>>(key: StoreKey<T, Name> | IDBKeyRange) {
		await this.openDbIfNeeded()
		return this.transaction(store => {
			return store.getAll(key)
		})
	}

	async delete<Name extends StoreNames<T>>(key: StoreKey<T, Name> | IDBKeyRange) {
		await this.openDbIfNeeded()
		await this.transaction(store => {
			return store.delete(key)
		})
	}

	async clearStore(storeName: string, dbName: string) {
		await this.openDbIfNeeded()
		await this.transaction(store => {
			return store.clear()
		})
	}

	async transaction<R>(fn: (tx: IDBPObjectStore<T, [StoreNames<T>], StoreNames<T>, 'readwrite'>) => R) {
		await this.openDbIfNeeded()
		const tx = this.db!.transaction(this.storeName, 'readwrite')
		try {
			const result = fn(tx.store)
			if(result instanceof Promise) await result
			await tx.done
			return result
		} catch(err: any) {
			tx.abort()
			throw err
		}
	}
}