import { DataUtil } from '@shared/util/data-util'
import { jsonMapMember, jsonMember, jsonObject } from 'typedjson'
import { BoReference } from './bo-reference'
import { ServerDataStoreEntryDbField } from './server-data-store-entry-db-field'

@jsonObject
export class ServerDataStoreEntry extends BoReference {
	@jsonMapMember(String, ServerDataStoreEntryDbField)
	fields = new Map<string, ServerDataStoreEntryDbField>()

	constructor(init?: Partial<ServerDataStoreEntry>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getField(name: string) {
		let field = this.fields.get(name)
		if(!field) {
			console.log('Field not found:', name)
			field = new ServerDataStoreEntryDbField()
			this.fields.set(name, field)
		}
		return field
	}
}