import { Injectable } from '@angular/core';
import { BusinessObjectIdType, ModuleIdType } from '@shared/types'

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  showEditDialog(moduleId: ModuleIdType, boId: BusinessObjectIdType) {
    const url = `${window.location.protocol}//${window.location.host}/studio/edit/Label/master/${moduleId}/${boId}`
    window.open(url)
  }
}
