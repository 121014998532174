import { UserIdType } from '@shared/types'
import { DataUtil } from '@shared/util/data-util'

export class UserLoginToken {
	id?: number
	refreshTokenHash!: string
	userId?: UserIdType
	validUntil!: Date

	constructor(init?: Partial<UserLoginToken>) {
		DataUtil.assignCommonProperties(this, init)
	}
}