import { LogicBlock } from './logic-block'
import { jsonObject, jsonMember, jsonArrayMember, jsonMapMember } from 'typedjson'
import { Parameter } from '@shared/script/parameter'

@jsonObject({ name: 'ScopeBlock' })
export class ScopeBlock extends LogicBlock {
	@jsonArrayMember(Parameter) variables: Parameter[] = []
	@jsonArrayMember(Parameter) declarationOnlyVariables: Parameter[] = []

	constructor(init: Partial<ScopeBlock> = {}) {
		super()
		this.init(init)
	}

	protected getOwnVariables(includeDeclarationVariables: boolean): Record<string, string | null> {
		let vars = Object.fromEntries(this.variables.map(v => [v.name, v.type]))
		if(includeDeclarationVariables) {
			vars = {
				...vars,
				...Object.fromEntries(this.declarationOnlyVariables.map(v => [v.name, v.type]))
			}
		}

		return vars
	}
}
