import { BusinessObject } from '@shared/bos/business-object'
import { DataUtil } from '@shared/util/data-util'
import { jsonObject, jsonMember, jsonArrayMember, toJson, jsonMapMember } from 'typedjson'
import { BusinessObjectWithDataFile } from './business-object-with-data-file'
import type { ResourceAvailabilityOnClientType } from '@shared/types'

export const ResourceTypes = {
	'application/pdf': 'PDF document',
	'text/plain': 'Plain text',
	'text/css': '(S)CSS style sheet',
	'text/html': 'HTML document',
	'image/png': 'PNG image',
	'image/jpeg': 'JPG image',
	'image/gif': 'GIF image',
	'image/svg+xml': 'SVG image',
	'image/bmp': 'BMP image',
	'image/tiff': 'TIFF image',
	'application/zip': 'ZIP archive',
	'text/csv': 'CSV file',
	'application/vnd.ms-excel': 'MS Excel workbook',
	'application/msword': 'MS Word document',
	'text/xml': 'XML document',
	'video/mp4': 'MP4 video',
	'application/octet-stream': 'Other binary file'
} as const
export type ResourceType = keyof typeof ResourceTypes
export const ResourceAvailabilityOnClientTypes: Record<ResourceAvailabilityOnClientType, string> = {
	none: 'None (only usable on server-side)',
	downloadable: 'Can be downloaded (e.g. URL, file name are exposed)',
	builtin: 'Built into client app (use only when absolutely necessary as it increases the app size!)',
}

@jsonObject({ name: 'Resource' })
export class Resource extends BusinessObject implements BusinessObjectWithDataFile {
	@jsonMember(String) dataBase64: string = ''
	@jsonMember(String) resourceType?: ResourceType
	@jsonMember(String) overrideMimeType: string = ''
	@jsonMember(String) fileName: string = ''
	@jsonMember(String) availabilityOnClient: ResourceAvailabilityOnClientType = 'none'

	get textData() {
		return atob(this.dataBase64)
	}
	set textData(text: string) {
		this.dataBase64 = btoa(text)
	}
	
	constructor(init?: Partial<Resource>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	hasDataFile(): this is BusinessObjectWithDataFile {
		return true
	}

	getEffectiveMimeType() {
		return (this.overrideMimeType || this.resourceType) ?? ''
	}

	getClassNames(): string[] {
		return ['$Resources']
	}

	isDownloadable() {
		return this.availabilityOnClient == 'downloadable' || this.availabilityOnClient == 'builtin'
	}
}
