import { EntityMethod } from '@shared/bos/entity-method'

export type PureExecutionLocation = 'server' | 'client'
export type ExecutionLocation = PureExecutionLocation | 'serverAndClient'
export const ExecutionContextNames = ['server', 'client', 'serverAndClient', 'calculation', 'screenDisplay', 'screenAction', 'storeAction'] as const
export type ExecutionContextName = typeof ExecutionContextNames[number]
const ExecutonContextLocations: Record<ExecutionContextName, ExecutionLocation> = {
	server: 'server',
	client: 'client',
	serverAndClient: 'serverAndClient',
	calculation: 'serverAndClient',
	screenDisplay: 'client',
	screenAction: 'client',
	storeAction: 'client'
}

export const SysModuleNames: Record<ExecutionLocation, string> = {
	client: '@ng-shared/lib/frontend-sys',
	server: '@backend/system/backend-sys',
	serverAndClient: '@shared/system/common-sys'
}

export class ExecutionContext {
	constructor(
		public readonly contextName: ExecutionContextName
	) {}

	private isOneOf(contextNames: ExecutionContextName[] | '*') {
		if(contextNames == '*') return true

		return contextNames.includes(this.contextName)
	}
	
	private locationIsOneOf(locations: ExecutionLocation[]) {
		const location = this.getExecutionLocation()
		return locations.includes(location)
	}
	
	getExecutionLocation(): ExecutionLocation {
		return ExecutonContextLocations[this.contextName]
	}

	getSysModuleName(){
		return SysModuleNames[this.getExecutionLocation()]
	}

	isReadOnly() {
		return this.isOneOf(['calculation', 'screenDisplay'])
	}

	canRunOnServer() {
		return this.locationIsOneOf(['server', 'serverAndClient'])
	}

	canRunOnClient() {
		return this.locationIsOneOf(['client', 'serverAndClient'])
	}

	needsDataStoreDeclarations() {
		return this.isOneOf(['calculation', 'screenDisplay', 'screenAction', 'storeAction', 'client'])
	}

	needsServerDataStoreDeclarations() {
		return this.isOneOf(['server', 'client', 'serverAndClient', 'screenAction', 'storeAction'])
	}

	needsAppStateDeclaration() {
		return this.isOneOf(['screenDisplay', 'screenAction', 'storeAction'])
	}

	needsScreenStateDeclaration() {
		return this.isOneOf(['screenDisplay', 'screenAction'])
	}

	needsAsyncForClientCallableMethods() {
		return this.isOneOf(['client', 'serverAndClient', 'screenAction', 'storeAction'])
	}

	needsUserDefinedConfigurationDeclarations() {
		return this.isOneOf(['server', 'client', 'serverAndClient', 'screenAction', 'storeAction'])
	}

	isEntityMethodAvailable(method: EntityMethod) {
		switch(method.availability) {
			case 'calculation':
				return true

			case 'serverOnly':
				return this.isOneOf(['server'])

			case 'clientOnly':
				return this.isOneOf(['client', 'screenAction', 'screenDisplay', 'storeAction'])

			case 'serverAndClient':
				return this.isOneOf(['server', 'client', 'serverAndClient', 'screenAction', 'screenDisplay'])
				
			case 'callableFromClient':
				return this.isOneOf(['server', 'client', 'serverAndClient', 'screenAction', 'screenDisplay', 'storeAction'])
		}
	}

	needsStaticEntityDeclarations() {
		return true
	}
}

export const AllExecutionContexts = ExecutionContextNames.map(ecName => new ExecutionContext(ecName))