import { FormInputBlock } from './form-input-block';
import type { EventType, HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

export const SliderBlockTypeNames = {
	single: 'Single value',
	range: 'Range (from, to)',
} as const
export type SliderBlockType = keyof typeof SliderBlockTypeNames

@jsonObject({ name: 'SliderBlock' })
export class SliderBlock extends FormInputBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 1,
		label: 'Type',
		options: Object.entries(SliderBlockTypeNames),
		isBinding: false
	})
	type: SliderBlockType = 'single'

	@jsonMember(String)
	@ScreenEditorInput<SliderBlock>({
		inputType: 'code',
		order: 2,
		label: (blocks) => blocks[0].type == 'range' ? 'Binding from' :'Binding',
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: true,
		isMandatory: () => true,
	})
	bindingFromCode?: TsCodeType = ''
	
	@jsonMember(String)
	@ScreenEditorInput<SliderBlock>({
		inputType: 'code',
		order: 3,
		label: 'Binding to',
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: true,
		isVisible: (blocks) => blocks[0].type == 'range',
		isMandatory: () => true,
	})
	bindingToCode?: TsCodeType = ''
	
	@jsonMember(String)
	@ScreenEditorInput<SliderBlock>({
		inputType: 'code',
		order: 4,
		label: 'Minimum value',
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	minimumValueCode?: TsCodeType = '1'
	
	@jsonMember(String)
	@ScreenEditorInput<SliderBlock>({
		inputType: 'code',
		order: 5,
		label: 'Maximum value',
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	maximumValueCode?: TsCodeType = '10'
	
	@jsonMember(String)
	@ScreenEditorInput<SliderBlock>({
		inputType: 'code',
		order: 6,
		label: 'Step size',
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	stepSizeCode?: TsCodeType = '1'

	@jsonMember(Boolean)
	@ScreenEditorInput<SliderBlock>({
		inputType: 'checkbox',
		order: 7,
		label: 'Show tick marks',
	})
	showTicks: boolean = true
	
	constructor(init: Partial<SliderBlock> = {}) {
		super()
		this.exampleText = 'Some input'
		this.init(init)
	}

	get allowedEventTypes(): EventType[] {
		return [
			{ name: 'change' },
		]
	}

	producePreview(): HtmlType {
		return `<mat-slider><input matSliderThumb></mat-slider>`
	}

	getEditorCategory(): string {
		return 'Additional Form Fields'
	}
}
