import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface GenericPopupData {
	text?: string
	preformattedText?: string
	imgSrc?: string
}

@Component({
	selector: 'app-generic-popup',
	templateUrl: './generic-popup.component.html',
	styleUrls: ['./generic-popup.component.scss'],
})
export class GenericPopupComponent {
	constructor(
    	@Inject(MAT_DIALOG_DATA) public data: GenericPopupData,
	) {}
}
