import { LogicBlock } from './logic-block'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoVisitor } from '@shared/bos/bo-visitor'

@jsonObject({ name: 'DisableBlock' })
export class DisableBlock extends LogicBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Disabled',
		codeLanguage: 'ts',
		tsReturnType: () => 'any',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		tooltip: () => ({
			text: `Use $disabled to access current disabled state, to e.g.<ul>
				<li>conditionally disable if already disabled or age &lt;18: $disabled || person.age &lt; 18</li>
				<li>conditionally enable if age &gte;18: $disabled && person.age &lt; 18</li>
				</ul>`,
			acceptHtml: true,
		})		
	})
	disabledCode: TsCodeType = 'true'

	constructor(init: Partial<DisableBlock> = {}) {
		super()
		this.init(init)
	}

	getEditorTitle() {
		const trimmedCode = this.disabledCode.trim()
		if(trimmedCode == 'true') return 'Disable'
		if(trimmedCode == 'false') return 'Enable'
		return `Disable/Enable`
	}

	visitThisWithBoVisitor(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		this.visitCodePropertyWithBoVisitor(visitor, pathPrefix, DisableBlock, 'disabledCode')
	}

	getEditorCategory(): string {
		return 'Logic'
	}
}
