import type { HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoReference } from '@shared/bos/bo-reference'

export const ImageLocationTypes = {
	resource: 'Resource',
	url: 'URL',
	base64: 'Base64-encoded data',
}

@jsonObject({ name: 'ImageBlock' })
export class ImageBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 1,
		label: 'Image location',
		options: Object.entries(ImageLocationTypes),
	})
	locationType: keyof typeof ImageLocationTypes = 'resource'

	@jsonMember
	@ScreenEditorInput({
		inputType: 'boRef',
		order: 1,
		label: 'Resource',
		allowedBoTypes: ['Resource'],
		includeImportedModules: true,
		isVisible: blocks => (blocks[0] as ImageBlock).locationType == 'resource',
		isMandatory: () => true,
	})
	resourceRef: BoReference = new BoReference()

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'URL',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isVisible: blocks => (blocks[0] as ImageBlock).locationType == 'url',
		isMandatory: () => true,
	})
	urlCode: TsCodeType = ''

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Base64 data',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isVisible: blocks => (blocks[0] as ImageBlock).locationType == 'base64',
		isMandatory: () => true,
	})
	contentBase64Code: TsCodeType = ''

	constructor(init: Partial<ImageBlock> = {}) {
		super()
		this.exampleText = 'Image'
		this.init(init)
	}

	producePreview(): HtmlType {
		switch(this.locationType) {
			case 'resource': {
				return `<img src="/${this.resourceRef.moduleId}/${this.resourceRef.moduleId}/${this.resourceRef.boId}" style="max-width: 250px;">`
			}
		}
		return `<div style="background: #dddddd; border: 1px solid #bbbbbb; padding: 3em;">${this.transpileExpressionForHtml(this.exampleText)}</div>`
	}

	getEditorCategory(): string {
		return 'Core'
	}
}
