import { DataUtil } from '@shared/util/data-util'
import { TsTypeType } from './../../types';
import { jsonMember, jsonObject, jsonArrayMember, jsonMapMember } from 'typedjson';

export const EventHandlerTypes = {
	central: 'Centrally-managed action',
	adhoc: 'Ad-hoc action',
} as const
export type EventHandlerType = keyof typeof EventHandlerTypes

@jsonObject
export class EventHandler {
	@jsonMember(String)
	eventName!: string
	@jsonMember(String)
	eventPrefix?: string

	@jsonMember(String)
	description?: string

	@jsonMember(String)
	type: EventHandlerType = 'central'

	@jsonMember(String)
	actionName!: string

	@jsonMember(String)
	adHocCode?: string

	@jsonMapMember(String, String)
	parameterAssignments: Map<string, TsTypeType> = new Map()

	constructor(init?: Partial<EventHandler>) {
		DataUtil.assignCommonProperties(this, init)
	}
}