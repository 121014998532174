import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject } from 'typedjson'
import type { BusinessObjectTypeType, ModuleIdType, BusinessObjectIdType, OnlyData } from './../types';
import { BusinessObject } from './business-object'

@jsonObject
export class BoReference<BoType extends BusinessObjectTypeType = BusinessObjectTypeType> {
	@jsonMember(String)
	moduleId!: ModuleIdType
	@jsonMember(String)
	boId!: BusinessObjectIdType
	@jsonMember(String)
	boType!: BoType

	constructor(init?: OnlyData<BoReference>) {
		DataUtil.assignCommonProperties(this, init)
	}

	equals(other: any) {
		if(!(other instanceof BoReference)) return false
		return this.matches(other)
	}

	matches(other: { moduleId: ModuleIdType, boType: BusinessObjectTypeType, boId: BusinessObjectIdType } | BusinessObject) {
		if(other instanceof BusinessObject) {
			return this.moduleId == other.moduleId && this.boId == other.boId && this.boType == other.__type
		}
		return this.moduleId == other?.moduleId && this.boId == other?.boId && this.boType == other?.boType
	}

	isNonEmpty() {
		return this.moduleId && this.boId
	}
	
	getQualifiedName(subType?: string) {
		if(!this.isNonEmpty()) return ''
		return `${this.moduleId}.${this.boId}${subType ? `.${subType}` : ''}`
	}

	getQualifiedTypeAndName() {
		if(!this.isNonEmpty()) return ''
		return `${this.boType}:${this.moduleId}.${this.boId}`
	}

	isFullyDefined() {
		return this.moduleId && this.boId && this.boType
	}

	copyFrom(otherRef: BoReference<BoType>) {
		this.boType = otherRef.boType
		this.moduleId = otherRef.moduleId
		this.boId = otherRef.boId
	}

	forModule(contextModule: ModuleIdType | BusinessObject | BoReference) {
		if(this.moduleId != 'This') return this
		if(contextModule instanceof BusinessObject) contextModule = contextModule.moduleId
		if(contextModule instanceof BoReference) contextModule = contextModule.moduleId
		
		return new BoReference({
			...this,
			moduleId: contextModule
		})
	}

	static fromQualifiedName<BoType extends BusinessObjectTypeType>(qualifiedName: string, boType: BoType) {
		const parts = qualifiedName?.split('.') ?? []
		const moduleId = parts.splice(0, 1)[0]
		const boId = parts.join('.')
		
		return new this<BoType>({
			boType,
			moduleId,
			boId,
		})
	}

	static fromQualifiedTypeAndName(qualifiedTypeAndName: string) {
		const [boType, qualifiedName] = qualifiedTypeAndName?.split(':') ?? []
		return this.fromQualifiedName(qualifiedName, boType as BusinessObjectTypeType)
	}
}