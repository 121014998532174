import { DataUtil } from '@shared/util/data-util'
import { jsonObject, jsonMember, jsonArrayMember, jsonMapMember } from 'typedjson'
import { BoReference } from './bo-reference'

@jsonObject
export class ModuleImport extends BoReference {
	constructor(init?: Partial<ModuleImport>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}
}