export interface RateLimitingOptions<DontThrow extends boolean> {
	runMaxTasksConcurrently?: number
	waitForAllConcurrentTasksBeforeNextBatch?: boolean
	runMaxTasksWithinTimeframe?: {
		numberOfTasks: number
		numberOfSeconds: number
	}
	dontThrowOnError?: boolean
}

export class CommonRateLimitingBridge {
	static executeTasks<T>(tasks: (() => Promise<T>)[], options: RateLimitingOptions<true>): Promise<(Awaited<T> | null)[]>
	static executeTasks<T>(tasks: (() => Promise<T>)[], options: RateLimitingOptions<boolean>): Promise<Awaited<T>[]>
	static executeTasks<T>(tasks: (() => Promise<T>)[], options: RateLimitingOptions<boolean>): Promise<(Awaited<T> | null)[]> {
		if(options.runMaxTasksConcurrently == undefined) options.runMaxTasksConcurrently = tasks.length
		
		return new Promise((res, rej) => {
			const pendingTaskIndices = [...tasks.map((task, idx) => idx)]
			let runningTaskIndices: number[] = []
			const promises = new Array<Promise<T | null>>(tasks.length)
			const errors = new Array<Promise<T>>(tasks.length)
			let isRejected = false
			
			let timeframeStart: Date
			let countStartedInTimeframe: number
	
			function startNewTimeframe() {
				if(isRejected) return
				if(!pendingTaskIndices.length) return
				
				timeframeStart = new Date()
				countStartedInTimeframe = 0
	
				if(options.runMaxTasksWithinTimeframe) {
					setTimeout(() => startNewTimeframe(), options.runMaxTasksWithinTimeframe.numberOfSeconds * 1000)
				}
				scheduleNewTasks()
			}
	
			function scheduleNewTasks() {
				if(isRejected) return
				if(!runningTaskIndices.length && !pendingTaskIndices.length) {
					// all done; resolve results
					Promise.all(promises).then(res)
				}

				let countToRun = options.runMaxTasksConcurrently! - runningTaskIndices.length
				if(options.waitForAllConcurrentTasksBeforeNextBatch) {
					countToRun = runningTaskIndices.length ? 0 : options.runMaxTasksConcurrently!
				}
				if(options.runMaxTasksWithinTimeframe) {
					countToRun = Math.min(countToRun, options.runMaxTasksWithinTimeframe.numberOfTasks - countStartedInTimeframe)
				}
				countToRun = Math.min(countToRun, pendingTaskIndices.length)
	
				const indicesToRun = pendingTaskIndices.splice(0, countToRun)
	
				for(const idxToRun of indicesToRun) {
					promises[idxToRun] = tasks[idxToRun]().catch(err => {
						errors[idxToRun] = err
						if(!options.dontThrowOnError) {
							isRejected = true
							rej(err)
						}
						return null
					}).then(result => {
						runningTaskIndices = runningTaskIndices.filter(idx => idx != idxToRun)
						setTimeout(() => scheduleNewTasks())
						return result
					})
	
					runningTaskIndices.push(idxToRun)
					countStartedInTimeframe++
				}
			}
	
			startNewTimeframe()
		})
	}
}