import type { HtmlType, TsCodeType, EventType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { LeafBlock } from '../leaf-block'
import { Parameter } from '@shared/script/parameter'
import { ButtonTypes } from './button-block'
import type { ButtonType } from './button-block'
import { TextOrCode_assumeCode, TextOrExpression } from '@shared/data/text-or-code'

export const FileUploadDisplayStyles = {
	'dropZone': 'Drop zone',
	'button': 'Button / Floating Action Button',
} as const
export type FileUploadDisplayStyle = keyof typeof FileUploadDisplayStyles
export const FileUploadFabIcons = {
	'upload': 'Upload',
	'attach_file': 'Attach',
} as const
export type FileUploadIcon = keyof typeof FileUploadFabIcons

@jsonObject({ name: 'FileUploadBlock' })
export class FileUploadBlock extends LeafBlock {
	// @jsonMember(String)
	// @EditorGeneralInput({
	// 	inputType: 'code',
	// 	order: 2,
	// 	label: 'Upload session',
	// 	codeLanguage: 'ts',
	// 	tsReturnType: () => 'Sys.FileUploadSession',
	// 	editorSize: 'singleline',
	// 	isBinding: true,
	// 	isMandatory: () => true,
	// })
	// uploadSessionCode?: TsCodeType = ''
	
	@jsonMember
	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 1,
		label: 'Multiple files',
		isBinding: false
	})
	multiple: boolean = false

	@jsonMember(TextOrExpression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Allowed MIME types',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false
	})
	allowedMimeTypes = new TextOrExpression('*', 'T')
	
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 3,
		label: 'Show drop zone',
		options: Object.entries(FileUploadDisplayStyles),
	})
	displayStyle: FileUploadDisplayStyle = 'dropZone'

	@jsonMember(TextOrExpression)
	@ScreenEditorInput<FileUploadBlock>({
		inputType: 'code',
		order: 4,
		label: 'Text',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		alternativeContentAreaName: 'content'
	})
	textCode = new TextOrExpression('Drop files here to upload', 'T')

	@jsonMember
	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 5,
		label: 'Upload automatically',
		isBinding: false
	})
	uploadAutomatically: boolean = false

		
	@jsonMember(String)
	@ScreenEditorInput<FileUploadBlock>({
		inputType: 'dropdown',
		order: 6,
		label: 'Icon',
		options: Object.entries(FileUploadFabIcons),
		isVisible: blocks => blocks[0].displayStyle != 'dropZone' && !blocks[0].getContentArea('content'),
	})
	icon: FileUploadIcon = 'upload'

	@jsonMember(String)
	@ScreenEditorInput<FileUploadBlock>({
		inputType: 'dropdown',
		order: 7,
		label: 'Button appearance',
		tab: 'Appearance',
		options: Object.entries(ButtonTypes),
		isVisible: blocks => blocks[0].displayStyle != 'dropZone' && !blocks[0].getContentArea('content'),
	})
	buttonType: ButtonType = 'mat-raised-button'


	constructor(init: Partial<FileUploadBlock> = {}) {
		super()
		this.exampleText = 'Some upload'
		this.init(init)
	}

	get allowedEventTypes(): EventType[] {
		return [
			{ name: 'filesSelected', parameters: [new Parameter({ name: 'files', type: 'File[]', defaultExpression: '[...$event]' })] },
			{ name: 'uploadStarted', parameters: [new Parameter({ name: 'uploads', type: 'Sys.FileUpload[]', defaultExpression: '$event' })] },
			{ name: 'uploadFinished', parameters: [new Parameter({ name: 'upload', type: 'Sys.FileUpload', defaultExpression: '$event' })] },
		]
	}

	producePreview(): HtmlType {
		return `<input tabindex="-1" type="file" value="${this.calculateExampleText()}">`
	}

	getEditorCategory(): string {
		return 'Additional Form Fields'
	}

	canHaveColor(): boolean {
		return this.displayStyle == 'button'
	}
}
