<mat-form-field class="fill">
	<mat-label [innerHtml]="label"></mat-label>
	<input #input matInput
		[value]="text"
		(keyup)="onKeyUp($event)"
		[disabled]="formBinding.formControl.disabled"
		[matAutocomplete]="auto"
	>
	<button *ngIf="allowClearing"
		[disabled]="formBinding.formControl.disabled || !value"
		matSuffix mat-icon-button mat-small-icon-button aria-label="Clear" (click)="onSelected(null); text = ''">
		<mat-icon>close</mat-icon>
	</button>

	<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith" (optionSelected)="onSelected($event.option.value)">
		<mat-option *ngFor="let $option of list; trackBy: trackBy" [value]="$option">{{ displayWith($option) }}</mat-option>
	</mat-autocomplete>
</mat-form-field>