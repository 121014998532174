import { BoReference } from '@shared/bos/bo-reference'
import { ModuleImport } from './module-import'
import { BusinessObject } from './business-object'
import { ScreenRoute } from './screen-route'
import type { ModuleIdType, ModuleBundleType, BusinessObjectIdType } from './../types'
import {
	jsonObject,
	jsonMember,
	jsonArrayMember,
	jsonMapMember,
} from 'typedjson'
import { BoVisitor } from './bo-visitor'
import { createSetNotifyProxy } from '@shared/util/create-set-notify-proxy'
import { DataUtil } from '@shared/util/data-util'

@jsonObject({ name: 'Module' })
export class Module extends BusinessObject {
	@jsonMember(String) bundle: ModuleBundleType = ''
	@jsonMember(String) name: string = ''
	@jsonMember(Boolean) isAppModule: boolean = true
	@jsonMember(String) tenantId?: string
	@jsonArrayMember(String) includeLanguages: string[] = ['English']
	@jsonMember(String) defaultLanguage: string = 'English'
	@jsonArrayMember(BoReference) includeStyles: BoReference[] = []

	@jsonMember defaultScreen: BoReference = new BoReference()
	@jsonMember defaultTheme: BoReference = new BoReference()
	@jsonMember appIcon: BoReference = new BoReference()
	@jsonArrayMember(ScreenRoute) exposedScreenRoutes: ScreenRoute[] = []
	@jsonArrayMember(ModuleImport) moduleImports: ModuleImport[] = [
		new ModuleImport({ moduleId: 'System', boId: 'System', boType: 'Module' }),
		new ModuleImport({ moduleId: 'Common', boId: 'Common', boType: 'Module' }),
	]
	@jsonMember(Boolean) isSystemModule: boolean = false
	@jsonMapMember(String, String) systemImports: Map<
		string,
		string
	> = new Map()

	constructor(init?: Partial<Module>) {
		super()
		DataUtil.assignCommonProperties(this, init)
		// this.type = 'Module'
		// this.moduleId = ModulesRootId
		this.moduleId = this.boId
	}

	hasUi() {
		return this.exposedScreenRoutes.length > 0
	}

	visit(visitor: BoVisitor): void {
		this.moduleImports.forEach((mi, idx) => visitor.visitBoReference(mi, ['moduleImports', idx]))
		if(this.defaultScreen) visitor.visitBoReference(this.defaultScreen, ['defaultScreen'])
		this.includeStyles.forEach((style, idx) => visitor.visitBoReference(style, ['includeStyles', idx]))
		this.exposedScreenRoutes.forEach((route, idx) => visitor.visitBoReference(route, ['exposedScreenRoutes', idx]))
		this.includeLanguages.forEach((languageId, idx) => visitor.visitBoReference(createSetNotifyProxy(
			new BoReference({
				moduleId: 'Common',
				boType: 'Language',
				boId: languageId,
			}), {
				boId: (newId: BusinessObjectIdType) => this.includeLanguages[idx] = newId
			}
		), ['includeLanguages', idx]))
	}
}
