import { AfterContentInit, ContentChildren, Directive, Input, QueryList } from '@angular/core'
import { MatTabGroup } from '@angular/material/tabs'
import { TabGroupTabControlDirective } from './tab-group-tab-control-directive'

@Directive({
	selector: 'mat-tab-group',
	exportAs: 'lowgileTabGroupControl',
})
export class TabGroupControlDirective implements AfterContentInit {
	afterContentInitCallback?: () => void
	@ContentChildren(TabGroupTabControlDirective) tabs!: QueryList<TabGroupTabControlDirective>
	constructor(
		private matTabGroup: MatTabGroup,
	) {}

	@Input()
	set lowgileSelectedTab(nameOrIdx: string | number) {
		if(typeof nameOrIdx == 'string') {
			if(!this.tabs) {
				// this.tabs not available before ngAfterCodeInit, therefore set it later
				this.afterContentInitCallback = () => this.lowgileSelectedTab = nameOrIdx
				return
			}
			const tabIdx = [...this.tabs].findIndex(tab => tab.lowgileTabName == nameOrIdx)
			if(tabIdx >= 0) {
				this.matTabGroup.selectedIndex = tabIdx
			}
		} else {
			this.matTabGroup.selectedIndex = nameOrIdx
		}
	}

	ngAfterContentInit(): void {
		this.afterContentInitCallback?.()
		this.afterContentInitCallback = undefined
	}

	getTabNameFromIndex(tabIdx: number) {
		const tab = [...this.tabs][tabIdx]
		return tab?.lowgileTabName
	}
}