import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'Screen/:branchName/:moduleId/:boId', loadComponent: () => import('./screen-editor/screen-editor.component').then(m => m.ScreenEditorComponent) },
  { path: 'Entity', loadChildren: () => import('./entity-editor/entity-editor.module').then(m => m.EntityEditorModule) },
  { path: 'StaticEntity', loadChildren: () => import('./static-entity-editor/static-entity-editor.module').then(m => m.StaticEntityEditorModule) },
  { path: 'ServerDataStore', loadChildren: () => import('./server-data-store-editor/server-data-store-editor.module').then(m => m.ServerDataStoreEditorModule) },
  { path: 'Label', loadChildren: () => import('./label-editor/label-editor.module').then(m => m.LabelEditorModule) },
  { path: 'DataStore', loadChildren: () => import('./data-store-editor/data-store-editor.module').then(m => m.DataStoreEditorModule) },
  { path: 'ConfigurationItem', loadChildren: () => import('./configuration-item-editor/configuration-item-editor.module').then(m => m.ConfigurationItemEditorModule) },
  { path: 'Resource', loadChildren: () => import('./resource-editor/resource-editor.module').then(m => m.ResourceEditorModule) },
  { path: 'Role', loadChildren: () => import('./role-editor/role-editor.module').then(m => m.RoleEditorModule) },
  { path: 'Permission', loadChildren: () => import('./permission-editor/permission-editor.module').then(m => m.PermissionEditorModule) },
  { path: 'Module', loadChildren: () => import('./module-editor/module-editor.module').then(m => m.ModuleEditorModule) },
  { path: 'AccessControl', loadChildren: () => import('./access-control-editor/access-control-editor.module').then(m => m.AccessControlEditorModule) },
  { path: 'DataType', loadChildren: () => import('./data-type-editor/data-type-editor.module').then(m => m.DataTypeEditorModule) },
  { path: 'Process/:branchName/:moduleId/:boId', loadComponent: () => import('./process-editor/process-editor.component').then(m => m.ProcessEditorComponent) },
  { path: 'Trigger/:branchName/:moduleId/:boId', loadComponent: () => import('./trigger-editor/trigger-editor.component').then(m => m.TriggerEditorComponent) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EditRoutingModule {}
