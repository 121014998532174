<mat-card [class]="cardClasses">
	<mat-card-header [style.flex]="'0 0 auto'" [class]="headerClasses">
		<mat-card-title>
			<ng-content select="[title]"></ng-content>
		</mat-card-title>
		<ng-content select="[actions]"></ng-content>
	</mat-card-header>
	<mat-card-content [class]="contentClasses">
		<ng-content select=":not([title]):not([actions])"></ng-content>
	</mat-card-content>
</mat-card>
