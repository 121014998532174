import { Entity } from '@shared/bos';
import { Store } from './../store/store';
import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { TypeReference } from '@shared/data/type-reference'
import { BoVisitor } from './bo-visitor'
import { DataUtil } from '@shared/util/data-util'

@jsonObject({ name: 'DataStore' })
export class DataStore extends BusinessObject {
	@jsonMember
	store: Store = new Store()
	@jsonMember(String)
	propertyName?: string
	
	constructor(init?: Partial<DataStore>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getDependencies(allBos: BusinessObject[]): BusinessObject[] {
		let types = new Set(
			this.store.properties.map(p => new TypeReference(p.type).getQualifiedSimpleTypeName())
		)

		const dependencyBos = allBos.reduce((list: BusinessObject[], bo) => {
			if(!(bo instanceof Entity)) return list
			if(!types.has(bo.boId)) return list
			return [...list, bo]
		}, [])
				
		return dependencyBos
	}

	getPropertyName() {
		return this.propertyName ?? this.boId
	}

	visit(visitor: BoVisitor): void {
		this.store.visit(visitor, ['store'])
	}
}
