import { NameAndType } from '@shared/types'
import { DataUtil } from '@shared/util/data-util'
import { Parameter } from './../../script/parameter';

export class RestrictableItem {
	itemName?: string
	parameters: Parameter[] = []
	generics: string[] = []
	contextDeclarations: NameAndType[] = []
	contextImports: Record<string, string> = {} // key: named import, value: module specifier
	functionName?: string
	defaultCode?: string

	constructor(init?: Partial<RestrictableItem>) {
		DataUtil.assignCommonProperties(this, init)
	}
}