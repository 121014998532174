import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject } from 'typedjson'
import { Actionable } from './actionable'

export const ProcedureTypes = [
	['procedure', 'Stored Procedure'],
	['function', 'SQL Function'],
] as const
export type ProcedureType = typeof ProcedureTypes[number][0]
export const DbToTsType = {
	int: 'number',
	varchar: 'string',
	bool: 'boolean',
} as const
export type InputType = keyof typeof DbToTsType

@jsonObject
export class ServerDataStoreProcedure extends Actionable {
	@jsonMember(String)
	type: ProcedureType = 'procedure'

	constructor(init?: Partial<ServerDataStoreProcedure>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}
}