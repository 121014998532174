import { DataUtil } from '@shared/util/data-util';
import { BoReference } from '@shared/bos/bo-reference';
import { Block } from '@shared/blocks/block';

export class FormElement {
	getBlocks?(): Block[] { return [] }
}

export class FormControlElement extends FormElement {
	variable?: string
	boRef?: BoReference
	bindingPropertyName?: string
	block!: Block
	parentBlock?: Block
	mandatory?: boolean | 'inherit' | 'custom' = 'inherit'
	maxLength?: number = 0

	constructor(init: FormControlElement) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getBlocks?(): Block[] {
		return [this.block]
	}
}

export class FormOtherBlocksElement extends FormElement {
	blocks: Block[] = []

	constructor(init: FormOtherBlocksElement) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getBlocks?(): Block[] {
		return this.blocks
	}
}
