import { Parameter } from '@shared/script/parameter';
import { Observable } from 'rxjs';
import { ExecutionContext } from '@shared/script/execution-context';
import { DataUtil } from './data-util';
import { DeclarationsContainer, ModuleIdType } from '../types';
import { ExecutionContextName } from '../script/execution-context';
import { map, tap, shareReplay } from 'rxjs/operators'
export type RawTypeDeclarationsType = Partial<Record<ExecutionContextName, Record<string, Record<string, DeclarationsContainer>>>>

export class DeclarationUtil {
	static calculateTypeDeclarations(rawTypeDeclarations: RawTypeDeclarationsType) {
		const writer = DataUtil.createCodeBlockWriter()
		for(const executionContextName in rawTypeDeclarations) {
			// console.log(executionContextName)
			const moduleDeclarations = rawTypeDeclarations[executionContextName as ExecutionContextName]
			
			writer.write(`declare module '${executionContextName}'`).block(() => {
				// First loop: write imports for all modules
				const imports: Record<string, string> = {}
				for(const moduleId in moduleDeclarations) {
					const boDeclarationsMap = moduleDeclarations[moduleId];
					for(const declContainer of Object.values(boDeclarationsMap)) {
						Object.assign(imports, declContainer.imports)
					}
				}
				for(const importObj of Object.entries(imports)) {
					if(importObj[0]?.startsWith('*')) {
						writer.writeLine(`import ${importObj[0]} from '${importObj[1]}'`)
					} else {
						writer.writeLine(`import { ${importObj[0]} } from '${importObj[1]}'`)
					}
				}

				// Second loop: write module declarations
				for(const moduleId in moduleDeclarations) {
					const boDeclarationsMap = moduleDeclarations[moduleId];
					const writeDeclarations = (isForNamespace: boolean) => {
						[...Object.values(boDeclarationsMap)].forEach(declarationsContainer => {
							if(declarationsContainer.doEncloseInNamespace == isForNamespace) {
								declarationsContainer.declarations.forEach(declaration => {
									declaration = declaration.replace(/\bThis\./g, `${moduleId}.`)
									writer.writeLine(declaration)
								})
							}
						})
					}
				
					writer.write(`export namespace ${moduleId}`).block(() => {
						writer.writeLine(`import This = ${moduleId}`)
						writeDeclarations(true)
					})
					writeDeclarations(false)
				}
			})
		}
		
		return writer.toString()
	}

	static getWrapperImports$(executionContext: ExecutionContext, rawTypeDeclarations$: Observable<RawTypeDeclarationsType>, thisModuleId: ModuleIdType) {
		return rawTypeDeclarations$.pipe(
		  map(rawTypeDeclarations => {
			if(!executionContext) return ''
			
			const moduleIds = Object.keys(rawTypeDeclarations?.[executionContext.contextName] ?? {})
			const importItems = [
			  ...moduleIds,
			]

			if(thisModuleId) {
				importItems.push(`${thisModuleId} as This`)
			}
		
			const imports = [
				`import { ${importItems.join(', ')} } from '${executionContext.contextName}'`,
				`import { IdType, DeepReadonly } from '@shared/types'`,
			]
			
			const sysModuleName = executionContext.getSysModuleName()
			if(sysModuleName) imports.push(
				`import * as Sys from '${sysModuleName}'`
				)
				
			if(executionContext.needsAppStateDeclaration()) {
				imports.push('declare const app: This.$AppState')
			}
			if(executionContext.needsScreenStateDeclaration()) {
				imports.push('declare const screen: This.$ScreenState')
			}
			
			return imports.join('; ')
		  }),
		  shareReplay(1),
		)
	  }

	static async declarationFromData(data: any): Promise<string> {
		if(data == null) return 'any'
		if(data instanceof Array) return `${await this.declarationFromData(data[0])}[]`
		if(data instanceof Promise) return `Promise<${await this.declarationFromData(await data)}>`
		if(data instanceof Date) return 'Date'
		
		if(typeof data == 'object') {
			const props = await Promise.all(Object.keys(data).map(async key => `"${key}": ${await this.declarationFromData(data[key])}`))
			return `{ ${props.join(', ')} }`
		}

		return typeof data
	}
}