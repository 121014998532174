import { ProxyUtil } from '@shared/util/proxy-util'

export type PathType = (string | number)[]
export type ValueType = object

export class PathOccurenceMap {
	private valueMap = new Map<string, ValueType>()
	private pathsMap = new Map<ValueType, PathType[]>()

	set(path: PathType, value: ValueType) {
		const strPath = this.getStringPath(path)

		const oldValue = this.getValueAtPath(path)
		if(oldValue === value) return
		if(oldValue !== undefined) {
			let oldValuePaths = this.getPathsForValue(oldValue)
			if(oldValuePaths) {
				oldValuePaths = oldValuePaths.filter(p => this.getStringPath(p) != strPath)
				this.pathsMap.set(oldValue, oldValuePaths)
			}
		}

		this.valueMap.set(strPath, value)
		this.pathsMap.set(this.unwrapValue(value), [...(this.pathsMap.get(value) ?? []), path])
	}

	getValueAtPath(path: PathType) {
		const strPath = this.getStringPath(path)
		return this.valueMap.get(strPath)
	}

	getPathsForValue(value: ValueType, omitPath?: PathType) {
		value = this.unwrapValue(value)

		let paths = this.pathsMap.get(value) ?? []
		// if(omitPath != undefined) {
		// 	const omitStrPath = this.getStringPath(omitPath)
		// 	paths = paths.filter(p => this.getStringPath(p) != omitStrPath)
		// }

		return paths
	}

	hasPath(path: PathType) {
		const strPath = this.getStringPath(path)
		return this.valueMap.has(strPath)
	}

	hasValue(value: ValueType) {
		value = this.unwrapValue(value)
		return this.pathsMap.has(value)
	}

	deletePath(path: PathType) {
		const strPath = this.getStringPath(path)
		const value = this.valueMap.get(strPath)!
		const paths = this.pathsMap.get(value) ?? []

		if(paths.length > 1) {
			this.pathsMap.set(value, paths.filter(p => this.getStringPath(p) != strPath))
		} else if(paths.length == 0) {
			this.pathsMap.delete(value)
		}
	}

	deleteValue(value: ValueType) {
		value = this.unwrapValue(value)
		this.pathsMap.delete(value)
		
		const paths = this.pathsMap.get(value) ?? []
		for(const path of paths) {
			const strPath = this.getStringPath(path)
			this.valueMap.delete(strPath)
		}
	}

	clear() {
		this.pathsMap.clear()
		this.valueMap.clear()
	}

	private getStringPath(path: PathType) {
		return path.join('.')
	}

	private unwrapValue(value: ValueType) {
		return ProxyUtil.getTarget(value) as ValueType
	}
}