import { TranslationService } from '@ng-shared/lib/services/translation.service';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { OnInit, OnDestroy, Component, Optional, Inject, ChangeDetectorRef, Input, Renderer2, NgZone, ElementRef, ContentChildren, TemplateRef, QueryList, ContentChild, inject } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { TrackByBasis } from '@shared/util/track-by-basis'
import { BreakpointObserver } from '@angular/cdk/layout'
import { Breakpoints } from '@angular/cdk/layout'
import { ChangeDetectionService } from '@ng-shared/lib/services/change-detection.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FormControlCache } from '../store/from-control-cache'
import { StoreService } from '../store/store.service'
import { BusinessObjectIdType, ModuleIdType, ScreenQualifiedNameAttribute, ValidationRule } from '@shared/types';
import { FrontendLanguageBridge } from '../bridges/frontend-language-bridge';
import { LayoutService } from '../services/layout-service'
import { FormGroup } from '@angular/forms'
import { Store } from '../store/store';
import { AppContext } from '../app-context'
import { AppTrpcService } from '../services/app-trpc.service'
import { ProcessService } from '../services/process.service'

@Component({
    template: '',
})
export abstract class AbstractScreen extends TrackByBasis implements OnInit, OnDestroy {
	@Input() @Optional() $forms: FormGroup[] = []
	@Input() @Optional() $disabled: boolean = false
	@ContentChild(TemplateRef, { read: TemplateRef, static: true }) template: TemplateRef<any>
	@ContentChildren(TemplateRef) contentTemplates: QueryList<TemplateRef<any>>
	private contentTemplateCache = new Map<string, TemplateRef<any>>()

	Object = Object
	JSON = JSON
	Math = Math
	navigator = navigator
	document = document

	$AppContext = AppContext
	subscriptions: Subscription[] = []
	onDestroyFns: (() => void)[] = []
	app: any
	$output: any

	componentStore?: Store<any> = undefined
	get screen() { return this.componentStore?.getState() }
	abstract get $inputs(): any
	$validators!: Record<number, ValidationRule<any>[]>

	$qualifiedScreenName = ''

	protected dialogData = inject(MAT_DIALOG_DATA, { optional: true })
	protected dialogRef = inject(MatDialogRef, { optional: true })
	protected storeService = inject(StoreService)
	protected route = inject(ActivatedRoute)
	protected router = inject(Router)
	protected breakpointObserver = inject(BreakpointObserver)
	protected translationService = inject(TranslationService)
	protected cdService = inject(ChangeDetectionService)
	protected cdRef = inject(ChangeDetectorRef)
	protected layoutService = inject(LayoutService)
	protected renderer = inject(Renderer2)
	protected zone = inject(NgZone)
	protected elementRef: ElementRef<HTMLElement> = inject(ElementRef)
	protected processService = inject(ProcessService)

	isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
		map(result => result.matches),
		shareReplay(),
	)

	ngOnInit() {
		const globalAny = globalThis as any
		if(!globalAny.$screen) Object.defineProperty(globalAny, '$screen', { get: () => this.componentStore?.stateInternal })
		if(!globalAny.$app) Object.defineProperty(globalAny, '$app', { get: () => this.app })
		if(!globalAny.$output) Object.defineProperty(globalAny, '$output', { get: () => this.$output })
		if(!globalAny.$api) Object.defineProperty(globalAny, '$api', { get: () => AppContext.injector.get(AppTrpcService) })
		this.subscriptions.push(
			FrontendLanguageBridge.languageChanged$.subscribe(() => this.cdRef.detectChanges()),
			this.cdService.markViewDirty$.subscribe(() => {
				this.cdRef.markForCheck()
			})
		)
		this.elementRef.nativeElement.setAttribute(ScreenQualifiedNameAttribute, this.$qualifiedScreenName)
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => sub.unsubscribe())
		this.onDestroyFns.forEach(fn => fn())
	}

	getFormControl(obj: object, propName: string) {
		// return Reflect.get(obj, `__fc__${propName}`)
		return FormControlCache.getFormControl(
			this.getCorrectBindingObject(obj, propName),
			this.getCorrectBindingProperty(obj, propName)
		).formControl
	}

	private getAlternativeBindingPropertyOnObject(value: any) {
        if(value && typeof value == 'object' && !(value instanceof Date)) return 'id' // in case of StaticEntity
		return undefined
	}

	getCorrectBindingObject(bindingObject: object, bindingProperty: string) {
        const value = Reflect.get(bindingObject, bindingProperty)
        const alternativeBindingProperty = this.getAlternativeBindingPropertyOnObject(value)
		// if(value && typeof value == 'object' && !(value instanceof Date)) return value // in case of StaticEntity
        return alternativeBindingProperty ? value : bindingObject
    }

    getCorrectBindingProperty(bindingObject: object, bindingProperty: string) {
        const value = Reflect.get(bindingObject, bindingProperty)
        const alternativeBindingProperty = this.getAlternativeBindingPropertyOnObject(value)
        // if(value && typeof value == 'object') return 'id' // in case of StaticEntity
        return alternativeBindingProperty || bindingProperty
    }

	getContentTemplate(name: string) {
		// console.log('getContentTemplate', name, this.contentTemplates?.length)
		if(!this.contentTemplateCache.has(name) && this.contentTemplates) {
			const templateRef = [...this.contentTemplates].find(templateRef => {
				const templateAttrs = (templateRef as any)._declarationTContainer?.attrs as string[]
				const contentAreaKeyIdx = templateAttrs.indexOf('data-content-area')
				if(contentAreaKeyIdx > -1) {
					const templateName = templateAttrs[contentAreaKeyIdx + 1]
					if(templateName == name) return true
				}

				return false
			})
			if(templateRef) {
				this.contentTemplateCache.set(name, templateRef)
			}
		}
		return this.contentTemplateCache.get(name)
	}

	$log(...args: any[]) {
		console.log(...args)
		return args.map(arg => arg?.toString())
	}

	$navigate(moduleId: ModuleIdType, screenId: BusinessObjectIdType, queryParams: object) {
		const screenName = AppContext.appModuleId == moduleId ? screenId : `${moduleId}.${screenId}`
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => { // first fake navigation to "/"" to force Angular router to refresh
			this.router.navigate([screenName], {
				queryParams,
			})
		})
	}

	$getNavigationUrl(moduleId: ModuleIdType, screenId: BusinessObjectIdType, queryParams: object) {
		const screenName = AppContext.appModuleId == moduleId ? screenId : `${moduleId}.${screenId}`
		const params = Object.entries(queryParams).map(e => `${e[0]}=${encodeURI(e[1])}`).join('&')
		return params ? `${screenName}?${params}` : screenName
	}

	$isLinkPointingToCurrentScreen(moduleId: ModuleIdType, screenId: BusinessObjectIdType) {
		let [_, appModuleId, screenName] = window.location.pathname.split('/')
		if(screenName && !screenName.includes('.')) {
			screenName = `${appModuleId}.${screenName}`
		} else if(!screenName) {
			const defaultScreen = AppContext.defaultScreen.join('.')
			screenName = AppContext.defaultScreen.join('.')
		}

		return screenName == `${moduleId}.${screenId}`
	}

	$dispatchInputEvent(element: HTMLElement) {
		const event = new KeyboardEvent('input', { bubbles: true })
		element.dispatchEvent(event)
	}

	$dispatchGenericEvent(element: HTMLElement, eventName: string) {
		const event = new Event(eventName, { bubbles: true })
		element.dispatchEvent(event)
	}
}