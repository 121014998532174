import { FormInputBlock } from './form-input-block';
import type { HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { Expression, TextOrExpression } from '@shared/data/text-or-code'

@jsonObject({ name: 'StaticEntityBlock' })
export class StaticEntityBlock extends FormInputBlock {
	@jsonMember(TextOrExpression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
	})
	labelCode = new TextOrExpression('', 'T')

	@jsonMember(Expression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Binding',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number | Sys.Types.StaticEntityInstance',
		editorSize: 'singleline',
		isBinding: true,
		isMandatory: () => true,
	})
	bindingCode = new Expression()

	@jsonMember(Expression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Options list',
		codeLanguage: 'ts',
		tsReturnType: () => 'Sys.HasDropdownOptions | Sys.DropdownOptions',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	optionListCode = new Expression()

	@jsonMember(Expression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 4,
		label: 'Option value',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		additionalLines: (blocks: StaticEntityBlock[]) => [`const $options = (${blocks[0].optionListCode.content})`.replace(/\n/g, ' ')],
		additionalVariables: () => ({ $option: `typeof $options[number]`}),
		tooltip: () => ({
			text: 'Use <code>$option</code> to access each item of the options list',
			acceptHtml: true,
		}),
	})
	optionValueCode = new Expression('$option.id')

	@jsonMember(Expression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 5,
		label: 'Option label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		additionalLines: (blocks: StaticEntityBlock[]) => [`const $options = (${blocks[0].optionListCode.content})`.replace(/\n/g, ' ')],
		additionalVariables: () => ({ $option: `typeof $options[number]`}),
		tooltip: () => ({
			text: 'Use <code>$option</code> to access each item of the options list',
			acceptHtml: true,
		}),
	})
	optionLabelCode = new Expression('$option.label')

	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 10,
		label: 'Allow clearing value',
	})
	@jsonMember(Boolean) allowClearing: boolean = false
}
