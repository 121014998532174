import { JsonMappable } from './../data/json-mappable'
import type { RoutePathType, ModuleIdType, BusinessObjectIdType, TsCodeType } from './../types'
import { jsonObject, jsonMember, jsonArrayMember, jsonMapMember } from 'typedjson'
import { BoReference } from './bo-reference'
import { DataUtil } from '@shared/util/data-util'

@jsonObject
export class ScreenRoute extends BoReference {
	@jsonMember(String)
	path?: RoutePathType

	constructor(init?: Partial<ScreenRoute>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getRouteTsDefinition(): TsCodeType {
		if(this.path == '**') {
			return `{ path: '${this.path}', component: ${this.moduleId}__${this.boId} }`
		}
		return `{ path: '${this.path}', children: [{ path: '**', component: ${this.moduleId}__${this.boId} }] }`
	}
}
