import { ValidatorCodeFactory } from './validator-code-factory'
import { StandardValidators } from './standard-validators'
import { FormValidationRule } from '../blocks/form/form-validation-rule'

export class ValidationManager {
	private static factories: Record<string, ValidatorCodeFactory> = {}
	private static isInitialized = false

	private constructor() {}

	static init() {
		if(this.isInitialized) return
		this.isInitialized = true

		const validators = Object.values(StandardValidators)
		for(const validator of validators) {
			this.register(validator)
		}
	}

	static register(validator: ValidatorCodeFactory) {	
		this.factories[validator.validationType] = validator
	}

	static unregister(idOrValidator: string | ValidatorCodeFactory) {
		if(typeof idOrValidator == 'object') idOrValidator = idOrValidator.validationType
		delete this.factories[idOrValidator]
	}

	static get(validationType: string) {
		return this.factories[validationType]
	}

	static createCode(validationRule: FormValidationRule) {
		return this.factories[validationRule.validationType]?.createCode(validationRule.configCode, validationRule.messageCode) ?? 'return undefined'
	}

	static getRegisteredForDropdown(): [string, string][] {
		return Object.values(this.factories).map(f => [f.validationType, f.name])
	}
}