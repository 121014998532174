import { FlexibleConnectedPositionStrategy, Overlay } from '@angular/cdk/overlay'
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lowgile-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @Input() vPosition: 'above' | 'below' = 'above'
  @Input() hPosition: 'left' | 'right' = 'right'
  @Input() width: 'small' | 'normal' | 'large' = 'normal'

  private closeTimeout: any
  isOpen = false

  constructor() { }

  ngOnInit(): void {
  }

  open() {
    this.clearCloseTimeout()
    this.isOpen = true
  }

  close() {
    this.clearCloseTimeout()
    this.closeTimeout = setTimeout(() => {
      this.isOpen = false
    }, 300)
  }

  private clearCloseTimeout() {
    if(this.closeTimeout) {
      clearTimeout(this.closeTimeout)
      this.closeTimeout = 0
    }
  }
}
