import { isDraft, original } from 'immer'

export const ProxyTargetSymbol = Symbol('ProxyTargetSymbol')

export class ProxyUtil {
	static getTarget(obj: any): any {
		if(!obj) return obj
		
		let maxDepth = 100
		while(maxDepth--) {
			if(obj && isDraft(obj)) obj = original(obj)
			if(obj[ProxyTargetSymbol]) {
				obj = obj[ProxyTargetSymbol]
			} else {
				return obj
			}
		}

		console.error('ProxyUtil has reached maxDepth trying to get target object')
	}
}