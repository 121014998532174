import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { MainLayoutSidebarBlock } from './main-layout-sidebar-block'
import { ContentAreaBlock } from './content-area-block'

@jsonObject({ name: 'MainLayoutBlock' })
export class MainLayoutBlock extends Block {
	readonly allowedChildTypes = [
		MainLayoutSidebarBlock,
		ContentAreaBlock
	]

	constructor(init: Partial<MainLayoutBlock> = {}) {
		super()
		this.init(init)
	}

	static createNewForEditor(): Block {
		return new this({
			children: [
				MainLayoutSidebarBlock.createNewForEditor(),
				new ContentAreaBlock({ name: 'toolbar' }),
				new ContentAreaBlock({ name: 'main' }),
			]
		})
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'black'
	}

	getTitleColor(): string {
		return 'white'
	}

	// getPreviewDirection() {
	// 	return 'row' as const
	// }

	getEditorCategory(): string {
		return 'Screen Structure'
	}
}
