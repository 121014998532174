import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop'
import { EMPTY, map, of, shareReplay, switchMap } from 'rxjs'
import { AppTrpcServiceInjectionToken } from './app-trpc.service'
import { AppContext } from '../app-context'
import { BaseJsonMapper } from '@shared/data/base-json-mapper';
import { UserTaskTokenDetails } from '@shared/app-types';

@Injectable({
	providedIn: 'root'
})
export class ProcessService {

	tokenId = signal<string | null>(null)
	taskDetails$ = toObservable(this.tokenId).pipe(
		switchMap(tokenId => {
			if(!tokenId) return of(undefined)
			const trpc = AppContext.injector.get(AppTrpcServiceInjectionToken)
			return trpc.queryAsObservable(c => c.process.getTaskDetailsById.query({ tokenId: tokenId }))
		}),
		map(taskDetails => new BaseJsonMapper().readFromObject(taskDetails!, UserTaskTokenDetails)),
		shareReplay(1),
	)
	taskDetails = toSignal(this.taskDetails$)
	isProcessTask = computed(() => !!this.tokenId())
	isUnassignedTask = computed(() => !!this.taskDetails() && !this.taskDetails()!.assignedToUserId)
	isAssignedTask = computed(() => !!this.taskDetails() && !!this.taskDetails()!.assignedToUserId)
}