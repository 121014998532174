import { ValidatorCodeFactory } from './validator-code-factory'

export const requiredValidatorDefaultMessageCode = `'Field is required'`

export const StandardValidators = {
	CustomValidator: {
		validationType: 'custom',
		name: 'Custom',
		configType: 'Sys.Types.ValidationError | undefined',
		configEditorSize: 'normal',
		defaultMessageCode: `'Validation error'`,
		createCode(config: string, messageCode) {
			return config
		}
	} as ValidatorCodeFactory,
	
	RequiredValidator: {
		validationType: 'required',
		name: 'Required',
		configType: undefined,
		defaultMessageCode: 'Common.$T.validationRequired',
		createCode(config: never, messageCode) {
			return `
			if($value) return undefined
			return {
				error: ${messageCode || requiredValidatorDefaultMessageCode},
				ruleId: 'isRequired',
			}
			`
		},
	} as ValidatorCodeFactory,
	
	MinimumValidator: {
		validationType: 'minimum',
		name: 'Minimum value / date / length',
		configType: 'number | Date | string',
		defaultMessageCode: 'Common.$T.validationMinimum($value, $config)',
		createCode(config: number | Date, messageCode) {
			return `
			const $config = (${config}) as any
			let valid = false
			if(typeof $value == 'number' || $value instanceof Date) {
				valid = $value >= $config
			} else {
				valid = !$value || $value.toString().length >= $config
			}
			
			if(valid) return undefined
			return {
				error: ${messageCode || `'Minimum value / length not reached'`},
				ruleId: 'minimum',
			}
			`
		},
	} as ValidatorCodeFactory,
	
	MaximumValidator: {
		validationType: 'maximum',
		name: 'Maximum value / date / length',
		configType: 'number | Date | string',
		defaultMessageCode: 'Common.$T.validationMaximum($value, $config)',
		createCode(config: number | Date, messageCode) {
			return `
			const $config = (${config}) as any
			let valid = false
			if(typeof $value == 'number' || $value instanceof Date) {
				valid = $value <= $config
			} else {
				valid = !$value || $value.toString().length <= $config
			}
			
			if(valid) return undefined
			return {
				error: ${messageCode || `'Maximum value / length exceeded'`},
				ruleId: 'maximum',
			}
			`
		},
	} as ValidatorCodeFactory,

	EmailValidator: {
		validationType: 'email',
		name: 'Valid E-Mail',
		configType: 'never',
		defaultMessageCode: 'Common.$T.validationEmail',
		createCode(config: any, messageCode) {
			return `
			if(!$value) return undefined
			if(!!$value.match(/^[\\w-\\.]+@([\\w-]+\\.)+[\\w]{2,}$/g)) return undefined
			return {
				error: ${messageCode || `'Invalid E-Mail address'`},
				ruleId: 'email',
			}
			`
		},
	} as ValidatorCodeFactory,
	
	EqualsValidator: {
		validationType: 'equals',
		name: 'Equals other value',
		configType: 'any',
		defaultMessageCode: 'Common.$T.validationEquals($config)',
		createCode(config: any, messageCode) {
			return `
			const $config = (${config}) as any
			let valid = false
			if($value instanceof Date && $config instanceof Date) {
				valid = $value - $config == 0
			} else {
				valid == $config
			}
			
			if(valid) return undefined
			return {
				error: ${messageCode || `'Values do not match'`},
				ruleId: 'equals',
			}
			`
		},
	} as ValidatorCodeFactory,

	IbanValidator: {
		validationType: 'iban',
		name: 'Valid IBAN',
		configType: 'never',
		defaultMessageCode: 'Common.$T.validationIban',
		createCode(config, messageCode) {
			return `
			if(!$value) return undefined
			const $isValid = Sys.Text.isValidIban($value)
			return $isValid ? undefined : {
				error: ${messageCode || `'IBAN is invalid'`},
				ruleId: 'iban',
			}
			`
		}
	} as ValidatorCodeFactory
}
// TODO: change as to satisfies with TS 4.9