import { studioRoleGuard } from '@ng-shared/lib/services/studio-role.guard';
import { EditModule } from './edit/edit.module';
import { ErrorFileNotFoundComponent } from './shared/error-file-not-found/error-file-not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoStudioAccessComponent } from './shared/no-studio-access/no-studio-access'
import { LoginComponent } from './components/login/login.component'

const routes: Routes = [
  { path: '', children: [
    { path: '', redirectTo: 'main', pathMatch: 'full' },
    {
      path: 'login',
      component: LoginComponent,
    },
    {
      path: 'main',
      canActivate: [studioRoleGuard],
      loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    },
    {
      path: 'edit',
      canActivate: [studioRoleGuard],
      loadChildren: () => import('./edit/edit.module').then(m => EditModule),
    },
    {
      path: 'console',
      canActivate: [studioRoleGuard],
      loadChildren: () => import('./console/console.module').then(m => m.ConsoleModule)
    },
    { path: 'no-studio-access', component: NoStudioAccessComponent },
    { path: '**', component: ErrorFileNotFoundComponent },
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
