import type { TsCodeType } from '@shared/types'
import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class FormValidationRule {
	@jsonMember(String)
	validationType!: string
	@jsonMember(String)
	description?: string
	/**
	 * config can be e.g. the validator code (string), maxLen (number), etc.
	 */
	@jsonMember(String, { 
		serializer(value, params) { return value != undefined ? JSON.stringify(value) : undefined },
		deserializer(json, params) { return json ? JSON.parse(json) : undefined },
	})
	configCode: string = ''
	
	@jsonMember(String)
	messageCode: TsCodeType = ''

	constructor(init?: Partial<FormValidationRule>) {
		DataUtil.assignCommonProperties(this, init)
	}
}