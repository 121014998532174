import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'

@jsonObject({ name: 'ToolbarBlock' })
export class ToolbarBlock extends Block {
	constructor(init: Partial<ToolbarBlock> = {}) {
		super()
		this.init(init)
	}
	getPreviewDirection() {
		return 'row' as const
	}

	getEditorCategory(): string {
		return 'Screen Structure'
	}
}
