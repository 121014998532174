import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { Parameter } from '@shared/script/parameter'
import { BoVisitor } from './bo-visitor'
import { DataUtil } from '@shared/util/data-util'

@jsonObject
export class Actionable {
	@jsonMember(String) name!: string
	@jsonArrayMember(Parameter) inputs: Parameter[] = []
	@jsonMember(String) code: string = ''

	constructor(init?: Partial<Actionable>) {
		DataUtil.assignCommonProperties(this, init)
	}

	visit(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		visitor.visitTsCode(this.code, this, 'action', [...pathPrefix, 'code'], newCode => this.code = newCode)
		this.inputs.forEach((input, idx) => input.visit(visitor, [...pathPrefix, 'inputs', idx]))
	}
}
