import type { EventType, HtmlType } from './../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { StaticEntityBlock } from './static-entity-block'
import { Parameter } from '@shared/script/parameter'

@jsonObject({ name: 'DropdownBlock' })
export class DropdownBlock extends StaticEntityBlock {
	producePreview(): HtmlType {
		return `<select tabindex="-1"><option>${this.exampleText || 'Option'}</option></select>`
	}

	getEditorCategory(): string {
		return 'Core'
	}

	get allowedEventTypes(): EventType[] {
		return [
			{ name: 'selectionChange', parameters: [new Parameter({ name: 'selection', type: 'any' })] },
		]
	}
}
