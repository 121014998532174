import { Directive, DoCheck, EmbeddedViewRef, Host, Input, OnInit, Optional, SkipSelf, TemplateRef, ViewContainerRef } from '@angular/core'
import { AbstractScreen } from '../components/abstract-screen'

class ScopeDirectiveContext {
	constructor(
		public readonly $implicit: object
	) {}
}

@Directive({
	selector: '[lowgileScope]'
})
export class ScopeDirective implements DoCheck, OnInit {
	private _value: any = undefined
	private _as: string = ''
	private _screen?: AbstractScreen
	viewRef?: EmbeddedViewRef<ScopeDirectiveContext>
	
	constructor(
		private templateRef: TemplateRef<any>,
  		private viewContainer: ViewContainerRef,
  		@Host() @Optional() @SkipSelf() private parentDirective: ScopeDirective,
	) {}

	@Input() set lowgileScope(value: any) {
		this._value = value
	}

	@Input() set lowgileScopeAs(as: string) {
		this._as = as
	}

	@Input() @Optional() set lowgileScopeScreen(screen: AbstractScreen) {
		this._screen = screen
	}

	ngOnInit(): void {
		this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef)
	}

	ngDoCheck() {
		Object.assign(this.viewRef!.context, new ScopeDirectiveContext(this.getScope()))
	}

	getScope(): object {
		const screenScope = this._screen ? {
			app: this._screen.app,
			screen: this._screen.screen,
			...this._screen.$inputs,
		} : undefined

		const localScope = this._as ? {
			[this._as]: this._value
		} : undefined

		const scope = {
			...screenScope,
			...this.parentDirective?.getScope(),
			...localScope,
		}

		return scope
	}
}  