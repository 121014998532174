import { Block } from '@shared/blocks';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blockName',
  pure: true
})
export class BlockNamePipe implements PipeTransform {

  transform(block: any, ...args: unknown[]): unknown {
    console.log('blockName', block)
    return block.constructor.prototype.displayName;
  }

}
