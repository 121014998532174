import { DataUtil } from '@shared/util/data-util'
import { jsonObject, jsonMember } from 'typedjson'
import { BoVisitor } from './bo-visitor'
import { BoReference } from './bo-reference'
import { EntityProperty } from './entity-property'
import { RelationType as TypeormRelationType } from 'typeorm/metadata/types/RelationTypes'

export const EntityRelationTypeNames = {
	'one-to-many': 'One instance of this Entity has a relationship to multiple instances of target Entity (e.g. Person ➔ Address)',
	'many-to-one': 'Many instances of this Entity have a relationship to a single instance of the target Entity (e.g. Address ➔ Person)',
	'one-to-one-owning': 'This Entity and target Entity have a one-to-one relationship (e.g. Car ⬌ Engine). This Entity owns the relationship (ID property)',
	'one-to-one-secondary': 'This Entity and target Entity have a one-to-one relationship (e.g. Car ⬌ Engine). The other Entity owns the relationship',
} as const
export type RelationType = keyof typeof EntityRelationTypeNames
export const TypeormRelationTypes: Record<RelationType, TypeormRelationType> = {
	'one-to-many': 'one-to-many',
	'many-to-one': 'many-to-one',
	'one-to-one-owning': 'one-to-one',
	'one-to-one-secondary': 'one-to-one',
}

@jsonObject
export class EntityRelation {
	@jsonMember(String) name!: string
	@jsonMember(String) idProperty: string = ''
	@jsonMember(String) type: RelationType = 'many-to-one'
	@jsonMember(BoReference) targetEntityRef!: BoReference
	@jsonMember(String) inverseRelation: string = ''
	@jsonMember(Boolean) enforceConstraint: boolean = true

	constructor(init?: Partial<EntityRelation>) {
		DataUtil.assignCommonProperties(this, init)
	}

	getIdPropertyDefinition() {
		if(!this.hasInverseRelation()) {
			return new EntityProperty({
				name: this.getIdPropertyName(),
				type: this.targetEntityRef ? `${this.targetEntityRef.getQualifiedName()}.Id` : 'never',
				defaultExpression: 'null',
			})
		}

		return null
	}

	getPropertyDefinitions() {
		const type = this.targetEntityRef ? (
			this.type == 'one-to-many'
			? `${this.targetEntityRef.getQualifiedName()}[]`
			: `${this.targetEntityRef.getQualifiedName()}`
		) : 'never'

		return [
			new EntityProperty({
				name: this.name,
				type,
			}),
			this.getIdPropertyDefinition(),
		].filter(Boolean) as EntityProperty[]
	}

	getIdPropertyName() {
		return this.idProperty || `${this.name}Id`
	}

	hasInverseRelation() {
		return this.type == 'one-to-many' || this.type == 'one-to-one-secondary'
	}

	visit(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		visitor.visitBoReference(this.targetEntityRef, [...pathPrefix, 'targetEntityRef'])
	}
}