import { LogicBlock } from './logic-block'
import { IfBlock } from './if-block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { Block } from '..'

@jsonObject({ name: 'ElseBlock' })
export class ElseBlock extends LogicBlock {
	constructor(init: Partial<ElseBlock> = {}) {
		super()
		this.init(init)
	}

	canHaveParent(parent: Block): boolean {
		console.log(parent instanceof IfBlock, parent)
		if(!(parent instanceof IfBlock)) return false
		return parent.children.filter(child => child instanceof ElseBlock).length == 0 // ensure there isn't aleady an Else
	}

	getEditorCategory(): string {
		return 'Logic'
	}
}
