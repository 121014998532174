import { map, share, shareReplay, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints as BP } from '@angular/cdk/layout'
import { Breakpoints, BreakpointSection, ScreenSizes } from '@shared/types'
import { ChangeDetectionService } from './change-detection.service'

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
	breakpoints$: Observable<Breakpoints>

  constructor(
    private breakpointObserver: BreakpointObserver,
	private cdService: ChangeDetectionService,
  ) {
    this.breakpoints$ = this.breakpointObserver.observe([
		BP.XSmall,
		BP.Small,
		BP.Medium,
		BP.Large,
		BP.XLarge,
		BP.Handset,
		BP.HandsetPortrait,
		BP.HandsetLandscape,
		BP.Tablet,
		BP.TabletPortrait,
		BP.TabletLandscape,
		BP.Web,
		BP.WebPortrait,
		BP.WebLandscape,
	]).pipe(
		map(state => {
			const queries = ScreenSizes.map(size => BP[size])

			const matchedIdx = queries.findIndex(query => state.breakpoints[query])

			const bps: Breakpoints = {
				sizeEquals: Object.fromEntries(ScreenSizes.map((size, idx) => [size, matchedIdx == idx])) as unknown as BreakpointSection,
				sizeIsGreaterThan: Object.fromEntries(ScreenSizes.map((size, idx) => [size, matchedIdx > idx])) as unknown as BreakpointSection,
				sizeIsGreaterThanOrEqual: Object.fromEntries(ScreenSizes.map((size, idx) => [size, matchedIdx >= idx])) as unknown as BreakpointSection,
				sizeIsLessThan: Object.fromEntries(ScreenSizes.map((size, idx) => [size, matchedIdx < idx])) as unknown as BreakpointSection,
				sizeIsLessThanOrEqual: Object.fromEntries(ScreenSizes.map((size, idx) => [size, matchedIdx <= idx])) as unknown as BreakpointSection,
				isHandset: state.breakpoints[BP.Handset],
				isHandsetPortrait: state.breakpoints[BP.HandsetPortrait],
				isHandsetLandscape: state.breakpoints[BP.HandsetLandscape],
				isTablet: state.breakpoints[BP.Tablet],
				isTabletPortrait: state.breakpoints[BP.TabletPortrait],
				isTabletLandscape: state.breakpoints[BP.TabletLandscape],
				isWeb: state.breakpoints[BP.Web],
				isWebPortrait: state.breakpoints[BP.WebPortrait],
				isWebLandscape: state.breakpoints[BP.WebLandscape],
				isPortrait: state.breakpoints[BP.HandsetPortrait] || state.breakpoints[BP.TabletPortrait] || state.breakpoints[BP.WebPortrait],
				isLandscape: state.breakpoints[BP.HandsetLandscape] || state.breakpoints[BP.TabletLandscape] || state.breakpoints[BP.WebLandscape],
				screenSize: ScreenSizes[matchedIdx],
			}
			return bps
		}),
		tap(bps => {
			document.body.classList.remove(...ScreenSizes)
			document.body.classList.add(bps.screenSize)
			this.cdService.refreshWholeApp()
		}),
		shareReplay(1),
	)
  }
}
