export interface EnvironmentSettings {
	publicName: string
	shortName: string
	bannerText: string
	fgColor: string
	bgColor: string
	cacheUntil: Date
}

export const idbSettingName = 'Lowgile_EnvironmentSettings'
