import { DataUtil } from '@shared/util/data-util';
import { AnyT, jsonMember, jsonObject, toJson } from 'typedjson'

@jsonObject
@toJson
export class DeploymentAuthorization {
	@jsonMember(String)
	get __type() { return 'DeploymentAuthorization' }
	set __type(_) {}
	@jsonMember(Number)
	id?: number
	@jsonMember(String)
	name?: string
	@jsonMember(String)
	publicKeyPem!: string
	@jsonMember(Boolean)
	allowWriting?: boolean
	@jsonMember(AnyT)
	lastUsed: Record<string, string> = {} // <environmentId, isoTimestamp>

	constructor(init?: Partial<DeploymentAuthorization>) {
		DataUtil.assignCommonProperties(this, init)
	}
}