import { PagedData, PagingDefinition, SortingDefinition, UserRelatedTokenFilter, UserTaskToken, UserTaskTokenDetails } from '@shared/types'
import { AppContext } from '../app-context'
import { AppTrpcService } from '../services/app-trpc.service'
import { FrontendUserBridge } from './frontend-user-bridge'
import { FrontendScreenBridge } from './frontend-screen-bridge'
import { ProcessService } from '../services/process.service'
import { BaseJsonMapper } from '@shared/data/base-json-mapper'

const jsonMapper = new BaseJsonMapper(false, {})

export async function getTaskById(tokenId: string): Promise<UserTaskToken | undefined> {
	const trpc = AppContext.injector.get(AppTrpcService)
	let task = await trpc.client.process.getTaskById.query({ tokenId })
	task = jsonMapper.readFromObject(task, UserTaskToken)
	return task
}

export async function getTaskDetailsById(tokenId: string): Promise<UserTaskTokenDetails | undefined> {
	const trpc = AppContext.injector.get(AppTrpcService)
	let taskDetails = await trpc.client.process.getTaskDetailsById.query({ tokenId })
	taskDetails = jsonMapper.readFromObject(taskDetails, UserTaskTokenDetails)
	return taskDetails
	
}

export async function getAllTasksForUser(filter: UserRelatedTokenFilter): Promise<UserTaskToken[]> {
	const trpc = AppContext.injector.get(AppTrpcService)
	const result = await trpc.client.process.getTasksForUser.query({
		filter,
		paging: undefined,
	})
	result.list = jsonMapper.readFromObject(result.list, UserTaskToken)
	return result.list
}

export async function getPagedTasksForUser(filter: UserRelatedTokenFilter, paging: PagingDefinition, sorting?: SortingDefinition<keyof UserTaskToken>): Promise<PagedData<UserTaskToken>> {
	const trpc = AppContext.injector.get(AppTrpcService)
	const result = await trpc.client.process.getTasksForUser.query({
		filter,
		paging,
		sorting,
	})
	result.list = jsonMapper.readFromObject(result.list, UserTaskToken)
	return result
}

export async function assignTaskToCurrentUser(tokenId: string) {
	const userId = FrontendUserBridge.currentUser?.id
	if(!userId) throw new Error('You are currently not logged in')
	return assignTaskToUser(tokenId, userId, false)
}

export async function assignCurrentTaskToCurrentUser() {
	const tokenId = getCurrentTaskTokenId()
	if(!tokenId) throw new Error('You are not currently in a process task')
	return assignTaskToCurrentUser(tokenId)
}

export async function assignTaskToUser(tokenId: string, toUserIdOrUsername: string | undefined, reassignIfAssigned = false) {
	const trpc = AppContext.injector.get(AppTrpcService)
	const result = await trpc.client.process.assignTaskToUser.mutate({
		tokenId,
		toUserIdOrUsername,
		reassignIfAssigned,
	})

	if(tokenId == getCurrentTaskTokenId()) {
		AppContext.injector.get(ProcessService).tokenId.set(null)
		AppContext.injector.get(ProcessService).tokenId.set(tokenId) // trigger reload of task details
	}

	return result
}

export async function unassignTask(tokenId: string) {
	const trpc = AppContext.injector.get(AppTrpcService)
	return trpc.client.process.unassignTask.mutate({
		tokenId,
	})
}

export async function navigateToTask(tokenOrId: UserTaskToken | string, inNewWindow: boolean | string) {
	if(typeof tokenOrId == 'string') {
		const token = await getTaskById(tokenOrId)
		if(!token) throw new Error('Task not found: ' + tokenOrId)
		tokenOrId = token
	}

	if(!tokenOrId.screenModuleId) {
		tokenOrId = new UserTaskToken({
			...tokenOrId,
			screenModuleId: AppContext.appModuleId,
		})
	}

	return FrontendScreenBridge.openUrl(tokenOrId.getUrl(), inNewWindow)
}

export async function driveProcess(action: string, data: any, tokenId?: string) {
	const token = await driveProcessAndGetFirstUserTaskToken(action, data, tokenId, -1, -1)
	return jsonMapper.readFromObject(token, UserTaskToken)
}

export async function driveProcessAndFollowNextUserTask(action: string, data: any, tokenId?: string, taskTimeoutMs?: number, taskDebounceMs?: number) {
	const result = await driveProcessAndGetFirstUserTaskToken(action, data, tokenId, taskTimeoutMs, taskDebounceMs)
	if(result?.id) {
		await navigateToTask(result.id, false)
	} else {
		// TODO: redirect to tasks screen. TBD how this will be set; most likely on a per-process level
		window.location.href = window.location.href.replace(/\/t\/[a-f0-9-]*$/i, '')
	}
}

export async function driveProcessAndGetFirstUserTaskToken(action: string, data: any, tokenId?: string, taskTimeoutMs?: number, taskDebounceMs?: number) {
	if(!tokenId) tokenId = AppContext.injector.get(ProcessService).tokenId() ?? undefined
	if(!tokenId) throw new Error('Cannot drive process forward without a tokenId')

	const trpc = AppContext.resolve(AppTrpcService)
	const token = await trpc.client.process.driveProcess.mutate({
		tokenId,
		action,
		data,
		getFirstUserTaskToken: (taskTimeoutMs ?? 0) > -1 && (taskDebounceMs ?? 0) > -1 ? {
			taskTimeoutMs,
			taskDebounceMs,
		} : undefined
	})
	return jsonMapper.readFromObject(token, UserTaskToken)
}

export function getCurrentTaskTokenId() {
	return AppContext.injector.get(ProcessService).tokenId()
}

export function getCurrentTaskDetails() {
	return AppContext.injector.get(ProcessService).taskDetails()
}

export function isProcessTask() {
	return AppContext.injector.get(ProcessService).isProcessTask()
}

export function isUnassignedTask() {
	return AppContext.injector.get(ProcessService).isUnassignedTask()
}

export function isAssignedTask() {
	return AppContext.injector.get(ProcessService).isAssignedTask()
}