import type { Class } from '../types'
import { Block } from './block'
import { jsonObject, jsonMember, jsonArrayMember, toJson } from 'typedjson'
import { ScreenEditorInput } from './decorators/screen-editor-input'

export abstract class LeafBlock extends Block {
	readonly allowedChildTypes: '*' | Class<Block>[] = []
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'input',
		order: 21,
		label: 'Example text',
		contentType: 'text',
		elementType: 'input',
	})
	exampleText: string = ''

	canHaveChildren(): boolean {
		return false
	}

	canHaveChild(child: Block): boolean {
		return false
	}

	canHaveParent(parent: Block | undefined): boolean {
		return true
	}
}
