import { CodeContext } from '@ng-shared/lib/script/code-context'

const isRangeSelection = function (s) {
    if (s.selectionStartLineNumber !== s.positionLineNumber) {
        return true;
    }
    if (s.selectionStartColumn !== s.positionColumn) {
        return true;
    }
    return false;
};


export async function setUpWrappers(editor: any, context: CodeContext) {
    const monaco = await import('monaco-editor')
    editor.setPosition({
        column: 0,
        lineNumber: context.prefixDisabledLineCount + 1
    });
    /* These signal to custom commands
       that the current cursor's position is in the end/start of the editable range */
    const contextSOR = editor.createContextKey('startOfEditable', false),
          contextEOR = editor.createContextKey('endOfEditable', false);

    const restrictSelections = function (selections) {
        selections = selections || editor.getSelections();
        let resetSelections = false;
        const model = editor.getModel();
        const minLine = context.prefixDisabledLineCount + 1
        const maxLine = model.getLineCount() - context.postfixDisabledLineCount;
        const lastLineCol = model.getLineContent(Math.max(maxLine, 1)).length + 1;

        contextEOR.set(false);
        contextSOR.set(false);

        for (const selection of selections) {
            if (selection.selectionStartLineNumber < minLine) {
                selection.selectionStartLineNumber = minLine;
                selection.selectionStartColumn = 1;
                resetSelections = true;
            }
            if (selection.positionLineNumber < minLine) {
                selection.positionLineNumber = minLine;
                selection.positionColumn = 1;
                resetSelections = true;
            }
            if (selection.selectionStartLineNumber > maxLine) {
                selection.selectionStartLineNumber = maxLine;
                selection.selectionStartColumn = lastLineCol;
                resetSelections = true;
            }
            if (selection.positionLineNumber > maxLine) {
                selection.positionLineNumber = maxLine;
                selection.positionColumn = lastLineCol;
                resetSelections = true;
            }
            /* Get if any of the cursors happened to be in the beginning/end
                of the editable range, so that we can block Delete/Backspace behavior.
                Range selections are safe, as they delete the selected content,
                not that is behind/in front of them. */
            if (!isRangeSelection(selection)) {
                if (selection.selectionStartLineNumber === minLine &&
                    selection.selectionStartColumn === 1
                ) {
                    contextSOR.set(true);
                }
                if (selection.positionLineNumber === maxLine &&
                    selection.positionColumn === lastLineCol
                ) {
                    contextEOR.set(true);
                }
            }
        }
        if (resetSelections) {
            editor.setSelections(selections);
        }
    };

    // Turns out the Delete and Backspace keys do not produce a keyboard event but commands
    // These commands overlay the default ones, thus cancelling the default behaviour
    // @see https://github.com/microsoft/monaco-editor/issues/940
    const voidFunction = function () {
        void 0; // magic!
    };
    editor.addCommand(monaco.KeyCode.Backspace, voidFunction, 'startOfEditable');
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Backspace, voidFunction, 'startOfEditable');
    editor.addCommand(monaco.KeyMod.Shift | monaco.KeyCode.Backspace, voidFunction, 'startOfEditable');
    editor.addCommand(monaco.KeyMod.Alt | monaco.KeyCode.Backspace, voidFunction, 'startOfEditable');
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyCode.Backspace, voidFunction, 'startOfEditable');
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyMod.Alt | monaco.KeyCode.Backspace, voidFunction, 'startOfEditable');
    editor.addCommand(monaco.KeyMod.Shift | monaco.KeyMod.Alt | monaco.KeyCode.Backspace, voidFunction, 'startOfEditable');
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyMod.Shift | monaco.KeyMod.Alt | monaco.KeyCode.Backspace, voidFunction, 'startOfEditable');
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Delete, voidFunction, 'endOfEditable');
    editor.addCommand(monaco.KeyCode.Delete, voidFunction, 'endOfEditable');

    // These cheat on the replace widget so that it cannot replace anything in the wrapper.
    // Done by temporarily switching to "replace in selection" mode and back.
    // Atomic replacements "work as expected" without this, for some reason or another.
    const find = editor.getContribution('editor.contrib.findController');
    const oldReplaceAll = find.replaceAll.bind(find);
    find.replaceAll = function replaceAll() {
        const oldSelections = editor.getSelections() ? [...editor.getSelections()] : [];
        const oldSearchScope = find._state.searchScope;
        const oldGetFindScope = find._model._decorations.getFindScope;
        if (!oldSearchScope) {
            // Make up a new replacement scope
            const model = editor.getModel();
            const minLine = context.prefixDisabledLineCount + 1;
            const maxLine = model.getLineCount() - context.postfixDisabledLineCount;
            const lastLineCol = model.getLineContent(Math.max(maxLine, 1)).length + 1;
            const scope = {
                endColumn: lastLineCol,
                endLineNumber: maxLine,
                positionColumn: 1,
                positionLineNumber: minLine,
                selectionStartColumn: lastLineCol,
                selectionStartLineNumber: maxLine,
                startColumn: 1,
                startLineNumber: minLine
            };
            find._state.change({
                searchScope: {
                    ...scope
                }
            }, true);
            editor.setSelection(scope);
            find._model._decorations.getFindScope = (function getFindScope() {
                return scope;
            });
        }

        oldReplaceAll();

        // Bring the previous editor state back
        find._model._decorations.getFindScope = oldGetFindScope;
        editor.setSelections(oldSelections);
        find._state.change({
            searchScope: oldSearchScope
        }, true);
        find._widget._updateSearchScope();
        restrictSelections(undefined);
    };

    // Clamp selections so they can't select wrapping lines
    editor.onDidChangeCursorSelection(function onChangeCursorSelection(evt) {
        const selections = [evt.selection, ...evt.secondarySelections];
        restrictSelections(selections);
    });

    // const model = editor.getModel();
    // const lastLine = model.getLineCount();
    // editor.setHiddenAreas([{
    //     startLineNumber: 1,
    //     endLineNumber: 1
    // }, {
    //     startLineNumber: lastLine,
    //     endLineNumber: lastLine
    // }]);
};