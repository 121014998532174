import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import type { TsCodeType } from '@shared/types'

export const StepperOrientations = {
	horizontal: 'Horizontal',
	vertical: 'Vertical,'
} as const
export type StepperOrientation = keyof typeof StepperOrientations

@jsonObject({ name: 'StepperStepBlock' })
export class StepperStepBlock extends Block {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		alternativeContentAreaName: 'label',
	})
	labelCode?: TsCodeType

	@ScreenEditorInput<StepperStepBlock>({
		inputType: 'code',
		order: 2,
		label: 'Optional',
		codeLanguage: 'ts',
		tsReturnType: () => 'boolean',
		editorSize: 'singleline',
		isBinding: false,
	})
	@jsonMember(String) isOptionalCode: string = "false"
	
	@ScreenEditorInput<StepperStepBlock>({
		inputType: 'code',
		order: 2,
		label: 'Editable',
		codeLanguage: 'ts',
		tsReturnType: () => 'boolean',
		editorSize: 'singleline',
		isBinding: false,
	})
	@jsonMember(String) isEditableCode: string = "true"
	
	@jsonMember
	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 3,
		label: 'Load content lazily',
		tab: 'Advanced',
	})
	loadLazily: boolean = false

	getPreviewDirection(): 'row' | 'column' {
		return this.parent?.getPreviewDirection() ?? 'column'
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'black'
	}

	getTitleColor(): string {
		return 'white'
	}

	getEditorCategory(): string {
		return 'Additional Form Fields'
	}
}
