<div class="code-editor--{{size}} {{cssClass}}" #container>
  <!-- <div class="editor" [style.visibility]="visibility">
    <ngx-monaco-editor
      [(ngModel)]="code"
      (ngModelChange)="onModelChange()"
      (onInit)="onMonacoInit($event)"
      [options]="editorOptions"
      [style.flex]="'1 1 auto'"
      style="height: 100%;"
    ></ngx-monaco-editor>
  </div> -->
</div>
