import { DBSchema, IDBPDatabase, OpenDBCallbacks, openDB } from 'idb'
import { SimpleIdbConnection } from '../store/simple-idb-connection'

export class FrontendStorageBridge {
	static get localStorage() {
		return localStorage
	}

	static get indexedDB() {
		return indexedDB
	}

	static openSimpleIdb<T>(storeName = 'default', dbName = 'default') {
		return new SimpleIdbConnection<T>(storeName, dbName)
	}
	
	static openIdbDatabase<T extends DBSchema | unknown = unknown>(name: string, version?: number, { blocked, upgrade, blocking, terminated }?: OpenDBCallbacks<T>): Promise<IDBPDatabase<T>>
	static openIdbDatabase<T extends DBSchema | unknown = unknown>(name: string, version?: number, callbacks?: OpenDBCallbacks<T>): Promise<IDBPDatabase<T>> {
		return openDB(name, version, callbacks)
	}
}