import { Pipe, PipeTransform } from '@angular/core'
import { TextBlockStyle } from '@shared/blocks/other/text-block'

@Pipe({
	name: 'replaceNewlines'
})
export class ReplaceNewlinesPipe implements PipeTransform {
	transform(value: string | { changingThisBreaksApplicationSecurity: string }, tagName: TextBlockStyle) {
		if(!value) return value
		
		const isSafeHtml = typeof value == 'object' && !!value.changingThisBreaksApplicationSecurity
		let html = isSafeHtml ? value.changingThisBreaksApplicationSecurity : value as string

		if(tagName == 'p') {
			html = `<p>${html.replace(/\n[ \t]*\n/g, '</p><p>')}</p>`
		}
		html = html.replace(/\n/g, '<br>')

		if(isSafeHtml) {
			value.changingThisBreaksApplicationSecurity = html
			return value
		}
		return html
	}
}