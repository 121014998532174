import type { TsTypeType } from './../../types';
import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'ContentAreaBlock' })
export class ContentAreaBlock extends Block {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'input',
		order: 1,
		label: 'Name',
		contentType: 'text',
		elementType: 'input',
		isMandatory: () => true,
	})
	name: TsTypeType = 'content'

	constructor(init: Partial<ContentAreaBlock> = {}) {
		super()
		this.init(init)
	}

	getEditorTitle() {
		return `${super.getEditorTitle()} ${this.name}`
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'black'
	}

	getTitleColor(): string {
		return 'white'
	}

	getEditorCategory(): string {
		return 'Screen Structure'
	}

	protected getOwnVariables(includeDeclarationVariables: boolean): Record<string, string | null> {
		return this.parent?.getOwnVariablesForChildContentArea(this) ?? {}
	}
}
