import { DataUtil } from '@shared/util/data-util'
import type { TsTypeType, TsCodeType } from '@shared/types';
import type { VariableNameType } from './../types';
import { LowgileAction } from './../script/lowgile-action';
import { jsonArrayMember, jsonObject, jsonMember } from 'typedjson';
import { BoVisitor } from '@shared/bos/bo-visitor'

export const StoreSources = ['static', 'dataquery'] as const

@jsonObject
export class StoreProperty {
	@jsonMember(String) name!: VariableNameType
	@jsonMember(String) type!: TsTypeType
	@jsonMember(Boolean) isMultiple: boolean = false
	@jsonMember(String) source: typeof StoreSources[number] = 'static'
	@jsonMember(String) sourceExpression: TsCodeType = ''
	@jsonMember(String) sourceDataQuery: string = ''

	constructor(init?: Partial<StoreProperty>) {
		DataUtil.assignCommonProperties(this, init)
	}
}

@jsonObject
export class Store {
	@jsonArrayMember(StoreProperty)
	properties: StoreProperty[] = []

	@jsonArrayMember(LowgileAction)
	actions: LowgileAction[] = []

	visit(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		this.properties?.forEach((property, idx) => {
			visitor.visitTsCode(property.type, property, 'type', [...pathPrefix, idx, 'type'], newType => property.type = newType)
			visitor.visitTsCode(property.sourceExpression, property, 'property', [...pathPrefix, idx, 'sourceExpression'], newCode => property.sourceExpression = newCode)
		})
		this.actions.forEach((action, idx) => action.visit(visitor, [...pathPrefix, 'action', idx]))
	}
}
