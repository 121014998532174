import { JsonMappable } from './json-mappable'
import { BaseJsonMapper } from './base-json-mapper'
import { AllConcreteBlockTypesMap, MappableRootClasses } from '../classes'
import { Class } from '@shared/types'

const knownTypes = {
	...MappableRootClasses,
	...AllConcreteBlockTypesMap
}

export class LowgileJsonMapper<T extends object> extends BaseJsonMapper<T> {
	constructor(prettyPrint: boolean, private additionalKnownTypes?: Record<string, Class<object>>) {
		super(prettyPrint, {
			...knownTypes,
			...additionalKnownTypes,
		})
	}
}
