import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { DataUtil } from '@shared/util/data-util'
import type { TsCodeType } from '@shared/types'

@jsonObject({ name: 'DataType' })
export class DataType extends BusinessObject {
	@jsonMember(String)
	declarationTs: TsCodeType = ''

	constructor(init?: Partial<DataType>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}
}
