import { EditorService } from '../../services/editor.service';
import { Subscription } from 'rxjs';
import { TypeReference } from '@shared/data/type-reference';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { BusinessObjectTypesOrAll, BusinessObjectTypeType, TsTypeType } from '@shared/types';
import { PrimitiveType, ModuleIdType } from '@shared/types';
import { Component, Input, OnInit, Output, EventEmitter, forwardRef, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { Key } from 'ts-key-enum'

@Component({
  selector: 'lowgile-type-reference',
  templateUrl: './type-reference.component.html',
  styleUrls: ['./type-reference.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TypeReferenceComponent),
    multi: true
  }]
})
export class TypeReferenceComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() allowedPrimitives: PrimitiveType[] | '*' = '*'
  @Input() allowedBoTypes: BusinessObjectTypesOrAll = '*'
  @Input() allowedBoModuleIds: ModuleIdType[] | '*' = '*'
  @ViewChild('inputEl') inputEl: ElementRef<HTMLInputElement>

  onChange = (value: TsTypeType) => {}
  formControl = new FormControl(null)

  allowedOptions: TypeReference[] = []
  filteredOptions: TypeReference[] = []

  subscriptions: Subscription[] = []

  constructor(
    private editorService: EditorService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.editorService.typeReferences$.subscribe(typeRefs => {
      this.allowedOptions = typeRefs
      this.updateFilteredOptions('')
    }))
    this.editorService.loadTypeReferences('master', ['Entity', 'StaticEntity'], false)
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  onFilterChange(event: KeyboardEvent) {
    if(event.key == Key.Tab) return

    this.updateFilteredOptions((event.target as any).value)
  }

  updateFilteredOptions(filterValue: string) {
    if(!filterValue) {
      this.filteredOptions = this.allowedOptions
      return
    }

    const filterRegex = new RegExp(filterValue, 'i')
    this.filteredOptions = this.allowedOptions.filter(option => this.getOptionDisplayText(option).match(filterRegex))
  }

  getOptionDisplayText(option: TypeReference) {
    return option?.getDisplayText?.()
  }

  onSelected(event: MatAutocompleteSelectedEvent) {
    const selectedTypeRef: TypeReference = event.option.value
    console.log(event, this.formControl.value)
    this.writeValue(selectedTypeRef)
    this.onChange(selectedTypeRef.getQualifiedTypeName())
    this.inputEl?.nativeElement.blur()
  }

  writeValue(value: TypeReference): void {
    this.formControl.setValue(value)
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}
