import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AuthService } from '@ng-shared/lib/services/auth-service'

@Component({
	templateUrl: './no-studio-access.html'
})
export class NoStudioAccessComponent {
	constructor(
		private authService: AuthService,
		private route: ActivatedRoute,
	) {}

	logout() {
		this.authService.logout$().subscribe(() => {
			let redirect = this.route.snapshot.queryParamMap.get('redirect')
			if(redirect) redirect = `?redirect=${encodeURIComponent(redirect)}`
			window.location.replace(`/studio/login${redirect}`)
		})
	}
}