import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, effect, input, signal } from '@angular/core'
import type { Result } from '@zxing/library'
import type Cropper from 'cropperjs'

@Component({
	selector: 'app-image-selector',
	templateUrl: './image-selector.component.html',
	styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent implements AfterViewInit {
	@ViewChild('file')
	private fileEl: ElementRef<HTMLInputElement>
	@ViewChild('video')
	private videoEl: ElementRef<HTMLVideoElement>
	@ViewChild('image')
	private imageEl: ElementRef<HTMLImageElement>
	private lastBarcodeResult?: Result
	private stream: MediaStream | null = null
	private cropper?: Cropper

	allowFileSelection = input(true)
	autoStartCapturing = input(false)
	parseQrCode = input(false)
	onQrDetection = input<'takePicture' | 'takeAndUsePicture' | 'doNothing'>('takePicture')
	desiredImageWidth = input(1920)
	desiredImageHeight = input<number | undefined>(undefined)
	captureImageFormat = input<'png' | 'jpg'>('jpg')

	@Output()
	qrCodeDetected = new EventEmitter<string>()
	@Output()
	pictureTaken = new EventEmitter<string>()

	protected isCapturing = signal(false)
	protected hasTakenPicture = false
	protected barcodeDecoder?: { stop: () => void }

	constructor() {
		effect(() => {
			if (this.autoStartCapturing()) {
				this.startCapturing()
			}
		})
		effect(async () => {
			this.barcodeDecoder?.stop()
			
			if(this.parseQrCode() && this.isCapturing()) {
				const { BrowserMultiFormatReader } = await import('@zxing/library')
				const barcodeReader = new BrowserMultiFormatReader()

				await barcodeReader.decodeFromVideoElementContinuously(this.videoEl.nativeElement, async (result, error) => {
					if(result) {
						this.lastBarcodeResult = result

						switch(this.onQrDetection()) {
							case 'takePicture': {
								await this.takePictureInternal()
								break
							}
							case 'takeAndUsePicture': {
								await this.takePictureInternal()
								this.useBarcode()
								break
							}
						}
					}
				})
				
				this.barcodeDecoder = {
					stop: () => {
						barcodeReader.stopContinuousDecode()
						this.barcodeDecoder = undefined
					}
				}
			}
		})
	}

	async ngAfterViewInit() {
	}

	selectFile() {
		this.fileEl.nativeElement.click()
	}

	async startCapturing() {
		if(this.isCapturing()) {
			return
		}
		this.isCapturing.set(true)
		this.hasTakenPicture = false
		
		this.stream = await navigator.mediaDevices.getUserMedia({ video: {
			width: { ideal: this.desiredImageWidth() },
			height: { ideal: this.desiredImageHeight() },
			facingMode: 'environment',
		}, audio: false })
		this.imageEl.nativeElement.src = ''
		this.videoEl.nativeElement.srcObject = this.stream
		// this.videoEl.nativeElement.play()
		this.videoEl.nativeElement.classList.toggle('hidden', false)
	}

	stopCapturing() {
		this.isCapturing.set(false)
		this.stream?.getTracks().forEach(track => track.stop())
		this.videoEl.nativeElement.srcObject = this.stream = null
		this.videoEl.nativeElement.classList.toggle('hidden', true)
	}

	cancelCapture() {
		this.stopCapturing()
		this.imageEl.nativeElement.src = ''
		this.hasTakenPicture = false
	}

	private async takePictureInternal() {
		const video = this.videoEl.nativeElement
		const canvas = document.createElement('canvas')
		canvas.width = video.videoWidth
		canvas.height = video.videoHeight
		const context = canvas.getContext('2d')!
		context.drawImage(video, 0, 0, canvas.width, canvas.height)

		const barcodePoints = this.lastBarcodeResult?.getResultPoints()
		if(barcodePoints?.length) {
			
			// const growAmount = (barcodePoints as any[]).reduce((sum, point) => sum + 3.5 * point.estimatedModuleSize ?? 0, 0) / barcodePoints.length
			
			const grownBarcodePoints = barcodePoints.map((point, idx) => {
				const next = barcodePoints[(idx + 1) % barcodePoints.length]
				const prev = barcodePoints[(idx - 1 + barcodePoints.length) % barcodePoints.length]

				const lenToNext = Math.sqrt((next.getX() - point.getX()) ** 2 + (next.getY() - point.getY()) ** 2)
				const lenToPrev = Math.sqrt((prev.getX() - point.getX()) ** 2 + (prev.getY() - point.getY()) ** 2)

				return {
					x: point.getX() - ((point as any).estimatedModuleSize ?? 0) * 3.5 * ((next.getX() - point.getX()) / lenToNext + (prev.getX() - point.getX()) / lenToPrev),
					y: point.getY() - ((point as any).estimatedModuleSize ?? 0) * 3.5 * ((next.getY() - point.getY()) / lenToNext + (prev.getY() - point.getY()) / lenToPrev),
				}
			})
			context.beginPath()
			grownBarcodePoints.forEach(point => {
				context.lineTo(point.x, point.y)
			})
			context.fillStyle = 'rgba(0, 255, 0, 0.3)'
			context.fill()
		}
	
		this.imageEl.nativeElement.src = canvas.toDataURL(`image/${this.captureImageFormat()}`)
		this.imageEl.nativeElement.classList.toggle('hidden', false)
		this.videoEl.nativeElement.classList.toggle('hidden', true)
		this.videoEl.nativeElement.srcObject = null

		this.cropper?.destroy()
		const Cropper = (await import('cropperjs')).default
		this.cropper = new Cropper(this.imageEl.nativeElement, {
			viewMode: 2,
			zoomable: false,
			modal: false,
			highlight: false,
			crop: event => {
				console.log('crop', event)
			}
		})

		this.hasTakenPicture = true
		this.stopCapturing()
	}

	async takePicture() {
		this.lastBarcodeResult = undefined
		await this.takePictureInternal()
	}

	usePicture() {
		this.pictureTaken.emit(this.imageEl.nativeElement.src)
		this.stopCapturing()
	}

	useBarcode() {
		this.qrCodeDetected.emit(this.lastBarcodeResult?.getText() ?? '')
		this.stopCapturing()
	}

	onFileSelected(event: Event) {
		this.stopCapturing()
	}
}