import { TypeReferenceFactory } from './../../data/type-reference';
import { TypeReference } from '@shared/data/type-reference';
import type { TsTypeType } from '../../types'
import { LogicBlock } from './logic-block'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { Block } from '../block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoVisitor } from '@shared/bos/bo-visitor'
import { Expression, ExpressionOrCode } from '@shared/data/text-or-code'

export const TrackByFunctions = ['trackByAutomatic', 'trackById', 'trackByIndex', 'trackByItem'] as const
export const TrackByFunctionNames: Record<typeof TrackByFunctions[number], string> = {
	trackByAutomatic: 'Automatic (Id if present, item if string/number, index otherwise)',
	trackById: 'Id',
	trackByIndex: 'Index',
	trackByItem: 'Looped item'
}

@jsonObject({ name: 'ForBlock' })
export class ForBlock extends LogicBlock {
	@jsonMember(ExpressionOrCode)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Array',
		codeLanguage: 'ts',
		tsReturnType: () => 'any[]',
		editorSize: 'normal',
		isBinding: false,
		isMandatory: () => true,
		spanColumns: '1 / -1',
	})
	collectionCode = new ExpressionOrCode('[]')

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'input',
		order: 2,
		label: 'Loop variable',
		contentType: 'text',
		elementType: 'input',
		isMandatory: () => true,
	})
	loopVariableDefinition: TsTypeType = 'item'
	
	@jsonMember(Expression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Loop variable type',
		codeLanguage: 'ts',
		tsReturnType: () => 'any',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	loopVariableType = new Expression('{}')
	
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'input',
		order: 4,
		label: 'Index variable (0, 1, 2, ...)',
		contentType: 'text',
		elementType: 'input',
	})
	indexVariableDefinition: TsTypeType = 'index'

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 5,
		label: 'Track by',
		options: TrackByFunctions.map(f => [f, TrackByFunctionNames[f]])
	})
	trackBy: typeof TrackByFunctions[number] = 'trackByAutomatic'

	constructor(init: Partial<ForBlock> = {}) {
		super()
		this.init(init)
	}

	getEditorTitle() {
		return `${super.getEditorTitle()} ${this.loopVariableDefinition} in ${
			this.collectionCode.content
		}`
	}

	getTitleBackgroundAndBorderColor(): string {
		return 'blue'
	}

	getTitleColor(): string {
		return 'white'
	}

	protected getOwnVariables(includeDeclarationVariables: boolean): Record<string, string | null> {
		return {
			[this.loopVariableDefinition]: this.loopVariableType.content,
			[this.indexVariableDefinition]: 'number'
		}
	}

	protected visitThisWithBoVisitor(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		this.visitCodePropertyWithBoVisitor(visitor, pathPrefix, ForBlock, 'collectionCode')
		this.visitCodePropertyWithBoVisitor(visitor, pathPrefix, ForBlock, 'loopVariableType')
	}

	getEditorCategory(): string {
		return 'Logic'
	}
}
