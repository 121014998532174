import { LowgileJsonMapper } from '@shared/data/lowgile-json-mapper';
import { TypeReference } from '@shared/data/type-reference';
import { ErrorService } from '@ng-shared/lib/services/error.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import type { BranchNameType, BusinessObjectTypeType } from '@shared/types';
import { StudioTrpcService } from '@ng-shared/lib/services/studio-trpc.service'

@Injectable({
  providedIn: 'root',
})
export class EditorService {
  private readonly typeReferencesSubject = new BehaviorSubject<TypeReference[]>([]);
  public typeReferences$ = this.typeReferencesSubject.asObservable();
  private isTypeReferencesLoaded = false
  private isTypeReferencesLoading = false
  private jsonMapper = new LowgileJsonMapper<TypeReference>(false);

  constructor(
    private trpcService: StudioTrpcService,
  ) {}

  async loadTypeReferences(branchName: BranchNameType, boTypes: '*' | BusinessObjectTypeType[], forceReload: boolean) {
    if(this.isTypeReferencesLoading) return
    if(this.isTypeReferencesLoaded && !forceReload) return

    this.isTypeReferencesLoading = true
    const typeReferences = await this.trpcService.client.bo.getTypeReferences.query({
      branchName,
      boTypes,
      includePrimitives: true,
    })
    this.isTypeReferencesLoading = false
    
    if (typeReferences) {
      this.isTypeReferencesLoaded = true
      this.typeReferencesSubject.next(typeReferences.map(typeRef => new TypeReference(typeRef)));
    }
  }
}
