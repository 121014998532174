import { CommonLanguageBridge } from '@shared/bridges/common-language-bridge'
import { BehaviorSubject } from 'rxjs'
import { AppContext } from '../app-context'
import { IdbService } from '@ng-shared/lib/services/idb.service'
import { LanguageInfo } from '@shared/types'
import { enUS } from 'date-fns/locale'

export const IdbLanguageKey = 'language'

export class FrontendLanguageBridge extends CommonLanguageBridge {
	protected static languageSubject = new BehaviorSubject<string>('English')
	static readonly language$ = this.languageSubject.asObservable()
	private static languagesCache: [string, LanguageInfo][] | undefined
	
	static get languages() { 
		if(!this.languagesCache) {
			this.languagesCache = Object.entries(AppContext.languages)
		}
		return this.languagesCache!
	}

	static get language() { return this.languageSubject.value }
	static set language(language: string) {
		AppContext.resolve(IdbService).put(IdbLanguageKey, language)
		this.languageSubject.next(language)
		this.languageChangedSubject.next()
	}
	static get languageIsoCode() { return AppContext.languages[this.language]?.isoCode ?? this.language}
	
	static get editMode() { return this.editModeSubject.value }
	static set editMode(editMode: boolean) {
		if(editMode == this.editModeSubject.value) return
		this.editModeSubject.next(editMode)
		this.languageChangedSubject.next()
	}

	static init() {
		setTimeout(async () => {
			const language = await AppContext.resolve(IdbService).get(IdbLanguageKey) as string
			if(language) this.language = language
		})
	}
}

FrontendLanguageBridge.θsetDateLocales({ enUS })