<div class="container">
	<ng-template
		cdkConnectedOverlay
		[cdkConnectedOverlayOrigin]="icon"
		[cdkConnectedOverlayOpen]="isOpen"
		[cdk]
	>
		<div class="popover {{vPosition}} {{hPosition}} {{width}}"
			(mouseenter)="open()"
			(mouseleave)="close()"
		>
			<ng-content></ng-content>
		</div>
	</ng-template>

	<mat-icon
		cdkOverlayOrigin
		#icon="cdkOverlayOrigin"
		class="icon"
		(mouseenter)="open()"
		(mouseleave)="close()"
	>help_outline</mat-icon>
</div>