import { CommonTextBridge } from '@shared/bridges/common-text-bridge'

type TextConversionType = 'ascii' | 'base64'
type ConversionType = TextConversionType | 'binary'

export class FrontendTextBridge extends CommonTextBridge {
	static convert(input: string, from: TextConversionType, to: TextConversionType): string
	static convert(input: ArrayBuffer, from: 'binary', to: TextConversionType): string
	static convert(input: string, from: TextConversionType, to: 'binary'): ArrayBuffer
	static convert(input: ArrayBuffer, from: 'binary', to: 'binary'): ArrayBuffer
	static convert(input: string | ArrayBuffer, from: ConversionType, to: ConversionType): string | ArrayBuffer {
	    // TODO: replace with native implementation when TC39 proposal https://github.com/tc39/proposal-arraybuffer-base64 is widely available
		if(from == to) return input
		
		let ascii = ''

		if(from == 'binary') {
			const buffer = input as ArrayBuffer
			const uint8 = new Uint8Array(buffer)
			for(let i = 0; i < buffer.byteLength; i++) {
				ascii += String.fromCharCode(uint8[i])
			}
		} else if(from == 'base64') {
			ascii = atob(input as string)
		} else {
			ascii = input as string
		}

		if(to == 'ascii') return ascii
		if(to == 'base64') return btoa(ascii)
		
		const buffer = new ArrayBuffer(ascii.length)
		const uint8 = new Uint8Array(buffer)
		for(let i = 0; i < ascii.length; i++) {
			uint8[i] = ascii.charCodeAt(i)
		}
		return buffer
	}
}