import * as fns from 'date-fns'
import { CommonLanguageBridge } from './common-language-bridge'

export class CommonDateBridge {
	constructor(private Language: typeof CommonLanguageBridge) {
	}

	readonly fns = fns

	format(date: Date, format: string) {
		if(!date) return ''
		return fns.format(date, format, {
			locale: this.Language.dateLocale,
		})
	}
}