import { DeepProxyHandler} from 'proxy-deep';
import { ProxyTargetSymbol } from '@shared/util/proxy-util'

const proxyCache = new WeakMap<object, object>()
const proxyObjects = new WeakSet<object>()
export const LowgilePathSymbol = Symbol('LowgilePathSymbol')
export const DeepProxySymbol = Symbol('DeepProxySymbol')

export const storeProxyHandler: DeepProxyHandler<object> = {
    get(target, p, receiver) {
      if(p == ProxyTargetSymbol || p == '__target') return Reflect.get(target, p, receiver) ?? target
      
      const val = Reflect.get(target, p, receiver)
      const fullPath = this.path.join('.') + '.' + p.toString()

      // console.log('property', p, 'path', this.path)
      // console.log(this.path.join('.')+'.', p)

      if(p == DeepProxySymbol || p == '__isDP') return true

      if(p == LowgilePathSymbol || p == '__LowgilePath') {
        if(typeof target == 'object') return this.path
        return this.path
      }

      if(typeof p == 'symbol') return val
      if(val instanceof Function) return val
      if(val instanceof Date) return val
      // if(target instanceof Date && val instanceof Function) return val.bind(target)

      if(val && typeof val == 'object') {
        if(proxyObjects.has(val)) {
          return val
        }

        let cached = proxyCache.get(val)
        if(!cached) {
          cached = this.nest(val)
          proxyObjects.add(cached)
          proxyCache.set(val, cached)
        }
        return cached
      }
      return val
    },
    // TODO: check if this needs to be re-enabled; disabling because trap somehow fails (returns false) when setting a property from within a screen action (e.g. person.firstName = '')
    // set: (target, p, value, receiver) => {
    //   return false
    // }
}