import type { HtmlType, TsCodeType, EventType } from './../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

@jsonObject({ name: 'VideoBlock' })
export class VideoBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Options',
		codeLanguage: 'ts',
		tsReturnType: () => 'any',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
	})
	optionsCode?: TsCodeType

	get allowedEventTypes(): EventType[] { return [
		{ name: 'ended' },
	]}

	constructor(init: Partial<VideoBlock> = {}) {
		super()
		this.exampleText = 'Video'
		this.init(init)
	}

	producePreview(): HtmlType {
		return `<div style="background: #dddddd; border: 1px solid #bbbbbb; padding: 3em;">${this.transpileExpressionForHtml(this.exampleText)}</div>`
	}
}
