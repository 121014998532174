import { ValidationRule } from '@shared/types'
import { AbstractControl, AsyncValidatorFn } from '@angular/forms'
import { AppContext } from '../app-context'
import { ChangeDetectionService } from '../services/change-detection.service'
import { ScopeDirective } from '../directives/scope.directive'

export class ValidationUtil {
	static createAsyncValidationFunction(validationRules: ValidationRule<any>[] | undefined, scopeDirective: ScopeDirective): AsyncValidatorFn | undefined {
		if(!validationRules?.length) return undefined

		return async (control: AbstractControl) => {
			let errors: Record<string, string> | null = null
			const scope = scopeDirective?.viewRef?.context?.$implicit ?? {}

			let idx = 0
			for(const rule of validationRules) {
				const result = await rule(control.value, scope)
				if(result?.error) {
					const ruleId = result.ruleId || `rule_${idx}`
					errors = {
						...errors!,
						[ruleId]: result.error,
					}
				}
				if(result?.skipSubsequentRules) break
				idx++
			}

			return errors
		}
	}
}