import type { HtmlType } from './../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { LeafBlock } from '../leaf-block'
import { ScreenEditorInput } from '../decorators/screen-editor-input'

export const DividerBlockDirections = {
	horizontal: 'Horizontal',
	vertical: 'Vertical',
} as const
export type DividerBlockDirection = keyof typeof DividerBlockDirections

@jsonObject({ name: 'DividerBlock' })
export class DividerBlock extends LeafBlock {
	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 1,
		label: 'Direction',
		options: Object.entries(DividerBlockDirections)
	})
	direction: DividerBlockDirection = 'horizontal'

	producePreview(): HtmlType {
		return `<hr>`
	}

	getEditorCategory(): string {
		return 'Screen Structure'
	}
}
