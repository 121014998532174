import { ApplicationRef, ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ChangeDetectionService {
  static readonly disableZones = false
  private rootComponent = {}
  private markViewDirtySubject = new Subject<void>()
  readonly markViewDirty$ = this.markViewDirtySubject.asObservable()

  constructor(
    private appRef: ApplicationRef,
  ) { }

  static getNgZone() {
    return this.disableZones ? 'noop' : 'zone.js'
  }

  setRootComponent(rootComponent: {}) {
    if(this.rootComponent) return
    this.rootComponent = rootComponent
  }
  markViewDirty(view: {} | 'root', force = false) {
    if(!ChangeDetectionService.disableZones && !force) return
    // TODO: find alternative for markDirty?
    // markDirty(view == 'root' ? this.rootComponent : view)
  }

  refreshWholeApp() {
    this.markViewDirtySubject.next()
    // setTimeout(() => this.appRef.tick())
  }
}
