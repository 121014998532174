import { BoReference } from '@shared/bos/bo-reference'
import { BusinessObject } from '@shared/bos/business-object'
import { BusinessObjectIdType, BusinessObjectTypeType, ModuleIdType, TypeIcons, PrimitiveConstructorNames } from './../types';

export class TypeReference {
	isArray: boolean = false
	isPromise: boolean = false
	kind: 'primitive' | 'bo' | 'type' = 'primitive'
	typeName: string = 'string'
	suffix: string = ''
	boType?: BusinessObjectTypeType
	boModuleId!: ModuleIdType
	boId!: BusinessObjectIdType

	constructor(init?: Partial<TypeReference> | string) {
		if(init instanceof Object) Object.assign(this, init)

		if(typeof init == 'string') {
			init = init.trim()
			this.kind = 'primitive'
			
			const isPromiseMatch = init.match(/^Promise<(.*)>$/)
			if(isPromiseMatch) {
				this.isPromise = true
				init = isPromiseMatch[1]
			}

			this.isArray = init.endsWith('[]')
			init = this.isArray ? init.substring(0, init.length-2) : init

			this.typeName = init

			const [primitiveOrModuleId, boId, suffix, ...rest] = init.split('.')
			if(suffix == 'Id') {
				this.kind = 'type'
				this.boModuleId = primitiveOrModuleId
				this.boId = boId
				this.suffix = suffix
				this.typeName = `${boId}θ${suffix}`
			} else if (init.startsWith('Sys.Types.')) {
				// no special treatment; treat as primitive
			} else if(boId) {
				if(primitiveOrModuleId != 'Temporal') {
					this.kind = 'bo'
					this.boModuleId = primitiveOrModuleId
					this.boId = boId
					this.suffix = suffix
				}
			}
		}
	}

	getDisplayText() {
		let icon = '[?]'

		switch(this.kind) {
			case 'primitive':
				icon = TypeIcons[this.typeName as keyof typeof TypeIcons]
				break

			case 'type':
				icon = '[T]'
				break;

			case 'bo':
				icon = TypeIcons[this.boType as keyof typeof TypeIcons]
				break

			default:
				console.log(`Error: icon for type reference kind ${this.kind} is not implemented`)
		}

		return `${icon} ${this.getQualifiedSimpleTypeName()}`
	}

	getQualifiedSimpleTypeName() {
		let type: string

		switch(this.kind) {
			case 'primitive':
				type = this.typeName
				break
				
			case 'type':
				type = (this.boModuleId ? `${this.boModuleId}.` : '') + this.typeName
				break

			case 'bo':
				type = `${this.boModuleId}.${this.boId}` + (this.suffix ? `.${this.suffix}` : '')
				break
		}

		if(type === undefined) console.error(`Type unknown for kind ${this.kind}`)
		return type
	}
	
	getQualifiedTypeName(promisify=false) {
		let type = this.getQualifiedSimpleTypeName()
		type = this.isArray ? `${type}[]` : type
		if(this.isPromise || promisify) type = `Promise<${type}>`
		
		return type
	}

	getConstructorName(addArrayIfApplicable = false) {
		let name: string
		if(this.kind == 'primitive') {
			name = PrimitiveConstructorNames[this.typeName as keyof typeof PrimitiveConstructorNames] || this.typeName
		} else {
			name = this.getQualifiedSimpleTypeName()
		}
		if(addArrayIfApplicable && this.isArray) name += '[]'
		return name
	}

	forModule(contextModule: ModuleIdType | BusinessObject | BoReference): TypeReference {
		if(this.kind != 'bo' && this.kind != 'type') return this
		if(this.boModuleId != 'This') return this
		if(contextModule instanceof BusinessObject) contextModule = contextModule.moduleId
		if(contextModule instanceof BoReference) contextModule = contextModule.moduleId
		
		return new TypeReference({
			...this,
			boModuleId: contextModule
		})
	}
}

export class TypeReferenceFactory {
	static string() {
		return new TypeReference({ kind: 'primitive', typeName: 'string' })
	}
	static number() {
		return new TypeReference({ kind: 'primitive', typeName: 'number' })
	}
	static constructorType() {
		return new TypeReference({ kind: 'primitive', typeName: undefined })
	}
	static void() {
		return new TypeReference({ kind: 'primitive', typeName: 'void' })
	}
}
