<input type="file" [multiple]="multiple" [accept]="allowedMimeTypes" style="display: none;" #fileInput (change)="onFilesSelected($event); fileInput.value = ''">

<div class="grid">
	<ng-container *ngFor="let upload of fileUploadSession.uploads">
		<div>{{ upload.fileName }}</div>
		<mat-progress-bar
		[value] = "upload.uploadProgressPercent$ | async"
		mode="determinate"
		[color]="getColor(upload)"
		></mat-progress-bar>
		<div>{{ upload.uploadProgressPercent$ | async }}%</div>
		<div>
			<mat-icon *ngIf="upload.isRemovable">delete</mat-icon>
		</div>
	</ng-container>
</div>
<div *ngIf="multiple || !fileUploadSession.uploads.length">
	<ng-container [ngSwitch]="displayStyle">
		<div *ngSwitchCase="'dropZone'" class="dropzone"
			[class.disabled]="disabled"
			(dragenter)="onFileDrag($event, true)"
			(dragover)="onFileDrag($event, true)"
			(dragleave)="onFileDrag($event, false)"
			(drop)="onFileDrop($event)"
			[class.draggingOver] = "this.draggingOverDropzone"
			(click)="disabled ? null : fileInput.click()"
		>
			<ng-container *ngTemplateOutlet="ngContent"/>
		</div>

		<ng-container *ngSwitchCase="'button'" [ngSwitch]="buttonType">
			<button *ngSwitchCase="'mat-button'" mat-button [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-raised-button'" mat-raised-button [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-stroked-button'" mat-stroked-button [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-flat-button'" mat-flat-button [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-icon-button'" mat-icon-button [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-icon-button mat-medium-icon-button'" mat-icon-button mat-medium-icon-button [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-icon-button mat-small-icon-button'" mat-icon-button mat-small-icon-button [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-icon-button mat-tiny-icon-button'" mat-icon-button mat-tiny-icon-button [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-fab'" mat-fab [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-mini-fab'" mat-mini-fab [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
			<button *ngSwitchCase="'mat-fab extended'" mat-fab extended [disabled]="disabled" [color]="color" (click)="fileInput.click()"><mat-icon *ngIf="buttonIcon">{{ buttonIcon }}</mat-icon><ng-container *ngTemplateOutlet="ngContent"/></button>
		</ng-container>
	</ng-container>
</div>

<ng-template #ngContent>
	<ng-content/>
</ng-template>