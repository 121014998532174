import type { BoReference } from '@shared/bos/bo-reference'
import { HasId } from '@shared/types';
export abstract class TrackByBasis {
	trackById(index: number, item: HasId) {
		return item?.id
	}

	trackByIndex(index: number, item: any) {
		return index
	}

	trackByItem(index: number, item: any) {
		return item
	}
	
	trackByAutomatic(index: number, item: any) {
		if(item?.id) return item.id
		const itemType = typeof item
		if(itemType == 'string' || itemType == 'number' || itemType == 'bigint' || itemType == 'boolean') return item
		return index
	}

	trackByBoReference(index: number, item: BoReference) {
		return item.getQualifiedTypeAndName()
	}
}