import { DataUtil } from '@shared/util/data-util'
import { type ModuleIdType, type BusinessObjectIdType, type UserIdType, UserIdCtor } from '@shared/types';
import { jsonMember, jsonArrayMember, jsonObject } from 'typedjson'

@jsonObject
export class UserRole {
	@jsonMember(Number)
	id?: number

	@jsonMember(() => UserIdCtor)
	userId?: UserIdType

	@jsonMember(String)
	roleModuleId: ModuleIdType = ''

	@jsonMember(String)
	roleBoId: BusinessObjectIdType = ''

	@jsonArrayMember(String)
	tenantIds: string[] = []
	@jsonMember(Boolean)
	appliesAlsoToSubTenants: boolean = true

	constructor(init?: Partial<UserRole>) {
		DataUtil.assignCommonProperties(this, init)
	}

	static fromQualifiedName(qualifiedName: string) {
		const [roleModuleId, roleBoId] = qualifiedName.split('.')
		return new UserRole({ roleModuleId, roleBoId })
	}

	getQualifiedTypeAndName() {
		return `Role:${this.getQualifiedName()}`
	}

	getQualifiedName() {
		return `${this.roleModuleId}.${this.roleBoId}`
	}

	matches(other: UserRole) {
		return this.roleModuleId === other.roleModuleId && this.roleBoId === other.roleBoId
	}
}

@jsonObject
export class UserIdpRole extends UserRole {
	@jsonMember(String)
	identityProviderId?: string

	constructor(init?: Partial<UserIdpRole>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	static fromQualifiedNameAndProviderId(qualifiedName: string, identityProviderId: string) {
		const [roleModuleId, roleBoId] = qualifiedName.split('.')
		return new UserIdpRole({ roleModuleId, roleBoId, identityProviderId })
	}
}