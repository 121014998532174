import { EditorPropertyWithKey } from '@shared/blocks/decorators/editor-property-types'
import { BusinessObject } from '@shared/bos/business-object'

export type PropertyBlock = PropertyPropertyBlock | GapPropertyBlock | FieldsetPropertyBlock

export interface PropertyPropertyBlock {
	type: 'property'
	property: EditorPropertyWithKey<BusinessObject, any>
}

export interface GapPropertyBlock {
	type: 'gap'
}

export interface FieldsetPropertyBlock {
	type: 'fieldset'
	legend: string
	propertyBlocks: PropertyBlock[]
}