import { DataUtil } from '@shared/util/data-util'
import { BusinessObjectTypeType, UserIdCtor, type UserIdType } from './../types';
import { jsonArrayMember, jsonMember, jsonObject, toJson } from 'typedjson'
import { BusinessObject } from '@shared/bos/business-object'

export type LockObjectType = BusinessObjectTypeType
export type LockLevelType = 'read' | 'partial' | 'full' | 'exclusive'

@jsonObject
@toJson
export class Lock {
	@jsonMember(String)
	get __type() { return 'Lock' }
	set __type(_) {}

	@jsonMember(String)
	id?: string

	@jsonMember(String)
	objectType!: LockObjectType

	@jsonMember(String)
	objectName!: string

	@jsonMember
	objectVersionDateTime?: Date

	@jsonMember(String)
	lockLevel: LockLevelType = 'read'

	@jsonArrayMember(String)
	subAreas: string[] = []

	@jsonMember(() => UserIdCtor)
	userId?: UserIdType

	@jsonMember
	obtainedOn?: Date

	constructor(init?: Partial<Lock>) {
		DataUtil.assignCommonProperties(this, init)
	}

	static forBo(bo: BusinessObject, init?: Partial<Lock>) {
		return new Lock({
			objectType: bo.__type,
			objectName: bo.getQualifiedName(),
			objectVersionDateTime: bo.updateTimestamp ?? bo.creationTimestamp,
			...init
		})
	}
}