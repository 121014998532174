<div class="top">
	<div class="table" #tableDiv>
		<mat-form-field *ngIf="useGlobalFilter" style="width: 100%; margin-bottom: 0.5em;" appearance="outline">
			<mat-label>Filter</mat-label>
			<input matInput #inputEl 
				[ngModel]="filterSubject.value"
				(keyup)="filterSubject.next(inputEl.value)"
				[mention]="columns" [mentionConfig]="filterMentionConfig"
				[mentionListTemplate]="filterMentionTemplate"
				(opened)="setSearchDisabled(true)"
				(closed)="setSearchDisabled(false)"
			>
			<button *ngIf="filterSubject.value" matSuffix mat-icon-button aria-label="Clear" (click)="inputEl.value = ''; filterSubject.next('')">
				<mat-icon>close</mat-icon>
			</button>
			<mat-hint><em>=Anna</em> finds exact matches; <em>^=Anna</em> finds at start; <em>$=</em> finds at end; <em>&lt;</em>, <em>&leq;</em>, <em>&gt;</em>, <em>&geq;</em> are comparisons; wrap with &quot; if needed (e.g. <em>="Anna Maria"</em>); use # to show field names </mat-hint>

			<ng-template #filterMentionTemplate let-item="item">
				<div style="min-width: 300px; display: flex;">
					<div style="flex-grow: 1;">
						<ng-container *ngTemplateOutlet="getHeaderTemplate(item.label)"></ng-container>
					</div>
					[{{item.label}}]
				</div>
			</ng-template>
		</mat-form-field>
		
		<table mat-table [dataSource]="dataSource" [trackBy]="trackBy" matSort (matSortChange)="onSort($event)" #matSort="matSort">
			<ng-content></ng-content>

			<tr mat-header-row *matHeaderRowDef="showColumns; sticky: stickyHeader" #header></tr>
			<tr mat-header-row *matHeaderRowDef="showFilterColumns; sticky: stickyHeader" class="filter-row" (input)="onFilterRowChanged($event)"></tr>
			<tr
				mat-row
				*matRowDef="let item; let index = index; columns: showColumns"
				(click)="rowClick.emit([$event, item, index])"
				[style]="rowStyleFn?.(item, surroundingScope) ?? ''"
			></tr>
		</table>
	
		<mat-paginator *ngIf="usePaging"
			[pageSizeOptions]="pageSizeOptions"
			[pageSize]="pageSize"
			[showFirstLastButtons]="true"
			(page)="onPage($event)"
		></mat-paginator>
		<input *ngIf="paginator" 
			#pageNumberInput
			matInput
			class="page-number-input"
			[ngModel]="paginator!.pageIndex + 1"
			(ngModelChange)="jumpToPageNumber($event)"
			[disabled]="paginator!.disabled || paginator!.pageSize >= paginator!.length"
		>
	</div>

	<ng-container *ngIf="showSettings">
		<mat-icon
			class="settings-trigger"
			cdkOverlayOrigin #trigger="cdkOverlayOrigin"
			(click)="toggleOverlay()"
		>settings</mat-icon>
		<ng-template
			cdkConnectedOverlay
			[cdkConnectedOverlayOrigin]="trigger"
			[cdkConnectedOverlayOpen]="isOverlayOpen"
			[cdkConnectedOverlayHasBackdrop]="isOverlayOpen"
			(backdropClick)="toggleOverlay()"
		>
			<div class="settings-overlay">
				<div *ngIf="userDefinedConfiguration">
					<mat-form-field style="width: 100%;">
						<mat-label>View</mat-label>
						<mat-select [(ngModel)]="currentView" (ngModelChange)="reloadView()">
							<mat-option [value]="''">
								<span>(Standard)</span>
								<span *ngIf="!currentView && isViewDirty">*</span>
								<span *ngIf="!configurations.defaultView"> [default]</span>
							</mat-option>
							<mat-option *ngFor="let item of configurations.views | keyvalue" [value]="item.key">
								<span>{{ item.key }}</span>
								<span *ngIf="item.key == currentView && isViewDirty">*</span>
								<span *ngIf="item.key == configurations.defaultView"> [default]</span>
							</mat-option>
						</mat-select>
					</mat-form-field>
					<div class="settings-button-row">
						<button mat-stroked-button color="primary" (click)="saveView()" [disabled]="!isViewDirty || !currentView">Save</button>
						<button mat-stroked-button color="primary" (click)="saveViewAs()">Save as</button>
						<button mat-stroked-button color="accent" (click)="reloadView()" [disabled]="!isViewDirty">Revert</button>
						<button mat-stroked-button color="" (click)="makeDefaultView()" [disabled]="currentView == configurations.defaultView">Make default</button>
						<button mat-stroked-button color="warn" (click)="deleteView()" [disabled]="!currentView">Delete</button>
					</div>
				</div>

				<div class="settings-filter-row">
					<div><strong>Filters</strong></div>
					<div *ngIf="mergeFilters(filterSubject.value, columnFiltersSubject.value) as filter; else noFilters">
						<code>{{ filter }}</code>
					</div>
					<ng-template #noFilters>
						<em>(none)</em>
					</ng-template>
				</div>

				<div class="columns-grid">
					<div>
						<strong>Hidden columns</strong>
						<div
							cdkDropList
							#hiddenCols="cdkDropList"
							class="column-list"
							[cdkDropListData]="hiddenColumns"
							[cdkDropListConnectedTo]="[shownCols]"
							(cdkDropListDropped)="dropOverlayColumn($event)"
						>
							<div *ngFor="let col of hiddenColumns" class="column-list-item" cdkDrag>
								<ng-container *ngTemplateOutlet="getHeaderTemplate(col)"></ng-container> [{{col}}]
							</div>
						</div>
					</div>
			
					<div>
						<strong>Shown columns</strong>
						<div
							cdkDropList
							#shownCols="cdkDropList"
							class="column-list"
							[cdkDropListData]="showColumns"
							[cdkDropListConnectedTo]="[hiddenCols]"
							(cdkDropListDropped)="dropOverlayColumn($event)"
						>
							<div *ngFor="let col of showColumns" class="column-list-item" cdkDrag>
								<ng-container *ngTemplateOutlet="getHeaderTemplate(col)"></ng-container> [{{col}}]
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</ng-container>
</div>