import { DataUtil } from '@shared/util/data-util'
import { Actionable } from '@shared/bos/actionable'
import { jsonMember, jsonObject } from 'typedjson';
import { BoVisitor } from '@shared/bos/bo-visitor'

@jsonObject
export class LowgileAction extends Actionable {
	constructor(init?: Partial<LowgileAction>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	visit(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		super.visit(visitor, pathPrefix)
	}

	getMethodName() {
		return `action_${this.name}`
	}
}