import { BusinessObject } from '@shared/bos/business-object'
import { DataUtil } from '@shared/util/data-util'
import { jsonObject, jsonMember, jsonArrayMember, toJson, jsonMapMember } from 'typedjson'
import { BoReference } from './bo-reference'

export const ThemeTypes = ['light', 'dark'] as const
export type ThemeType = typeof ThemeTypes[number]
export const ThemeTypographyLevelNames = ['headline-1', 'headline-2', 'headline-3', 'headline-4', 'headline-5', 'headline-6', 'subtitle-1', 'body-1', 'body-2', 'subtitle-2', 'caption'] as const
export type ThemeTypographyLevelName = typeof ThemeTypographyLevelNames[number]

@jsonObject
export class ThemePalette {
	@jsonMember(String) paletteName?: string
	@jsonMember(String) defaultHue?: string
	@jsonMember(String) lighterHue?: string
	@jsonMember(String) darkerHue?: string
	@jsonMember(String) textHue?: string

	constructor(init?: Partial<ThemePalette>) {
		DataUtil.assignCommonProperties(this, init)
	}

	getDefinePalette() {
		const defaultHue = this.defaultHue || '500'
		const lighterHue = this.lighterHue || '100'
		const darkerHue = this.darkerHue || '700'
		const textHue = this.textHue || defaultHue
		
		return `mat.m2-define-palette(mat.$m2-${this.paletteName}-palette, ${defaultHue}, ${lighterHue}, ${darkerHue}, ${textHue})`
	}
}

@jsonObject
export class ThemeTypographyLevel {
	@jsonMember(String) fontFamily: string = 'sans-serif'
	@jsonMember(Number) fontWeight: number = 400
	@jsonMember(String) fontSize: string = '1rem'
	@jsonMember(Number) lineHeight: number = 1
	@jsonMember(String) letterSpacing: string = 'normal'
}

@jsonObject
export class ThemeTypography {
	@jsonMapMember(String, ThemeTypographyLevel)
	levels: Map<ThemeTypographyLevelName, ThemeTypographyLevel> = new Map()
}

@jsonObject({ name: 'Theme' })
export class Theme extends BusinessObject {
	@jsonMember extendsThemeRef?: BoReference
	@jsonMember(String) type: ThemeType = 'light'

	@jsonMember primaryPalette: ThemePalette = new ThemePalette({
		paletteName: 'indigo',
		defaultHue: '500',
	})
	@jsonMember accentPalette: ThemePalette = new ThemePalette({
		paletteName: 'pink',
		defaultHue: 'A200',
		lighterHue: 'A100',
		darkerHue: 'A400',
	})
	@jsonMember warnPalette: ThemePalette = new ThemePalette({
		paletteName: 'red'
	})
	
	@jsonMember typography: ThemeTypography = new ThemeTypography()

	@jsonMember(Number) density: number = 0

	constructor(init?: Partial<Theme>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getPrefixName() {
		return `${this.moduleId}__${this.boId}`
	}

	getThemeClassName() {
		return `${this.getPrefixName()}-theme`
	}
}