import { ListItemBlock } from './list-item-block';
import { Block } from '../block'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import type { Direction } from '@shared/types'
import { DirectionOptions } from '@shared/types'

export const KindInfos = {
	standard: { name: 'Standard', listTag: 'mat-list', listItemTag: 'mat-list-item' },
	navigation: { name: 'Navigation', listTag: 'mat-nav-list', listItemTag: 'mat-list-item' },
	unordered: { name: 'Unordered (Bullets)', listTag: 'ul', listItemTag: 'li' },
	ordered: { name: 'Ordered (1., 2., 3.)', listTag: 'ol', listItemTag: 'li' },
} as const
type KindName = keyof typeof KindInfos

@jsonObject({ name: 'ListBlock' })
export class ListBlock extends Block {
	readonly allowedChildTypes = [ ListItemBlock ]

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 1,
		label: 'Type',
		options: Object.entries(KindInfos).map(e => [e[0], e[1].name]),
	})
	kind: KindName = 'standard'

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'dropdown',
		order: 2,
		label: 'Direction',
		options: Object.entries(DirectionOptions),
		isVisible(items, properties, bo) {
			return items[0] instanceof ListBlock && items[0].kind == 'navigation'
		},
	})
	direction: Direction = 'vertical'

	constructor(init: Partial<ListBlock> = {}) {
		super()
		this.init(init)
	}

	getEditorCategory(): string {
		return 'Tables & Lists'
	}

	static createNewForEditor(): Block {
		return new this({
			children: [
				ListItemBlock.createNewForEditor()
			]
		})
	}
}
